import { useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  GetSessionQuery,
  GetSessionQueryVariables,
  GetSessionUpdatesSubscription,
  GetSessionUpdatesSubscriptionVariables,
} from '../GraphQL/graphql'
import {
  GetSessionDocument,
  GetSessionUpdatesDocument,
} from '../GraphQL/graphql'

export const useSession = (sessionId?: string) => {
  const { data, loading, subscribeToMore } = useQuery<
    GetSessionQuery,
    GetSessionQueryVariables
  >(GetSessionDocument, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      GetSessionUpdatesSubscription,
      GetSessionUpdatesSubscriptionVariables
    >({
      document: GetSessionUpdatesDocument,
      variables: {
        sessionId,
      },
      updateQuery: (prev, { subscriptionData: { data } }) => ({
        ...data,
        __typename: prev.__typename,
      }),
    })

    return () => unsubscribe()
  }, [sessionId, subscribeToMore])

  return useMemo(
    () => ({
      isSession: !data?.getSession.error,
      sessionLoading: loading,
      error: data?.getSession.error ?? null,
    }),
    [data, loading]
  )
}
