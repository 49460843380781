import type { FC, HTMLAttributes } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ProfileError,
  type UpdateEmailMutationVariables,
} from '../../GraphQL/graphql'
import { authErrorToMessage, emailRegex } from '../../Helper/AuthHelper'
import { profileErrorToMessage } from '../../Helper/UserHelper'
import { useProfile } from '../../Hooks/useProfile'
import { AlertMessage } from '../AlertMessage/AlertMessage'
import { Button } from '../Button'
import { FormInput } from '../FormInput/FormInput'
import { Heading } from '../Heading/Heading'

export const ProfileUpdateEmail: FC<HTMLAttributes<HTMLDivElement>> = ({
  ...divArgs
}) => {
  const { t } = useTranslation(['translation'])

  const [editEmail, setEditEmail] = useState(false)

  const {
    profile,
    mutations: {
      updateEmail: [updateEmail],
    },
  } = useProfile()

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitting },
  } = useForm<UpdateEmailMutationVariables>({
    defaultValues: useMemo(() => {
      return { email: profile?.email ?? undefined }
    }, [profile?.email]),
  })

  useEffect(() => {
    reset({ email: profile?.email ?? undefined })
  }, [profile?.email, reset])

  const onSubmit = handleSubmit(async (variables) => {
    await updateEmail({
      variables,
      onCompleted: ({ updateEmail }) => {
        if (updateEmail.authError) {
          setError('root', {
            message: authErrorToMessage(updateEmail.authError, t),
          })
        } else if (updateEmail.profileErrors) {
          const errors: string[] = []
          updateEmail.profileErrors.forEach((error) => {
            switch (error) {
              case ProfileError.InvalidEmailFormat:
                setError('email', {
                  message: profileErrorToMessage(error, t),
                })
                break
              default:
                errors.push(profileErrorToMessage(error, t))
                break
            }
            if (errors.length) {
              setError('root', {
                message: errors.join(' | '),
              })
            }
          })
        } else {
          setEditEmail(false)
          reset()
        }
      },
      onError: () =>
        setError('root', {
          message: t(
            'error.Saving not possible. If this error occurs repeatedly, please contact our support.'
          ),
        }),
    })
  })

  return (
    <div {...divArgs}>
      <Heading variant="h2" className="mb-6">
        {t('pages.profile.E-Mail')}
      </Heading>

      {isSubmitSuccessful && profile?.pendingEmail && !isDirty && (
        <AlertMessage
          type="success"
          message={t(
            'pages.profile.E-mail successfully saved. You have been sent a confirmation link to {{email}} that you can use to activate it.',
            { email: profile.pendingEmail }
          )}
          onClose={() => reset()}
        />
      )}

      {editEmail && errors.root?.message && (
        <AlertMessage
          type="warning"
          message={errors.root.message}
          onClose={() => clearErrors('root')}
        />
      )}

      <form className="space-y-6" onSubmit={onSubmit}>
        <FormInput
          id="email"
          invisible
          size="lg"
          label={t('pages.profile.E-Mail')}
          disabled={isSubmitting || !editEmail}
          type="text"
          register={register}
          options={{
            required: t('error.This field cannot be empty.'),
            pattern: {
              value: emailRegex,
              message: profileErrorToMessage(
                ProfileError.InvalidEmailFormat,
                t
              ),
            },
          }}
          errors={errors}
        />

        {editEmail && (
          <Button
            type="submit"
            size="sm"
            disabled={isSubmitting || !isDirty}
            className="mr-2"
          >
            {isSubmitting
              ? t('components.button.Saving')
              : t('pages.profile.Save Mail')}
          </Button>
        )}

        <Button
          type="button"
          variant={editEmail ? 'secondary' : 'primary'}
          size="sm"
          onClick={() => {
            reset()
            setEditEmail(!editEmail)
          }}
        >
          {editEmail
            ? t('components.button.Abort')
            : t('components.button.Edit')}
        </Button>
      </form>
    </div>
  )
}
