import type { FC } from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

const REDIRECT_PATHNAMES = [
  'reports',
  'anamnesis',
  'sessions',
  'orders',
  'files',
  'profile',
  'archive',
]
type Props = {
  to: string
}
export const ParamsNavigate: FC<Props> = ({ to }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const next = searchParams.get('next')

  useEffect(() => {
    if (next) {
      navigate(next)
    } else if (
      REDIRECT_PATHNAMES.some((name) => location.pathname.includes(name))
    ) {
      navigate(`/login?next=${location.pathname}`)
    } else {
      navigate(`${to}${searchParams}`)
    }
  }, [next, navigate, searchParams, to, location])

  return <div />
}
