import type { FC, HTMLAttributes } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  AuthError,
  ProfileError,
  type UpdatePasswordMutationVariables,
} from '../../GraphQL/graphql'
import { authErrorToMessage, passwordRegex } from '../../Helper/AuthHelper'
import { profileErrorToMessage } from '../../Helper/UserHelper'
import { useProfile } from '../../Hooks/useProfile'
import { AlertMessage } from '../AlertMessage/AlertMessage'
import { Button } from '../Button'
import { FormInput } from '../FormInput/FormInput'
import { Heading } from '../Heading/Heading'

export const ProfileUpdatePassword: FC<HTMLAttributes<HTMLDivElement>> = ({
  ...divArgs
}) => {
  const { t } = useTranslation()

  const [editPassword, setEditPassword] = useState(false)

  const {
    mutations: {
      updatePassword: [updatePassword],
    },
  } = useProfile()

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    getValues,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitting },
  } = useForm<UpdatePasswordMutationVariables & { passwordRepeat: string }>()

  const onSubmit = handleSubmit(
    async ({ password, passwordRepeat, oldPassword }) => {
      if (password !== passwordRepeat) {
        setError('passwordRepeat', {
          message: t('error.profile.Passwords do not match'),
        })
        return
      }
      await updatePassword({
        variables: {
          password,
          oldPassword,
        },
        onCompleted: ({ updatePassword }) => {
          if (updatePassword.authError) {
            switch (updatePassword.authError) {
              case AuthError.InvalidPassword:
                setError('oldPassword', {
                  message: authErrorToMessage(updatePassword.authError, t),
                })
                break
              default:
                setError('root', {
                  message: authErrorToMessage(updatePassword.authError, t),
                })
            }
          } else if (updatePassword.profileErrors) {
            const errors: string[] = []
            updatePassword.profileErrors.forEach((error) => {
              switch (error) {
                case ProfileError.InvalidPasswordFormat:
                  setError('password', {
                    message: profileErrorToMessage(error, t),
                  })
                  break
                default:
                  errors.push(profileErrorToMessage(error, t))
                  break
              }
              if (errors.length) {
                setError('root', {
                  message: errors.join(' | '),
                })
              }
            })
          } else {
            setEditPassword(false)
            reset()
          }
        },
        onError: () =>
          setError('root', {
            message: t(
              'error.Saving not possible. If this error occurs repeatedly, please contact our support.'
            ),
          }),
      })
    }
  )

  return (
    <div {...divArgs}>
      <Heading variant="h2" className="mb-6">
        {t('pages.profile.Password')}
      </Heading>

      {isSubmitSuccessful && !isDirty && (
        <AlertMessage
          type="success"
          message={t('pages.profile.Password successfully saved.')}
          onClose={() => reset()}
        />
      )}

      {editPassword && errors.root?.message && (
        <AlertMessage
          type="warning"
          message={errors.root.message}
          onClose={() => clearErrors('root')}
        />
      )}

      <form className="space-y-6" onSubmit={onSubmit}>
        <FormInput
          id="oldPassword"
          invisible
          size="lg"
          label={t('pages.profile.Current password')}
          disabled={isSubmitting || !editPassword}
          type="password"
          register={register}
          options={{
            required: t('error.This field cannot be empty.'),
          }}
          errors={errors}
        />

        <FormInput
          id="password"
          invisible
          size="lg"
          label={t('pages.profile.New password')}
          disabled={isSubmitting || !editPassword}
          type="password"
          register={register}
          options={{
            required: t('error.This field cannot be empty.'),
            pattern: {
              value: passwordRegex,
              message: profileErrorToMessage(
                ProfileError.InvalidPasswordFormat,
                t
              ),
            },
          }}
          errors={errors}
        />

        <FormInput
          id="passwordRepeat"
          invisible
          size="lg"
          label={t('pages.profile.Repeat password')}
          disabled={isSubmitting || !editPassword}
          type="password"
          register={register}
          options={{
            required: t('error.This field cannot be empty.'),
            validate: (passwordRepeat) => {
              return getValues('password') !== passwordRepeat
                ? t('error.profile.Passwords do not match')
                : undefined
            },
          }}
          errors={errors}
        />

        {editPassword && (
          <Button
            type="submit"
            size="sm"
            disabled={isSubmitting || !isDirty}
            className="mr-2"
          >
            {isSubmitting
              ? t('components.button.Saving')
              : t('pages.profile.Save Password')}
          </Button>
        )}

        <Button
          type="button"
          variant={editPassword ? 'secondary' : 'primary'}
          size="sm"
          onClick={() => {
            reset()
            setEditPassword(!editPassword)
          }}
        >
          {editPassword
            ? t('components.button.Abort')
            : t('components.button.Edit')}
        </Button>
      </form>
    </div>
  )
}
