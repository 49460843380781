import { useMutation } from '@apollo/client'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../Contexts/AuthContext'
import type {
  LogoutUserMutation,
  LogoutUserMutationVariables,
} from '../../GraphQL/graphql'
import { LogoutUserDocument } from '../../GraphQL/graphql'

export const Logout: React.FC = () => {
  const navigate = useNavigate()
  const { updateAuth } = useContext(AuthContext)

  const [logoutUser] = useMutation<
    LogoutUserMutation,
    LogoutUserMutationVariables
  >(LogoutUserDocument, {
    async onCompleted(data) {
      if (data.logoutUser) {
        await updateAuth(null)
        navigate('/')
      }
    },
  })

  useEffect(() => {
    const handle = async () => {
      try {
        await logoutUser()
        const isStage = (process.env.REACT_APP_BASE_PATH as string).includes(
          'stage'
        )
        window.location.href = `${window.location.origin}${
          isStage ? '/stage' : ''
        }`
        // cache.reset()
        // storage.removeItem("apollo-cache-persist")
        // window.location.reload()
      } catch (error) {
        // TODO: catch unsuccessful logout and inform user
      }
    }
    handle()
    // should be executed once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{}</>
}
