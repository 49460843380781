import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  GetReportPreviewQuery,
  GetReportPreviewQueryVariables,
  ReportError,
} from '../GraphQL/graphql'
import {
  GetReportPreviewDocument,
  GetReportPreviewUpdatesDocument,
} from '../GraphQL/graphql'

export type UseReportPreviewResponse = {
  report?: string
  reportError?: ReportError
  reportLoading: boolean
}

export const useReportPreview = (
  sessionId?: string
): UseReportPreviewResponse => {
  const [getReportPreview, { data, loading, subscribeToMore }] = useLazyQuery<
    GetReportPreviewQuery,
    GetReportPreviewQueryVariables
  >(GetReportPreviewDocument)

  useEffect(() => {
    sessionId && getReportPreview({ variables: { sessionId } })
  }, [data, getReportPreview, loading, sessionId])

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: GetReportPreviewUpdatesDocument,
      variables: { sessionId },
      updateQuery: (_, options) => {
        return options.subscriptionData.data
      },
    })

    return () => unsubscribe()
  }, [sessionId, subscribeToMore])

  return useMemo(
    () => ({
      report: data?.getReportPreview?.text ?? undefined,
      reportError: data?.getReportPreview?.error ?? undefined,
      reportLoading: loading,
    }),
    [data, loading]
  )
}
