import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../Components/Button'
import { CardList } from '../../Components/CardList/CardList'
import { Heading } from '../../Components/Heading/Heading'
import { ReportCard } from '../../Components/ReportCard'
import { Shell } from '../../Components/Shell/Shell'
import { Spinner } from '../../Components/Spinner/Spinner'
import { useReports } from '../../Hooks/useReports'
import { useSession } from '../../Hooks/useSession'
import { Alert } from '../../Modules/Alert/Alert'

export const Reports: React.FC = () => {
  const { t } = useTranslation([], { keyPrefix: 'pages.reports' })
  const navigate = useNavigate()
  const { reports, reportsLoading } = useReports()
  const { isSession, sessionLoading } = useSession()

  if (reportsLoading || sessionLoading) {
    return <Spinner />
  }

  return (
    <Shell autogeneratedCircles={2} preGeneratedCircles={[]} noLayout>
      <Heading variant="h1">{t('Medical reports')}</Heading>

      {isSession && (
        <p className="my-5">
          <Button className="px-4 py-2" onClick={() => navigate('/anamnesis')}>
            {t('Show anamnesis of current conversation')}{' '}
          </Button>
        </p>
      )}
      {!reportsLoading && !reports.length && (
        <Alert message={t('No reports available')} />
      )}
      {!reportsLoading && !!reports.length && (
        <CardList>
          {reports &&
            [...reports].map((report) => (
              <ReportCard report={report} key={report.id} />
            ))}
        </CardList>
      )}
    </Shell>
  )
}
