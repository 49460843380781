import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  GetCurrentUserChangesSubscription,
  GetCurrentUserChangesSubscriptionVariables,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  LoginUserMutation,
  LoginUserMutationVariables,
  SendPasswordResetMutation,
  SendPasswordResetMutationVariables,
  SendSecondAuthFactorMutation,
  SendSecondAuthFactorMutationVariables,
  UpdateEmailMutation,
  UpdateEmailMutationVariables,
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables,
  UpdatePhoneMutation,
  UpdatePhoneMutationVariables,
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
  VerifyUserPhoneMutation,
  VerifyUserPhoneMutationVariables,
} from '../GraphQL/graphql'
import {
  GetCurrentUserChangesDocument,
  GetCurrentUserDocument,
  LoginUserDocument,
  SendPasswordResetDocument,
  SendSecondAuthFactorDocument,
  UpdateEmailDocument,
  UpdatePasswordDocument,
  UpdatePhoneDocument,
  UpdateProfileDocument,
  VerifyUserPhoneDocument,
} from '../GraphQL/graphql'

export const useProfile = () => {
  const { data, loading, subscribeToMore } = useQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >(GetCurrentUserDocument, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      GetCurrentUserChangesSubscription,
      GetCurrentUserChangesSubscriptionVariables
    >({
      document: GetCurrentUserChangesDocument,
      updateQuery: (prev, { subscriptionData: { data } }) => ({
        ...prev,
        ...data,
        __typename: prev.__typename,
      }),
    })
    return () => unsubscribe()
  }, [subscribeToMore])

  const loginUser = useMutation<LoginUserMutation, LoginUserMutationVariables>(
    LoginUserDocument
  )
  const sendSecondAuthFactor = useMutation<
    SendSecondAuthFactorMutation,
    SendSecondAuthFactorMutationVariables
  >(SendSecondAuthFactorDocument)
  const sendPasswordReset = useMutation<
    SendPasswordResetMutation,
    SendPasswordResetMutationVariables
  >(SendPasswordResetDocument)

  const updateEmail = useMutation<
    UpdateEmailMutation,
    UpdateEmailMutationVariables
  >(UpdateEmailDocument)

  const updatePassword = useMutation<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
  >(UpdatePasswordDocument)

  const updatePhone = useMutation<
    UpdatePhoneMutation,
    UpdatePhoneMutationVariables
  >(UpdatePhoneDocument)

  const updateProfile = useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument)

  const verifyUserPhone = useMutation<
    VerifyUserPhoneMutation,
    VerifyUserPhoneMutationVariables
  >(VerifyUserPhoneDocument)

  const mutations = useMemo(
    () => ({
      loginUser,
      sendSecondAuthFactor,
      sendPasswordReset,
      updateEmail,
      updatePassword,
      updatePhone,
      updateProfile,
      verifyUserPhone,
    }),
    [
      loginUser,
      sendPasswordReset,
      sendSecondAuthFactor,
      updateEmail,
      updatePassword,
      updatePhone,
      updateProfile,
      verifyUserPhone,
    ]
  )

  return useMemo(
    () => ({
      profile: data?.getCurrentUser ?? undefined,
      profileLoading: loading,
      mutations,
    }),
    [data, loading, mutations]
  )
}
