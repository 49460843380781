import classNames from 'classnames'
import type { FC } from 'react'

export type HeadingProps = React.ButtonHTMLAttributes<HTMLDivElement> & {
  variant?: 'h1' | 'h2' | 'h3' | 'h4'
  center?: boolean
}

export const Heading: FC<HeadingProps> = ({
  children,
  variant = 'h1',
  center = false,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'font-heading text-dro-blue',
        variant === 'h1' && 'text-4xl mt-5 md:text-5xl',
        variant === 'h2' && 'text-2xl mt-3 md:text-3xl',
        center && 'text-center',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
