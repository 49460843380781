import classNames from 'classnames'
import type { PropsWithChildren } from 'react'
import type { Circle } from '../Layout/Layout'
import { Layout } from '../Layout/Layout'

interface ShellProps {
  autogeneratedCircles: number
  preGeneratedCircles: Array<Circle>
  noLayout?: boolean
}

export const Shell: React.FC<PropsWithChildren<ShellProps>> = ({
  children,
  autogeneratedCircles,
  preGeneratedCircles,
  noLayout,
}) => {
  if (process.env.NODE_ENV === 'development') {
    return (
      <div className="flex flex-col bg-white min-h-screen">
        <Layout
          className={classNames(
            'flex-1',
            'px-2 pt-1',
            'md:px-4 md:pt-2 md:pb-4',
            'lg:px-10 md:pt-4 lg:pb-10'
          )}
          autogeneratedCircles={autogeneratedCircles}
          preGeneratedCircles={preGeneratedCircles}
          transparent={!!noLayout}
        >
          {/* <AppHeader className="lg:mb-2" /> */}
          {children}
        </Layout>
        {/* <AppFooter className="grow-1 shrink-1 mt-6 mb-[22px] ml-20 mr-[120px]" /> */}
      </div>
    )
  }

  return <>{children}</>
}
