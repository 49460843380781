import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AlertMessage } from '../../Components/AlertMessage/AlertMessage'
import { Button } from '../../Components/Button'
import { FormInput } from '../../Components/FormInput/FormInput'
import { Heading } from '../../Components/Heading/Heading'
import { Shell } from '../../Components/Shell/Shell'
import { Spinner } from '../../Components/Spinner/Spinner'
import type {
  GetUserFromPasswordRefreshTokenQuery,
  GetUserFromPasswordRefreshTokenQueryVariables,
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables,
} from '../../GraphQL/graphql'
import {
  GetUserFromPasswordRefreshTokenDocument,
  ProfileError,
  UpdatePasswordDocument,
} from '../../GraphQL/graphql'
import { authErrorToMessage, passwordRegex } from '../../Helper/AuthHelper'
import { profileErrorToMessage } from '../../Helper/UserHelper'

type PasswordResetParams = {
  userId: string
  token: string
}
export const PasswordReset: React.FC = () => {
  const { t } = useTranslation()
  const { userId, token } = useParams<PasswordResetParams>()

  const [getUser, { data: userData, loading: userLoading, error: userError }] =
    useLazyQuery<
      GetUserFromPasswordRefreshTokenQuery,
      GetUserFromPasswordRefreshTokenQueryVariables
    >(GetUserFromPasswordRefreshTokenDocument)

  useEffect(() => {
    if (userId && token) {
      getUser({ variables: { userId, token } })
    }
  }, [getUser, token, userId])

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState: { errors, isDirty, isSubmitting, isSubmitSuccessful },
  } = useForm<{ password: string; passwordRepeat: string }>({
    defaultValues: {
      password: undefined,
      passwordRepeat: undefined,
    },
  })

  const navigate = useNavigate()

  const [updatePassword] = useMutation<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
  >(UpdatePasswordDocument, { fetchPolicy: 'network-only' })

  const onSubmit = handleSubmit(async ({ password, passwordRepeat }) => {
    if (password !== passwordRepeat) {
      setError('passwordRepeat', {
        message: t('error.profile.Passwords do not match'),
      })
      return
    }
    if (!userId || !token) {
      setError('root', {
        message: t(
          'error.An error has occurred. Please try to reload the page.'
        ),
      })
      return
    }
    await updatePassword({
      variables: {
        password,
        userId,
        token,
      },
      onCompleted: ({ updatePassword }) => {
        if (updatePassword.authError) {
          setError('root', {
            message: authErrorToMessage(updatePassword.authError, t),
          })
        } else if (updatePassword.profileErrors) {
          const errors: string[] = []
          updatePassword.profileErrors.forEach((error) => {
            switch (error) {
              case ProfileError.InvalidPasswordFormat:
                setError('password', {
                  message: profileErrorToMessage(error, t),
                })
                break
              default:
                errors.push(profileErrorToMessage(error, t))
                break
            }
            if (errors.length) {
              setError('root', {
                message: errors.join(' | '),
              })
            }
          })
        } else if (!updatePassword.success) {
          setError('root', {
            message: t(
              'error.Saving not possible. If this error occurs repeatedly, please contact our support.'
            ),
          })
        } else {
          navigate('/login')
        }
      },
      onError: () =>
        setError('root', {
          message: t(
            'error.Saving not possible. If this error occurs repeatedly, please contact our support.'
          ),
        }),
    })
  })

  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]}>
      <div className="max-w-md mx-auto">
        <Heading variant="h1" className="text-center">
          {t('pages.password.Reset password')}
        </Heading>
        <div className="flex flex-col space-y-6 mt-5">
          {userLoading && <Spinner />}
          {!userData?.getUserFromPasswordRefreshToken && userError && (
            <AlertMessage
              type="warning"
              message={t('error.Could not load user')}
            />
          )}
          {userData?.getUserFromPasswordRefreshToken.error && (
            <AlertMessage
              type="warning"
              message={authErrorToMessage(
                userData.getUserFromPasswordRefreshToken.error,
                t
              )}
            />
          )}
        </div>
        {userData?.getUserFromPasswordRefreshToken.email && (
          <div className="flex flex-col space-y-6">
            <p className="text-center">
              {t('pages.password.Change password of user email.', {
                email: userData.getUserFromPasswordRefreshToken.email,
              })}
            </p>
            <div className="space-y-6">
              {errors.root?.message && (
                <AlertMessage
                  type="warning"
                  message={errors.root.message}
                  onClose={() => clearErrors('root')}
                />
              )}
              {isSubmitSuccessful && !isDirty && (
                <AlertMessage
                  type="success"
                  message={t('pages.profile.Password successfully saved.')}
                />
              )}
              <form onSubmit={onSubmit} className="space-y-6">
                <FormInput
                  id="password"
                  invisible
                  size="lg"
                  label={t('pages.profile.New password')}
                  disabled={isSubmitting}
                  type="password"
                  register={register}
                  options={{
                    required: t('error.This field cannot be empty.'),
                    pattern: {
                      value: passwordRegex,
                      message: profileErrorToMessage(
                        ProfileError.InvalidPasswordFormat,
                        t
                      ),
                    },
                  }}
                  errors={errors}
                />

                <FormInput
                  id="passwordRepeat"
                  invisible
                  size="lg"
                  label={t('pages.profile.Repeat password')}
                  disabled={isSubmitting}
                  type="password"
                  register={register}
                  options={{
                    required: t('error.This field cannot be empty.'),
                    validate: (passwordRepeat) => {
                      return getValues('password') !== passwordRepeat
                        ? t('error.profile.Passwords do not match')
                        : undefined
                    },
                  }}
                  errors={errors}
                />
                <div className="mt-5 flex justify-center">
                  <Button
                    disabled={isSubmitting || !isDirty || userLoading}
                    type="submit"
                    size="lg"
                  >
                    {isSubmitting
                      ? t('components.button.Saving')
                      : t('components.button.Save')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </Shell>
  )
}
