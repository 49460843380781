import React from 'react'
import { ReactComponent as CompressIcon } from '../../assets/compress.svg'
import { ReactComponent as ExpandIcon } from '../../assets/expand.svg'
import type { SessionChatFragment } from '../../GraphQL/graphql'
import type { UseSessionStateType } from '../../Hooks/useActiveSession'
import { ChatVideo } from '../ChatVideo/ChatVideo'
import { DeleteChatButton } from '../DeleteChatButton/DeleteChatButton'
import { MdRenderer } from '../MdRenderer/MdRenderer'

interface Props {
  sessionId?: string
  messages?: SessionChatFragment['messages']
  lastBotMessage?: SessionChatFragment['messages'][0]
  isFullscreen?: boolean
  switchFullscreen?: () => void
  useSessionState?: UseSessionStateType
  videoPath?: string
}

export const Dialog: React.FC<Props> = ({
  sessionId,
  messages,
  lastBotMessage,
  isFullscreen,
  switchFullscreen,
  useSessionState,
  videoPath,
}) => {
  const [sesstionState, _] = useSessionState ?? []
  return (
    <div className="relative">
      <div className="my-0 mx-auto h-full max-h-[70vh] w-full pt-4">
        <button
          onClick={switchFullscreen}
          className="absolute top-10 left-6 z-50"
        >
          {isFullscreen ? (
            <CompressIcon className="w-12 h-12 fill-dro-gray" />
          ) : (
            <ExpandIcon className="w-12 h-12 fill-dro-gray" />
          )}
        </button>
        <ChatVideo
          videoIdentifier={lastBotMessage?.treeElementIdentifier}
          isLoading={sesstionState?.loading}
          videoPath={videoPath}
        />
        {!sesstionState?.loading && lastBotMessage?.botText && (
          <div className="mx-4 absolute right-0 left-0 bottom-0 backdrop-blur-lg rounded-lg first:mt-0 last:mb-0 box-border">
            {sessionId &&
              !sesstionState?.loading &&
              (messages?.length ?? 0) > 1 &&
              messages?.find((message) => message.userText) && (
                <DeleteChatButton
                  className="bg-white rounded-lg absolute py-1 px-2 text-sm -top-9 right-0 hover:underline"
                  sessionId={sessionId}
                  useSessionState={useSessionState}
                  lastElementId={messages?.at(-1)?.treeElementId}
                />
              )}
            <div className=" p-3 overflow-auto max-h-40">
              <MdRenderer mdText={lastBotMessage?.botText} styling={false} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
