import { use } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getLanguageCode } from './Helper/LanguageHelper'
import { DE_DE } from './locales/de_DE'
import { EN_US } from './locales/en_US'

export const fallbackLng = 'de_DE'

export const resources = {
  de: {
    translation: DE_DE,
  },
  en: {
    translation: EN_US,
  },
} as const

use(initReactI18next).init({
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  fallbackLng,
  resources,
  lng: getLanguageCode(),
  interpolation: {
    escapeValue: false,
  },
})
