import { getLanguageCode, setLanguageCode } from '../../Helper/LanguageHelper'
import './LanguageSelector.scss'

export const LanguageSelector: React.FC = () => {
  return (
    <select
      className="LanguageSelector"
      value={getLanguageCode()}
      onChange={(e) => {
        setLanguageCode(e.currentTarget.value)
        window.location.reload()
      }}
    >
      {['de', 'en'].map((val) => (
        <option key={val} value={val}>
          {val}
        </option>
      ))}
    </select>
  )
}
