import type { DE_DE } from './de_DE'

export const EN_US = {
  company: {
    'company-ceo': 'CEO: Markus Helfenstein',
    'company-city': 'Pullach im Isartal',
    'company-copyright': 'Copyright © 2010-2024 DocRobin',
    'company-email': 'info@docrobin.net',
    'company-legal-court': 'Local Court Munich',
    'company-legal-location': 'Registered Office: Munich',
    'company-legal-number': 'HRB 277443',
    'company-name': 'Medical Intelligence Lab GmbH',
    'company-phone': '+49 (0)7565-5054069',
    'company-street': 'Birkenallee 35',
    'company-zip': '82049',
    'company-bank-iban': 'DE 8744 0400 3703 2150 5000',
    'company-bank-holder': 'DocRobin',
  },
  components: {
    button: {
      Abort: 'Abort',
      Back: 'Back',
      Continue: 'Continue',
      Delete: 'Delete',
      Edit: 'Edit',
      Loading: 'Loading...',
      Save: 'Save',
      Saving: 'Saving...',
      Send: 'Send',
      Sending: 'Sending...',
    },
    footer: {
      Help: 'Help',
      Imprint: 'Imprint',
      'Privacy Policy': 'Privacy Policy',
      'Terms of Use': 'Terms of Use',
    },
    navigation: {
      Chat: 'Chat',
      Dialog: 'Dialog',
      Files: 'Files',
      Login: 'Login',
      Logout: 'Logout',
      'Primary navigation': 'Primary navigation',
      Profile: 'Profile',
      Reports: 'Reports',
      'Sign up': 'Sign up',
    },
  },
  language: {
    de: 'German',
    en: 'English',
  },
  error: {
    'An error has occurred. If this error occurs repeatedly, please get in touch with our support team.':
      'An error has occurred. If this error occurs repeatedly, please get in touch with our support team.',
    'An error has occurred. Please get in touch with our support team.':
      'An error has occurred. Please get in touch with our support team.',
    'An error has occurred. Please try to reload the page.':
      'An error has occurred. Please try to reload the page.',
    'Invalid link': 'Invalid link',
    auth: {
      'Could not find user.': 'Could not find user.',
      'Invalid Password.': 'Invalid Password.',
      'Please contact our support team to unlock your account.':
        'Please contact our support team to unlock your account.',
      'The activation link has expired or is invalid.':
        'The activation link has expired or is invalid.',
      'The Code has expired or is invalid.':
        'The Code has expired or is invalid.',
      'The data is incorrect.': 'The details is incorrect.',
      'The E-Mail with the activation code could not be sent.':
        'The E-Mail with the activation code could not be sent.',
      'The email has not been updated, because it matches the old one.':
        'The email has not been updated, because it matches the old one.',
      'The email must be confirmed with the link from the activation email.':
        'The email must be confirmed with the link from the activation email.',
      'The phone number must be confirmed.':
        'The phone number must be confirmed.',
      'The registration has failed.': 'The registration has failed.',
      'The user has been verified already.':
        'The user has been verified already.',
      'This user exists already.': 'This user exists already.',
      'Wrong password. Typo?': 'Wrong password. Typo?',
    },
    'Could not load data': 'Could not load data',
    'Could not load user': 'Could not load user',
    profile: {
      'Including country code, e.g. +10123456789.':
        'Including country code, e.g. +10123456789.',
      'Passwords do not match': 'Passwords do not match',
      'Please enter an email': 'Please enter an email',
      'The postal code is not valid': 'The postal code is not valid',
      'This country is not yet supported': 'This country is not yet supported',
      'Wrong format.': 'Wrong format.',
      'You must be at least 18 years old.':
        'You must be at least 18 years old.',
    },
    'Saving not possible. If this error occurs repeatedly, please contact our support.':
      'Saving not possible. If this error occurs repeatedly, please contact our support.',
    'This field cannot be empty.': 'This field cannot be empty.',
  },
  link: {
    'company-help-page': 'https://docrobin.net/hilfe/',
    'company-imprint-page': 'https://docrobin.net/impressum/',
    'company-privacy-policy': 'https://docrobin.net/datenschutzerklaerung/',
    'company-terms-of-use':
      'https://docrobin.net/allgemeine-geschaeftsbedingungen-agb/',
    'company-website': 'https://docrobin.net',
    'research-lmu-help':
      'https://lmu-assets.eu-central-1.linodeobjects.com/hilfe.pdf',
    'research-lmu-patient-information':
      'https://lmu-assets.eu-central-1.linodeobjects.com/patienteninformationsschrift.pdf',
  },
  pages: {
    conversation: {
      'An open order has been found, you will be forwarded':
        'An open order has been found, you will be forwarded',
      'Change answer': 'Change answer',
      'Change last answer': 'Change last answer',
      'Confirm selection': 'Confirm selection',
      'Conversation history': 'Conversation history',
      'End conversation': 'End conversation',
      'Go to overview': 'Go to overview',
      Help: 'Help',
      'Help and Answers': 'Help and Answers',
      'Load previous state (login)': 'Load previous state (login)',
      'No conversation history found': 'No conversation history found',
      Options: 'Options',
      'Patient Information and Consent Form':
        'Patient Information and Consent Form',
      'Repeat video': 'Repeat video',
      'Save current state (register and transfer)':
        'Save current state (register and transfer)',
      'Save current state (register)': 'Save current state (register)',
      'Show current anamnesis': 'Show current anamnesis',
      'Start over': 'Start over',
      'The session has expired': 'The session has expired',
    },
    files: {
      'Continue doctor talk': 'Continue doctor talk',
      'Currently, we are only evaluating knee X-rays. Please upload only X-ray images as shown in the sample images. Others will not be evaluated and will be deleted.':
        'Currently, we are only evaluating knee X-rays. Please upload only X-ray images as shown in the sample images. Others will not be evaluated and will be deleted.',
      'Date of study': 'Date of study',
      'Drag’n’drop DICOM files or click to select':
        'Drag’n’drop DICOM files or click to select',
      'Drop files here': 'Drop files here',
      'Image Series': 'Image Series',
      'No files have been uploaded yet': 'No files have been uploaded yet',
      'Upload count image series': 'Upload {{count}} image series',
      'Upload new x-ray images': 'Upload new x-ray images',
      'Warning! Only x-ray images will be uploaded!':
        'Warning! Only x-ray images will be uploaded!',
      'X-ray images': 'X-ray images',
    },
    login: {
      'Anamnesis Report': 'Anamnesis Report',
      'E-Mail': 'E-Mail',
      'E-Mail Token': 'E-Mail Token',
      Login: 'Login',
      'Not registered yet?': 'Not registered yet?',
      Password: 'Password',
      'Password reset instructions has been sent to email.':
        'Password reset instructions has been sent to {{email}}.',
      'Resend E-Mail confirmation': 'Resend E-Mail confirmation',
      'Reset password': 'Reset password',
      'Send E-Mail confirmation': 'Send E-Mail confirmation',
      'Sending E-Mail': 'Sending E-Mail...',
      'Sign up': 'Sign up',
      'SMS Token': 'SMS Token',
      'Start conversation': 'Unterhaltung starten',
      'Transfer chat history and overwrite the one saved in your profile.':
        'Transfer chat history and overwrite the one saved in your profile.',
      'Welcome to DocRobin. You will be redirected':
        'Welcome to DocRobin. You will be redirected…',
    },
    password: {
      'Change password of user email.': 'Change password of user {{email}}.',
      'Reset password': 'Reset password',
    },
    payment: {
      'Right of Cancellation': 'Right of Cancellation', // Widerrufsrecht
      'Your report is now being processed. Please pay for your order now.':
        'Your report is now being processed. Please pay for your order now.', // 'Ihr Gutachten ist nun in Bearbeitung. Bitte zahlen Sie jetzt Ihren Auftrag.'
      'I am aware that my right of withdrawal expires upon complete fulfillment of the contract by the expert.':
        'I am aware that my right of withdrawal expires upon complete fulfillment of the contract by the expert.', //Mit ist bekannt, dass mein Widerrufsrecht mit vollständiger Vertragserfüllung durch den Experten erlischt.
      'Go to reports': 'Go to reports',
      Order: 'Order',
      'Order amount': 'Order amount',
      'Total amount amount. How would you like to pay?':
        'Total amount: {{amount}}. How would you like to pay?', // Gesamtbetrag: {{amount}}. Wie möchten Sie zahlen?
      'Order with costs': 'Order with costs',
      'Expert Assessment Contract': 'Expert Assessment Contract', // 'Begutachtungsvertrag',
      'The information I provide when using this portal may be used for the purposes stated therein in compliance with all data protection regulations. (optional)':
        'The information I provide when using this portal may be used for the purposes stated therein in compliance with all data protection regulations. (optional)', // Meine bei Nutzung dieses Portals gemachten Angaben dürfen zu den darin genannten Zwecken unter Einhaltung aller datenschutzrechtlicher Bestimmungen weiter verwendet werden. (optional)
      'The DocRobin service may contact me at regular intervals to request information about my further course of treatment and my state of health after completion of the second opinion for the above-mentioned purposes in compliance with all data protection regulations. (optional)':
        'The DocRobin service may contact me at regular intervals to request information about my further course of treatment and my state of health after completion of the second opinion for the above-mentioned purposes in compliance with all data protection regulations. (optional)', // Der Service von DocRobin darf mich in regelmäßigen Abständen kontaktieren, um meine Informationen über meinen weiteren Behandlungsverlauf sowie meinen Gesundheitszustand nach Abschluss des Zweitgutachtens zu den oben genannten Zwecken unter Einhaltung aller datenschutzrechtlicher Bestimmungen abzufragen. (optional)
      'I agree to the exchange of data between DocRobin and the medical expert in accordance with this declaration of consent. (necessary)':
        'I agree to the exchange of data between DocRobin and the medical expert in accordance with this declaration of consent. (necessary)', // Mit dem Datenaustausch zwischen DocRobin und dem Zweitgutachter gemäß dieser Einwilligungserklärung bin ich einverstanden. (notwendig)
      Overview: 'Overview',
      IBAN: 'IBAN',
      Amount: 'Amount',
      'Account holder': 'Account holder',
      'Transfer purpose': 'Transfer purpose',
      'Pay later or by bank transfer': 'Pay later or by bank transfer',
      'Pay now': 'Pay now',
      'Payment cancelled': 'Payment cancelled',
      'Payment failed!': 'Payment failed!',
      'Payment processing': 'Payment processing',
      'Payment successful': 'Payment successful',
      'We will clarify the question for you To what extent does the indication for knee replacement surgery I have correspond to the national guideline? based solely on the information you have provided here.':
        'We will clarify the question for you: "To what extent does the indication for knee replacement surgery I have correspond to the national guideline?" based solely on the information you have provided here.', // Wir klären für Sie die Fragestellung „Inwieweit entspricht die mir vorliegende Indikation zur Knie-TEP der nationalen Leitlinie?“ unter ausschließlicher Berücksichtigung der von Ihnen hier gemachten Angaben.
      'The order total includes vat VAT.':
        'The order total includes {{vat}} VAT.', // In der Auftragssumme sind {totalVat} USt enthalten.
      'My assignment is based on the following appraisal contract with right of revocation':
        'My assignment is based on the following appraisal contract with right of revocation:',
      'I agree to the assessment contract. (necessary)':
        'I agree to the assessment contract. (necessary)', // Dem Begutachtungsvertrag stimme ich zu. (notwendig)
      'I agree to the right of withdrawal. (necessary)':
        'I agree to the right of withdrawal. (necessary)', // Dem Widerrufsrecht stimme ich zu. (notwendig)
      'Please select the language in which you would like to receive the report':
        'Please select the language in which you would like to receive the report', // Bitte wählen Sie die Sprache, in der Sie den Bericht erhalten möchten
      'You can also pay by bank transfer. Transfer the order amount with the intended purpose to the following account.':
        'You can also pay by bank transfer. Transfer the order amount with the intended purpose to the following account.',
      'The data donation is based on the following declaration':
        'The data donation is based on the following declaration',
      'Declaration of consent under data protection law':
        'Declaration of consent under data protection law',
    },
    profile: {
      Abort: 'Abort',
      'Back to Profile': 'Back to Profile',
      'Change Mail': 'Change Mail',
      'Confirm Phone': 'Confirm Phone',
      'Current password': 'Current password',
      'Day of birth': 'Day of birth',
      'E-Mail': 'E-Mail',
      'E-mail successfully saved. You have been sent a confirmation link to {{email}} that you can use to activate it.':
        'E-mail successfully saved. You have been sent a confirmation link to {{email}} that you can use to activate it.',
      'First name': 'First name',
      Gender: 'Gender',
      'Last name': 'Last name',
      'Mr.': 'Mr.',
      'Mrs.': 'Mrs.',
      'New password': 'New password',
      'Passord successfully changed': 'Passord successfully changed',
      Password: 'Password',
      'Password successfully saved.': 'Password successfully saved.',
      Person: 'Person',
      'Personal Information': 'Personal Information',
      Phone: 'Phone',
      'Phone successfully saved. You have been sent a code to phone that you can use to activate it.':
        'Phone successfully saved. You have been sent a code to {{phone}} that you can use to activate it.',
      Profile: 'Profile',
      'Profile successfully saved.': 'Profile successfully saved.',
      'Repeat password': 'Repeat password',
      'Save Information': 'Save Information',
      'Save Mail': 'Save Mail',
      'Save Password': 'Save Password',
      'Save Phone': 'Save Phone',
      'Save Profile': 'Save Profile',
      'The email has been verified': 'The email has been verified',
      Title: 'Title',
    },
    registration: {
      'About the person': 'About the person',
      'Already signed up?': 'Already signed up?',
      'No e-mail received? Please check your spam folder or correct your e-mail address.':
        'No e-mail received? Please check your spam folder or correct your e-mail address.',
      'Continue doctor talk': 'Continue doctor talk',
      'I agree to the terms of use and privacy policy.':
        'I agree to the <lnk_terms>General Terms and Conditions (GTC)</lnk_terms>.',
      'I am aware of my right of withdrawal and I agree to it.':
        'I am aware of my <lnk_privacy>Right of Withdrawal</lnk_privacy> and I agree to it.',
      'Please verify your E-Mail using the sent link':
        'Please verify your E-Mail using the sent link',
      'Protect your data!': 'Protect your data!',
      'Sign up': 'Sign up',
      'Sign up for free': 'Sign up for free',
      'These statements form the basis for your consultation with your doctor.':
        'These statements form the basis for your consultation with your doctor.',
      'Verify email': 'Verify email',
      'We have sent an activation link to':
        'We have sent an activation link to:',
      'Why provide the mobile phone number?':
        'Why provide the mobile phone number?',
      'If we fear misuse':
        'If we fear misuse, we will send you an SMS to your cell phone number to securely identify you when you log in and thus protect your data. With this combination of password and SMS, we follow the recommendation for 2-factor authentication of the German Federal Office for Information Security (BSI)',
    },
    reports: {
      'Assessment pending. As soon as your report has been issued, you will receive an e-mail.':
        'Assessment pending. As soon as your report has been issued, you will receive an e-mail.',
      'Payment outstanding. You will receive access to the report as soon as we have received your payment.':
        'Payment outstanding. You will receive access to the report as soon as we have received your payment.',
      'Anamnesis Report': 'Anamnesis Report',
      'Cancel order': 'Cancel order',
      'Cancellation date': 'Cancellation date',
      Cancelled: 'Cancelled',
      'Commissioning date': 'Commissioning date',
      Completed: 'Completed',
      'Could not load report.': 'Could not load report.',
      'Created at': 'Created at',
      'Download invoice': 'Download invoice',
      'E-Mail': 'E-Mail',
      'Go to conversation history': 'Go to conversation history',
      'Medical reports': 'Medical reports',
      'No reports available': 'No reports available',
      Orders: 'Orders',
      'Orthopedic X-ray report': 'Orthopedic X-ray report',
      'Paid at': 'Paid at',
      Patient: 'Patient',
      'Pay now': 'Pay now',
      Phone: 'Phone',
      Print: 'Print',
      'Release date': 'Release date',
      Report: 'Report',
      'Report not released yet': 'Report not released yet',
      'Request date': 'Request date',
      Reviewer: 'Reviewer',
      'Second opinion illness': 'Second opinion {{illness}}',
      'Share report for five minutes': 'Share report for five minutes',
      'Show anamnesis': 'Show anamnesis',
      'Show anamnesis of current conversation':
        'Show anamnesis of current conversation',
      'Show expert opinion': 'Show expert opinion',
      'Stop sharing': 'Stop sharing',
      'The report is currently being shared':
        'The report is currently being shared',
      'Time remaining': 'Time remaining',
      Website: 'Website',
    },
  },
} satisfies typeof DE_DE
