import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useProfile } from '../../Hooks/useProfile'
import { Loading } from '../../Modules/Loading/Loading'
import { Heading } from '../Heading/Heading'
import { Link } from '../Link/Link'
import { RegisterEnterEmailPassword } from '../RegisterEnterEmailPassword/RegisterEnterEmailPassword'
import { RegisterEnterProfileInfo } from '../RegisterEnterProfileInfo/RegisterEnterProfileInfo'
import { RegisterWaitEmailVerification } from '../RegisterWaitEmailVerification/RegisterWaitEmailVerification'
import { Shell } from '../Shell/Shell'

enum RegisterState {
  EnterEmailPassword,
  WaitEmailVerification,
  EnterProfileInfo,
  // VerifyPhone,
}

export const Register: React.FC = () => {
  const { profile, profileLoading } = useProfile()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const [formState, setFormState] = useState<RegisterState>(
    RegisterState.EnterEmailPassword
  )

  useEffect(() => {
    if (!profile || !(profile.pendingEmail || profile.email)) {
      setFormState(RegisterState.EnterEmailPassword)
    } else if (!profile.isEmailVerified) {
      setFormState(RegisterState.WaitEmailVerification)
    } else if (!(profile.pendingPhone || profile.phone)) {
      setFormState(RegisterState.EnterProfileInfo)
      // } else if (!profile.isPhoneVerified) {
      //   setFormState(RegisterState.VerifyPhone)
    }
  }, [profile])

  if (profileLoading) {
    return <Loading />
  }

  return (
    <Shell autogeneratedCircles={2} preGeneratedCircles={[]}>
      <div className="max-w-md mx-auto">
        <Heading variant="h1" className="text-center">
          {formState === RegisterState.EnterEmailPassword &&
            t('pages.registration.Sign up')}
          {formState === RegisterState.WaitEmailVerification &&
            t('pages.registration.Verify email')}
          {formState === RegisterState.EnterProfileInfo &&
            t('pages.registration.About the person')}
        </Heading>
        <div className="flex justify-center space-x-4 mt-5 mb-6">
          <p>{t('pages.registration.Already signed up?')}</p>
          <Link to={`/login?${searchParams}`}>{t('pages.login.Login')}</Link>
        </div>

        {formState === RegisterState.EnterEmailPassword && (
          <RegisterEnterEmailPassword />
        )}

        {formState === RegisterState.WaitEmailVerification && (
          <RegisterWaitEmailVerification profile={profile} />
        )}

        {formState === RegisterState.EnterProfileInfo && (
          <RegisterEnterProfileInfo profile={profile} />
        )}

        {/* {formState === RegisterState.VerifyPhone && (
          <>
            <RegisterEnterProfileInfo
              isDisabled
              smallPaddingBottom
              profile={profile}
            />
            <RegisterVerifyPhone />
          </>
        )} */}
      </div>
    </Shell>
  )
}
