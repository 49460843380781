import { useLazyQuery } from '@apollo/client'
import { useMemo } from 'react'
import type {
  GetOrderInvoiceQuery,
  GetOrderInvoiceQueryVariables,
} from '../GraphQL/graphql'
import { GetOrderInvoiceDocument } from '../GraphQL/graphql'

export const useOrderInvoice = () => {
  const [triggerQuery, { loading }] = useLazyQuery<
    GetOrderInvoiceQuery,
    GetOrderInvoiceQueryVariables
  >(GetOrderInvoiceDocument)

  return useMemo(
    () => ({
      getInvoice: triggerQuery,
      invoiceLoading: loading,
    }),
    [loading, triggerQuery]
  )
}
