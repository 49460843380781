import { CheckIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import React from 'react'

type CheckboxProps = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>

export const Checkbox: React.FC<CheckboxProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <label
      className={classNames(
        className,
        'relative flex items-start gap-3 group',
        'cursor-pointer'
      )}
      {...props}
    >
      <div
        className={classNames(
          'cursor-pointer',
          'rounded-md mt-1',
          'border border-dro-midgray bg-white',
          'group-aria-checked:bg-dro-green group-aria-checked:border-dro-green'
        )}
      >
        <CheckIcon
          className={classNames(
            'invisible group-aria-checked:visible',
            'w-5 h-5 text-dro-lightgray'
          )}
        />
      </div>
      <div className="flex-1 min-w-0 text-left mouse">{children}</div>
    </label>
  )
}
