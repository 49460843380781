import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  GetAnamnesisQuery,
  GetAnamnesisQueryVariables,
  ReportError,
} from '../GraphQL/graphql'
import {
  GetAnamnesisDocument,
  GetAnamnesisUpdatesDocument,
} from '../GraphQL/graphql'

export type UseAnamnesisResponse = {
  anamnesis?: string
  anamnesisError?: ReportError
  anamnesisLoading: boolean
}

export const useAnamnesis = (sessionId?: string): UseAnamnesisResponse => {
  const [getAnamnesis, { data, loading, subscribeToMore }] = useLazyQuery<
    GetAnamnesisQuery,
    GetAnamnesisQueryVariables
  >(GetAnamnesisDocument)

  useEffect(() => {
    sessionId && getAnamnesis({ variables: { sessionId } })
  }, [data, getAnamnesis, loading, sessionId])

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: GetAnamnesisUpdatesDocument,
      variables: { sessionId },
      updateQuery: (_, options) => {
        return options.subscriptionData.data
      },
    })

    return () => unsubscribe()
  }, [sessionId, subscribeToMore])

  return useMemo(
    () => ({
      anamnesis: data?.getAnamnesis?.text ?? undefined,
      anamnesisError: data?.getAnamnesis?.error ?? undefined,
      anamnesisLoading: loading,
    }),
    [data, loading]
  )
}
