import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Heading } from '../../Components/Heading/Heading'
import { ProfileUpdateEmail } from '../../Components/ProfileUpdateEmail/ProfileUpdateEmail'
import { ProfileUpdatePassword } from '../../Components/ProfileUpdatePassword/ProfileUpdatePassword'
import { ProfileUpdatePhone } from '../../Components/ProfileUpdatePhone/ProfileUpdatePhone'
import { ProfileUpdateProfile } from '../../Components/ProfileUpdateProfile/ProfileUpdateProfile'
import { Shell } from '../../Components/Shell/Shell'
import { Spinner } from '../../Components/Spinner/Spinner'
import { useProfile } from '../../Hooks/useProfile'
import { Alert } from '../../Modules/Alert/Alert'

export const Profile: FC = () => {
  const { t } = useTranslation()

  const { profile, profileLoading } = useProfile()

  return (
    <Shell autogeneratedCircles={2} preGeneratedCircles={[]} noLayout>
      <div>
        {profileLoading ? (
          <Spinner />
        ) : !profile ? (
          <Alert message={t('error.Could not load data')} />
        ) : (
          <>
            <Heading variant="h1">{t('pages.profile.Profile')}</Heading>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
              <ProfileUpdateEmail />

              <ProfileUpdatePhone />

              <ProfileUpdatePassword />

              <ProfileUpdateProfile />
            </div>
          </>
        )}
      </div>
    </Shell>
  )
}
