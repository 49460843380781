import classNames from 'classnames'
import type { ForwardedRef, InputHTMLAttributes } from 'react'
import { forwardRef } from 'react'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  variant?: 'primary'
  size?: 'sm' | 'md' | 'lg'
}

function InputInner(
  { size, className, children, ...rest }: Props,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <input
      ref={ref}
      className={classNames(
        'border-dro-midgray border',
        'inline-block w-full rounded-full',
        'transition duration-200 ease-in-out',
        size === 'md' && 'p-3',
        size === 'lg' && 'p-5',
        className
      )}
      {...rest}
    >
      {children}
    </input>
  )
}

export const Input = forwardRef(InputInner)
