import { CheckIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Anchor } from '../../Components/Anchor/Anchor'
import { Button } from '../../Components/Button'
import { Heading } from '../../Components/Heading/Heading'
import { Shell } from '../../Components/Shell/Shell'
import type { Currency } from '../../GraphQL/graphql'
import { moneyToString } from '../../Helper/TextHelper'
import { useGtm } from '../../Hooks/useGtm'
import { useProductSetForSession } from '../../Hooks/useProductSet'
import { Alert } from '../../Modules/Alert/Alert'
import { Loading } from '../../Modules/Loading/Loading'

export const Checkout = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { sessionId } = useParams()
  const [searchParams] = useSearchParams()
  const nextUrl = searchParams.get('next')
  const { loading, session, productSet, error, mutations } =
    useProductSetForSession(sessionId)
  const { trackOrderEvent } = useGtm()

  const [unfreezeSession, { loading: unfreezing }] = mutations.unfreezeSession
  const [createOrder, { loading: creating }] = mutations.createOrder

  useEffect(() => {
    if (session?.report) {
      if (session.report.orders.length === 1) {
        navigate(`/orders/${session.report.orders[0].id}/payment`)
      } else {
        navigate(`/reports/${session.report.id}/orders`)
      }
    }
  }, [navigate, session])

  const totalPrice = useMemo(() => {
    const grouped = _.groupBy(productSet?.patientProducts, (p) => p.currency)
    return Object.keys(grouped)
      .flatMap((currency) =>
        moneyToString(
          _.sumBy(grouped[currency], (p) => p.price ?? 0),
          currency as Currency
        )
      )
      .join(' | ')
  }, [productSet])

  const totalVat = useMemo(() => {
    const grouped = _.groupBy(productSet?.patientProducts, (p) => p.currency)
    return Object.keys(grouped)
      .flatMap((currency) =>
        moneyToString(
          _.sumBy(grouped[currency], (p) => p.vat ?? 0),
          currency as Currency
        )
      )
      .join(' | ')
  }, [productSet])

  const [appraisalContract, setAppraisalContract] = useState(false)
  const [withdrawalRight, setWithdrawalRight] = useState(false)
  const [dataProtection, setDataProtection] = useState(false)
  const [mayContact, setMayContact] = useState(false)
  const [shareData, setShareData] = useState(false)

  if (loading) {
    return (
      <Shell autogeneratedCircles={0} preGeneratedCircles={[]} noLayout>
        <Loading />
      </Shell>
    )
  }

  if (error) {
    return (
      <Shell autogeneratedCircles={0} preGeneratedCircles={[]} noLayout>
        <Alert
          message={t(
            'error.An error has occurred. If this error occurs repeatedly, please get in touch with our support team.'
          )}
        />
        {sessionId && (
          <Button
            onClick={async () => {
              const data = await unfreezeSession({
                variables: { sessionId },
              })
              if (!data.data?.unfreezeSession.error) {
                // TODO: implement via websocket
                nextUrl ? navigate(nextUrl) : navigate(-1)
              }
            }}
            variant="secondary"
            outline
            disabled={unfreezing}
          >
            {unfreezing
              ? t('components.button.Loading')
              : t('components.button.Back')}
          </Button>
        )}
      </Shell>
    )
  }

  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]} noLayout>
      <div className="mx-auto space-y-3 text-dro-blue">
        <Heading variant="h1">{t('pages.payment.Overview')}</Heading>
        <p>
          {t(
            'pages.payment.We will clarify the question for you To what extent does the indication for knee replacement surgery I have correspond to the national guideline? based solely on the information you have provided here.'
          )}
        </p>
        <table className="">
          <tbody>
            {productSet?.patientProducts.map((product) => (
              <tr key={product.id}>
                <td>{product.title}</td>
                <td className="text-right">
                  {moneyToString(product.price, product.currency)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>{t('pages.payment.Order amount')}</td>
              <td className="text-right">{totalPrice}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                {t('pages.payment.The order total includes vat VAT.', {
                  vat: totalVat,
                })}{' '}
              </td>
            </tr>
          </tfoot>
        </table>
        <p>
          {t(
            'pages.payment.My assignment is based on the following appraisal contract with right of revocation'
          )}{' '}
          <Anchor href="https://docrobin.net/stage/begutachtungsvertrag/" blank>
            {t('pages.payment.Expert Assessment Contract')}
          </Anchor>
          ,{' '}
          <Anchor
            href="https://docrobin.net/stage/begutachtungsvertrag#anlage_1"
            blank
          >
            {t('pages.payment.Right of Cancellation')}
          </Anchor>
        </p>
        <p>
          {t(
            'pages.payment.I am aware that my right of withdrawal expires upon complete fulfillment of the contract by the expert.'
          )}
        </p>
        <div className="pl-8">
          <label
            aria-checked={appraisalContract}
            className="relative flex items-start gap-3 mb-2 px-5 text-dro-blue  sm:px-0 group aria-checked:sm:bg-transparent"
            onClick={() => {
              setAppraisalContract(!appraisalContract)
            }}
          >
            <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green mt-1">
              <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
            </div>
            <div className="flex-1 min-w-0 text-left">
              {t(
                'pages.payment.I agree to the assessment contract. (necessary)'
              )}
            </div>
          </label>
          <label
            aria-checked={withdrawalRight}
            className="relative flex items-start gap-3 px-5 text-dro-blue  sm:px-0 group aria-checked:sm:bg-transparent"
            onClick={() => {
              setWithdrawalRight(!withdrawalRight)
            }}
          >
            <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green mt-1">
              <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
            </div>
            <div className="flex-1 min-w-0 text-left">
              {t(
                'pages.payment.I agree to the right of withdrawal. (necessary)'
              )}
            </div>
          </label>
        </div>
        <p>
          {t(
            'pages.payment.The data donation is based on the following declaration'
          )}
          {': '}
          <Anchor
            href="https://docrobin.net/stage/datenschutzrechtliche-einwilligungserklaerung-zur-datenspende/"
            blank
          >
            {t(
              'pages.payment.Declaration of consent under data protection law'
            )}
          </Anchor>
        </p>
        <div className="pl-8 mb-[2rem_!important]">
          <label
            aria-checked={dataProtection}
            className="relative flex items-start gap-3 px-5 text-dro-blue  sm:px-0 group aria-checked:sm:bg-transparent mb-2"
            onClick={() => {
              setDataProtection(!dataProtection)
            }}
          >
            <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green mt-1">
              <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
            </div>
            <div className="flex-1 min-w-0 text-left">
              {t(
                'pages.payment.The information I provide when using this portal may be used for the purposes stated therein in compliance with all data protection regulations. (optional)'
              )}
            </div>
          </label>
          <label
            aria-checked={mayContact}
            className="relative flex items-start gap-3 px-5 text-dro-blue  sm:px-0 group aria-checked:sm:bg-transparent mb-4"
            onClick={() => {
              setMayContact(!mayContact)
            }}
          >
            <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green mt-1">
              <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
            </div>
            <div className="flex-1 min-w-0 text-left">
              {t(
                'pages.payment.The DocRobin service may contact me at regular intervals to request information about my further course of treatment and my state of health after completion of the second opinion for the above-mentioned purposes in compliance with all data protection regulations. (optional)'
              )}
            </div>
          </label>
          <label
            aria-checked={shareData}
            className="relative flex items-start gap-3 px-5 text-dro-blue  sm:px-0 group aria-checked:sm:bg-transparent"
            onClick={() => {
              setShareData(!shareData)
            }}
          >
            <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green mt-1">
              <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
            </div>
            <div className="flex-1 min-w-0 text-left">
              {t(
                'pages.payment.I agree to the exchange of data between DocRobin and the medical expert in accordance with this declaration of consent. (necessary)'
              )}
            </div>
          </label>
        </div>

        {session?.report && (
          <Button
            to={
              session.report.orders.length === 1
                ? `/orders/${session.report.orders[0].id}/payment`
                : `/reports/${session.report.id}/orders`
            }
          >
            {t('pages.payment.Pay now')}
          </Button>
        )}
        {session && !session.report && (
          <>
            <Button
              className="mr-4"
              disabled={
                creating ||
                !appraisalContract ||
                !withdrawalRight ||
                !shareData ||
                unfreezing
              }
              onClick={async () => {
                const data = await createOrder({
                  variables: {
                    sessionId: session?.id,
                    productSetId: productSet?.id,
                    allowsFollowUp: mayContact,
                    allowsDataProcessing: dataProtection,
                  },
                })
                if (data.data?.createOrder.order) {
                  await trackOrderEvent()
                  navigate(`/orders/${data.data.createOrder.order.id}/payment`)
                }
              }}
            >
              {t('pages.payment.Order with costs')}
            </Button>{' '}
            <Button
              onClick={async () => {
                const data = await unfreezeSession({
                  variables: { sessionId: session.id },
                })
                if (!data.data?.unfreezeSession.error) {
                  // TODO: implement via websocket
                  nextUrl ? navigate(nextUrl) : navigate(-1)
                }
              }}
              variant="secondary"
              outline
              disabled={creating || unfreezing}
            >
              {unfreezing
                ? t('components.button.Loading')
                : t('components.button.Back')}
            </Button>
          </>
        )}
      </div>
    </Shell>
  )
}
