/* eslint-disable */
/* This is an autogenerated file. Do not edit directly! (codegen.yml) */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Upload: any;
};

export type ActionObject = {
  __typename?: 'ActionObject';
  type: ActionType;
  url?: Maybe<Scalars['String']>;
};

/** Types of different actions for Action Object. */
export enum ActionType {
  GoToAnamnesis = 'GO_TO_ANAMNESIS',
  GoToFiles = 'GO_TO_FILES',
  GoToLogin = 'GO_TO_LOGIN',
  GoToProfile = 'GO_TO_PROFILE',
  GoToRegistration = 'GO_TO_REGISTRATION',
  GoToReports = 'GO_TO_REPORTS',
  GoToUrl = 'GO_TO_URL',
  LogoutUser = 'LOGOUT_USER',
  PlaceOrder = 'PLACE_ORDER',
  RestartSession = 'RESTART_SESSION'
}

export type ActivateConversationTreeDataResponse = {
  __typename?: 'ActivateConversationTreeDataResponse';
  error?: Maybe<ConversationTreeError>;
  success: Scalars['Boolean'];
};

export type ActiveConversationInputObject = {
  __typename?: 'ActiveConversationInputObject';
  freeType?: Maybe<FreeType>;
  options?: Maybe<Array<Array<OptionObject>>>;
};

export type ActiveSessionErrorDetails = {
  __typename?: 'ActiveSessionErrorDetails';
  error?: Maybe<ConversationError>;
  orderId?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city: Scalars['String'];
  country: CountryCode;
  instruction?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type AddressObject = {
  __typename?: 'AddressObject';
  city: Scalars['String'];
  country: CountryCode;
  instruction?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  zip: Scalars['String'];
};

export enum AdminApplication {
  ConversationStarts = 'CONVERSATION_STARTS',
  ConversationTrees = 'CONVERSATION_TREES',
  Orders = 'ORDERS',
  OrdersReviewer = 'ORDERS_REVIEWER',
  Products = 'PRODUCTS',
  Reports = 'REPORTS',
  ReportEditor = 'REPORT_EDITOR',
  Users = 'USERS'
}

export enum AdminDashboardContext {
  Count = 'COUNT',
  Money = 'MONEY'
}

export type AdminDashboardInfo = {
  __typename?: 'AdminDashboardInfo';
  app: AdminApplication;
  context: AdminDashboardContext;
  info: Scalars['String'];
  title: Scalars['String'];
};

export enum AnnotationError {
  NotAllowed = 'NOT_ALLOWED',
  UnknownAnnotation = 'UNKNOWN_ANNOTATION',
  UnknownInstance = 'UNKNOWN_INSTANCE',
  WrongArguments = 'WRONG_ARGUMENTS'
}

export enum AnnotationType {
  Line = 'LINE'
}

/** Types of errors occuring during authentication. */
export enum AuthError {
  ExistingUser = 'EXISTING_USER',
  InvalidEmailToken = 'INVALID_EMAIL_TOKEN',
  InvalidPassword = 'INVALID_PASSWORD',
  InvalidPasswordToken = 'INVALID_PASSWORD_TOKEN',
  InvalidTwoFaToken = 'INVALID_TWO_FA_TOKEN',
  InvalidUser = 'INVALID_USER',
  NoEmailChange = 'NO_EMAIL_CHANGE',
  RegistrationFailed = 'REGISTRATION_FAILED',
  UnactivatedUser = 'UNACTIVATED_USER',
  UnknownUser = 'UNKNOWN_USER',
  UnverifiedEmail = 'UNVERIFIED_EMAIL',
  UnverifiedPhone = 'UNVERIFIED_PHONE',
  VerificationEmailSendingError = 'VERIFICATION_EMAIL_SENDING_ERROR',
  VerificationSmsSendingError = 'VERIFICATION_SMS_SENDING_ERROR',
  VerifiedUser = 'VERIFIED_USER'
}

export type AuthResponse = {
  __typename?: 'AuthResponse';
  error?: Maybe<AuthError>;
  success: Scalars['Boolean'];
};

export type CancelOrderReportResponse = {
  __typename?: 'CancelOrderReportResponse';
  error?: Maybe<OrderError>;
  success: Scalars['Boolean'];
};

/** Types of errors occuring during message. */
export enum ConversationError {
  ClosedSession = 'CLOSED_SESSION',
  ExpiredElement = 'EXPIRED_ELEMENT',
  ExpiredSession = 'EXPIRED_SESSION',
  FrozenSession = 'FROZEN_SESSION',
  InvalidInput = 'INVALID_INPUT',
  InvalidOption = 'INVALID_OPTION',
  InvalidSession = 'INVALID_SESSION',
  NotAllowed = 'NOT_ALLOWED',
  NoDeleteable = 'NO_DELETEABLE',
  NoInputAllowed = 'NO_INPUT_ALLOWED',
  NoNext = 'NO_NEXT',
  OpenOrder = 'OPEN_ORDER',
  RequiredInput = 'REQUIRED_INPUT',
  RequiredOption = 'REQUIRED_OPTION',
  UnknownElement = 'UNKNOWN_ELEMENT',
  UnknownOption = 'UNKNOWN_OPTION',
  UnknownSession = 'UNKNOWN_SESSION',
  UnknownStart = 'UNKNOWN_START'
}

export type ConversationMessageObject = {
  __typename?: 'ConversationMessageObject';
  botText?: Maybe<Scalars['String']>;
  creationTimestamp: Scalars['DateTime'];
  id: Scalars['String'];
  infoText?: Maybe<Scalars['String']>;
  treeElementId: Scalars['String'];
  treeElementIdentifier?: Maybe<Scalars['String']>;
  userText?: Maybe<Scalars['String']>;
};

export type ConversationSessionObject = {
  __typename?: 'ConversationSessionObject';
  criteria: Array<CriterionObject>;
  id: Scalars['String'];
  isActive: Scalars['Float'];
  isEndOfSession: Scalars['Float'];
  languageCode: LanguageCode;
  lastActiveTimestamp?: Maybe<Scalars['DateTime']>;
  messages: Array<ConversationMessageObject>;
  progress: Scalars['Float'];
  report?: Maybe<ReportObject>;
  startTimestamp?: Maybe<Scalars['DateTime']>;
};

export type ConversationTreeDataInput = {
  id?: InputMaybe<Scalars['String']>;
  identifier: Scalars['String'];
  parentId: Scalars['String'];
};

export type ConversationTreeDataObject = {
  __typename?: 'ConversationTreeDataObject';
  actions: Array<TreeActionObject>;
  activeFromTimestamp?: Maybe<Scalars['DateTime']>;
  checks: Array<TreeCheckObject>;
  criteria: Array<TreeCriterionObject>;
  id: Scalars['String'];
  identifier: Scalars['String'];
  isActive: Scalars['Boolean'];
  isStructureEditable: Scalars['Boolean'];
  reportTemplates: TreeReportTemplatesObject;
  settings: TreeSettingsObject;
  statements: Array<TreeStatementObject>;
};

export enum ConversationTreeError {
  NotAllowed = 'NOT_ALLOWED',
  UnknownAction = 'UNKNOWN_ACTION',
  UnknownCheck = 'UNKNOWN_CHECK',
  UnknownCriterion = 'UNKNOWN_CRITERION',
  UnknownElement = 'UNKNOWN_ELEMENT',
  UnknownIllness = 'UNKNOWN_ILLNESS',
  UnknownStatement = 'UNKNOWN_STATEMENT',
  UnknownTree = 'UNKNOWN_TREE',
  UnknownTreeData = 'UNKNOWN_TREE_DATA'
}

export type ConversationTreeInput = {
  id?: InputMaybe<Scalars['String']>;
  identifier: Scalars['String'];
  illnessId: Scalars['String'];
  videoPath: Scalars['String'];
};

export type ConversationTreeObject = {
  __typename?: 'ConversationTreeObject';
  activeData?: Maybe<ConversationTreeDataObject>;
  data: Array<ConversationTreeDataObject>;
  id: Scalars['String'];
  identifier: Scalars['String'];
  illness: IllnessObject;
  videoPath: Scalars['String'];
};

export enum ConversationTreeStatementInputType {
  Free = 'FREE',
  None = 'NONE',
  Options = 'OPTIONS'
}

export type ConversationTreeValidation = {
  __typename?: 'ConversationTreeValidation';
  duplicateCriteria: Array<Scalars['String']>;
  duplicateCriterionIdentifiers: Array<Scalars['String']>;
  duplicateElementIdentifiers: Array<Scalars['String']>;
  duplicateElements: Array<Scalars['String']>;
  elementsWithInvalidCriterion: Array<Scalars['String']>;
  elementsWithInvalidNext: Array<Scalars['String']>;
  invalidCriterionIdentifiers: Array<Scalars['String']>;
  invalidElementIdentifiers: Array<Scalars['String']>;
  invalidStart: Scalars['Boolean'];
  unusedCriteria: Array<Scalars['String']>;
  unusedElements: Array<Scalars['String']>;
};

/** ISO 3166-1 Alpha-2 */
export enum CountryCode {
  Ch = 'CH',
  De = 'DE',
  Gb = 'GB',
  Us = 'US'
}

export type CreateOrderResponse = {
  __typename?: 'CreateOrderResponse';
  error?: Maybe<OrderError>;
  order?: Maybe<OrderObject>;
};

export type CriterionObject = {
  __typename?: 'CriterionObject';
  identifier: Scalars['String'];
  operand: Scalars['Float'];
  positive: Scalars['Boolean'];
};

export enum Currency {
  Chf = 'chf',
  Eur = 'eur',
  Gbp = 'gbp',
  Usd = 'usd'
}

export type DeleteConversationTreeDataResponse = {
  __typename?: 'DeleteConversationTreeDataResponse';
  error?: Maybe<ConversationTreeError>;
  success: Scalars['Boolean'];
};

export type DeleteConversationTreeResponse = {
  __typename?: 'DeleteConversationTreeResponse';
  error?: Maybe<ConversationTreeError>;
  success: Scalars['Boolean'];
};

export type DeleteIllnessResponse = {
  __typename?: 'DeleteIllnessResponse';
  error?: Maybe<IllnessError>;
  success: Scalars['Boolean'];
};

export type DeleteImageAnnotationResponse = {
  __typename?: 'DeleteImageAnnotationResponse';
  error?: Maybe<AnnotationError>;
  success: Scalars['Boolean'];
};

export type DeleteMessageResponse = {
  __typename?: 'DeleteMessageResponse';
  error?: Maybe<ConversationError>;
  success: Scalars['Boolean'];
};

export type DeleteReportResponse = {
  __typename?: 'DeleteReportResponse';
  error?: Maybe<ReportError>;
  success: Scalars['Boolean'];
};

export type DeleteUserResponse = {
  __typename?: 'DeleteUserResponse';
  error?: Maybe<AuthError>;
  success: Scalars['Boolean'];
};

export type DicomInstanceObject = {
  __typename?: 'DicomInstanceObject';
  id: Scalars['String'];
  number?: Maybe<Scalars['Int']>;
  seriesIndex?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

export enum DicomPatientError {
  LinkedSeries = 'LINKED_SERIES',
  ServerError = 'SERVER_ERROR',
  UnknownSeries = 'UNKNOWN_SERIES',
  UnknownUser = 'UNKNOWN_USER'
}

export type DicomPatientObject = {
  __typename?: 'DicomPatientObject';
  id: Scalars['String'];
  studies: Array<DicomStudyObject>;
  userId: Scalars['String'];
};

export type DicomSeriesObject = {
  __typename?: 'DicomSeriesObject';
  date?: Maybe<Scalars['DateTime']>;
  deletable: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  instances: Array<DicomInstanceObject>;
  linkedReportIds: Array<Scalars['String']>;
  modality?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
};

export type DicomStudyObject = {
  __typename?: 'DicomStudyObject';
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  institution?: Maybe<Scalars['String']>;
  series: Array<DicomSeriesObject>;
  uid: Scalars['String'];
};

/** Topics that can be answered without a conversation. */
export enum EvaluationQuestion {
  KneeOsteoarthrosis = 'KNEE_OSTEOARTHROSIS'
}

/** Types of free input. */
export enum FreeType {
  Date = 'DATE',
  Decimal = 'DECIMAL',
  Int = 'INT',
  String = 'STRING'
}

export type GetActiveSessionResponse = {
  __typename?: 'GetActiveSessionResponse';
  action?: Maybe<ActionObject>;
  error?: Maybe<ActiveSessionErrorDetails>;
  input?: Maybe<ActiveConversationInputObject>;
  session?: Maybe<ConversationSessionObject>;
  videoPath?: Maybe<Scalars['String']>;
};

export type GetConversationTreeActionResponse = {
  __typename?: 'GetConversationTreeActionResponse';
  action?: Maybe<TreeActionObject>;
  error?: Maybe<ConversationTreeError>;
};

export type GetConversationTreeCheckResponse = {
  __typename?: 'GetConversationTreeCheckResponse';
  check?: Maybe<TreeCheckObject>;
  error?: Maybe<ConversationTreeError>;
};

export type GetConversationTreeCriterionResponse = {
  __typename?: 'GetConversationTreeCriterionResponse';
  criterion?: Maybe<TreeCriterionObject>;
  error?: Maybe<ConversationTreeError>;
};

export type GetConversationTreeDataResponse = {
  __typename?: 'GetConversationTreeDataResponse';
  error?: Maybe<ConversationTreeError>;
  treeData?: Maybe<ConversationTreeDataObject>;
  treeDataJson?: Maybe<Scalars['JSON']>;
  validation?: Maybe<ConversationTreeValidation>;
};

export type GetConversationTreeResponse = {
  __typename?: 'GetConversationTreeResponse';
  error?: Maybe<ConversationTreeError>;
  tree?: Maybe<ConversationTreeObject>;
};

export type GetConversationTreeStatementResponse = {
  __typename?: 'GetConversationTreeStatementResponse';
  error?: Maybe<ConversationTreeError>;
  statement?: Maybe<TreeStatementObject>;
};

export type GetConversationTreesResponse = {
  __typename?: 'GetConversationTreesResponse';
  error?: Maybe<ConversationTreeError>;
  trees?: Maybe<Array<ConversationTreeObject>>;
};

export type GetDicomPatientResponse = {
  __typename?: 'GetDicomPatientResponse';
  dicomPatient?: Maybe<DicomPatientObject>;
  error?: Maybe<DicomPatientError>;
};

export type GetIllnessResponse = {
  __typename?: 'GetIllnessResponse';
  error?: Maybe<IllnessError>;
  illness?: Maybe<IllnessObject>;
};

export type GetIllnessesResponse = {
  __typename?: 'GetIllnessesResponse';
  error?: Maybe<IllnessError>;
  illnesses?: Maybe<Array<IllnessObject>>;
};

export type GetImageAnnotationResponse = {
  __typename?: 'GetImageAnnotationResponse';
  annotation?: Maybe<ImageAnnotationObject>;
  error?: Maybe<AnnotationError>;
};

export type GetImageAnnotationsResponse = {
  __typename?: 'GetImageAnnotationsResponse';
  annotations?: Maybe<Array<ImageAnnotationObject>>;
  error?: Maybe<AnnotationError>;
};

export type GetImageOperandResponse = {
  __typename?: 'GetImageOperandResponse';
  error?: Maybe<OperandError>;
  operand?: Maybe<ImageOperandObject>;
};

export type GetImageOperandsResponse = {
  __typename?: 'GetImageOperandsResponse';
  error?: Maybe<OperandError>;
  operands?: Maybe<Array<ImageOperandObject>>;
};

export type GetOrderInvoiceResponse = {
  __typename?: 'GetOrderInvoiceResponse';
  error?: Maybe<OrderError>;
  uri?: Maybe<Scalars['String']>;
};

export type GetOrderPaymentIntentResponse = {
  __typename?: 'GetOrderPaymentIntentResponse';
  error?: Maybe<Scalars['String']>;
  paymentIntent?: Maybe<PaymentIntentObject>;
};

export type GetOrderResponse = {
  __typename?: 'GetOrderResponse';
  error?: Maybe<OrderError>;
  order?: Maybe<OrderObject>;
};

export type GetOrdersResponse = {
  __typename?: 'GetOrdersResponse';
  error?: Maybe<OrderError>;
  orders?: Maybe<Array<OrderObject>>;
  summary?: Maybe<PayersSummaryObject>;
};

export type GetProductSetForSessionResponse = {
  __typename?: 'GetProductSetForSessionResponse';
  error?: Maybe<PaymentError>;
  productsSet?: Maybe<ProductsSetObject>;
  session?: Maybe<ConversationSessionObject>;
};

export type GetProductSetsResponse = {
  __typename?: 'GetProductSetsResponse';
  error?: Maybe<PaymentError>;
  productsSets?: Maybe<Array<ProductsSetObject>>;
};

export type GetReportResponse = {
  __typename?: 'GetReportResponse';
  error?: Maybe<ReportError>;
  report?: Maybe<ReportObject>;
};

export type GetReportTextResponse = {
  __typename?: 'GetReportTextResponse';
  error?: Maybe<ReportError>;
  text?: Maybe<Scalars['String']>;
};

export type GetReportsResponse = {
  __typename?: 'GetReportsResponse';
  error?: Maybe<ReportError>;
  reports?: Maybe<Array<ReportObject>>;
};

export type GetSessionResponse = {
  __typename?: 'GetSessionResponse';
  error?: Maybe<ConversationError>;
  session?: Maybe<ConversationSessionObject>;
};

export type GetSessionsResponse = {
  __typename?: 'GetSessionsResponse';
  error?: Maybe<ConversationError>;
  sessions?: Maybe<Array<ConversationSessionObject>>;
};

export type GetUserFromTokenResponse = {
  __typename?: 'GetUserFromTokenResponse';
  email?: Maybe<Scalars['String']>;
  error?: Maybe<AuthError>;
  id?: Maybe<Scalars['String']>;
};

export enum IllnessError {
  UnknownIllness = 'UNKNOWN_ILLNESS'
}

export type IllnessInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Array<TranslationInput>;
};

export type IllnessObject = {
  __typename?: 'IllnessObject';
  id: Scalars['String'];
  name: Scalars['String'];
  nameLocales: Array<TranslationObject>;
};

export type ImageAnnotationInput = {
  dicomInstanceId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  points: Array<Array<Scalars['Float']>>;
  type: AnnotationType;
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type ImageAnnotationObject = {
  __typename?: 'ImageAnnotationObject';
  dicomInstanceId: Scalars['String'];
  findingType?: Maybe<ImageFindingType>;
  id: Scalars['String'];
  points: Array<Array<Scalars['Float']>>;
  type: AnnotationType;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum ImageFindingType {
  IblKoalaJointnarrowing = 'IBL_KOALA_JOINTNARROWING',
  IblKoalaKl = 'IBL_KOALA_KL',
  IblKoalaOsteophytosis = 'IBL_KOALA_OSTEOPHYTOSIS',
  IblKoalaOsteosclerosis = 'IBL_KOALA_OSTEOSCLEROSIS',
  IblKoalaSpaceLateral = 'IBL_KOALA_SPACE_LATERAL',
  IblKoalaSpaceMedial = 'IBL_KOALA_SPACE_MEDIAL'
}

export type ImageOperandObject = {
  __typename?: 'ImageOperandObject';
  dicomSeriesId: Scalars['String'];
  findingType: ImageFindingType;
  id: Scalars['String'];
  label: Scalars['String'];
  order: Scalars['Int'];
  positive?: Maybe<Scalars['Boolean']>;
  uncalculateable: Scalars['Boolean'];
  unit?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount: Scalars['Int'];
  title: Scalars['String'];
  total: Scalars['Float'];
  vat?: Maybe<Scalars['Float']>;
};

export type InvoiceObject = {
  __typename?: 'InvoiceObject';
  buyer: InvoiceParty;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['String'];
  invoiceNumber?: Maybe<Scalars['String']>;
  items: Array<InvoiceItem>;
  paid?: Maybe<Scalars['Float']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  seller: InvoiceParty;
  state: InvoiceState;
  total: Scalars['Float'];
  vat?: Maybe<Scalars['Float']>;
  voidAt?: Maybe<Scalars['DateTime']>;
};

export enum InvoiceParty {
  Docrobin = 'DOCROBIN',
  Patient = 'PATIENT',
  Reviewer = 'REVIEWER'
}

export enum InvoiceState {
  Created = 'CREATED',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PartiallyVoid = 'PARTIALLY_VOID',
  Void = 'VOID'
}

export enum LanguageCode {
  DeDe = 'de_DE',
  EnUs = 'en_US'
}

export type LoginLinkResponse = {
  __typename?: 'LoginLinkResponse';
  error?: Maybe<AuthError>;
  expirationTimestamp?: Maybe<Scalars['DateTime']>;
  link?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken?: Maybe<Scalars['String']>;
  error?: Maybe<AuthError>;
  user?: Maybe<UserObject>;
};

export type Mutation = {
  __typename?: 'Mutation';
  TESTReceiveOrthanc: Scalars['Boolean'];
  activateConversationTreeData: ActivateConversationTreeDataResponse;
  cancelOrderReport: CancelOrderReportResponse;
  continueSession: SubmitConversationResponse;
  createLoginLink: LoginLinkResponse;
  createOrder: CreateOrderResponse;
  createResearchUser: ResearchUserResponse;
  deactivateLoginLink: LoginLinkResponse;
  deleteConversationTree: DeleteConversationTreeResponse;
  deleteConversationTreeCriterion: DeleteConversationTreeResponse;
  deleteConversationTreeData: DeleteConversationTreeDataResponse;
  deleteConversationTreeElement: DeleteConversationTreeResponse;
  deleteDicomSeries: GetDicomPatientResponse;
  deleteIllness: DeleteIllnessResponse;
  deleteImageAnnotation: DeleteImageAnnotationResponse;
  deleteInactiveEmptyUsers: Array<Scalars['String']>;
  deleteLastUserResponse: DeleteMessageResponse;
  deleteReport: DeleteReportResponse;
  deleteResearchUser: DeleteUserResponse;
  duplicateConversationTreeData: GetConversationTreeDataResponse;
  editReport: GetReportResponse;
  freezeReport: ReleaseReportResponse;
  loginUser: LoginResponse;
  loginUserWithToken: LoginResponse;
  logoutUser: Scalars['Boolean'];
  markOrderAsPaid: CancelOrderReportResponse;
  postponePayment: CancelOrderReportResponse;
  reauthenticateSocket: Scalars['Boolean'];
  refundOrderPayments: CancelOrderReportResponse;
  regenerateReport: GetReportResponse;
  registerUserAndSendEmailVerification: RegisterResponse;
  registerUserDiscardMail: RegisterResponse;
  registerUserDiscardPhone: RegisterResponse;
  registerUserProfileAndSendPhoneVerification: RegisterResponse;
  releaseReport: ReleaseReportResponse;
  requestEvaluationReport: RequestReportResponse;
  restartSession: DeleteMessageResponse;
  sendDicomInstancesToReporting: Scalars['Boolean'];
  sendPasswordReset: Scalars['Boolean'];
  sendSecondAuthFactor: AuthResponse;
  setConversationTreeDataSettings: GetConversationTreeDataResponse;
  setConversationTreeReportTemplates: GetConversationTreeDataResponse;
  setDicomSeriesOfReport: GetReportResponse;
  shareReport: ReportSecretResponse;
  unfreezeReport: ReleaseReportResponse;
  unfreezeSession: GetSessionResponse;
  updateEmail: ProfileUserResponse;
  updateImageOperand: GetImageOperandResponse;
  updatePassword: UpdatePasswordResponse;
  updatePhone: ProfileUserResponse;
  updateProfile: ProfileUserResponse;
  updateUserActiveState: ProfileUserResponse;
  uploadDicomFiles: GetDicomPatientResponse;
  upsertConversationTree: GetConversationTreeResponse;
  upsertConversationTreeAction: GetConversationTreeActionResponse;
  upsertConversationTreeCheck: GetConversationTreeCheckResponse;
  upsertConversationTreeCriterion: GetConversationTreeCriterionResponse;
  upsertConversationTreeData: GetConversationTreeDataResponse;
  upsertConversationTreeStatement: GetConversationTreeStatementResponse;
  upsertIllness: IllnessObject;
  upsertImageAnnotation: GetImageAnnotationResponse;
  upsertProduct: UpsertProductResponse;
  upsertProductPrice: UpsertProductPriceResponse;
  upsertProductsSet: UpsertProductSetResponse;
  upsertUser: ProfileUserResponse;
  verifyImageOperands: GetImageOperandsResponse;
  verifyUserEmail: ProfileVerificationResponse;
  verifyUserPhone: ProfileVerificationResponse;
};


export type MutationTestReceiveOrthancArgs = {
  instanceId: Scalars['String'];
};


export type MutationActivateConversationTreeDataArgs = {
  treeDataId: Scalars['String'];
};


export type MutationCancelOrderReportArgs = {
  orderId?: InputMaybe<Scalars['String']>;
  orderIds?: InputMaybe<Scalars['String']>;
  reportId?: InputMaybe<Scalars['String']>;
};


export type MutationContinueSessionArgs = {
  lastElementId?: InputMaybe<Scalars['String']>;
  responseOptionIds?: InputMaybe<Array<Scalars['String']>>;
  responseText?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['String'];
};


export type MutationCreateLoginLinkArgs = {
  userId: Scalars['String'];
};


export type MutationCreateOrderArgs = {
  allowsDataProcessing?: InputMaybe<Scalars['Boolean']>;
  allowsFollowUp?: InputMaybe<Scalars['Boolean']>;
  productSetId?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['String'];
};


export type MutationCreateResearchUserArgs = {
  researchCenter: ResearchCenter;
  researchName: ResearchName;
};


export type MutationDeactivateLoginLinkArgs = {
  userId: Scalars['String'];
};


export type MutationDeleteConversationTreeArgs = {
  treeId: Scalars['String'];
};


export type MutationDeleteConversationTreeCriterionArgs = {
  criterionId: Scalars['String'];
  treeDataId: Scalars['String'];
};


export type MutationDeleteConversationTreeDataArgs = {
  treeDataId: Scalars['String'];
};


export type MutationDeleteConversationTreeElementArgs = {
  elementId: Scalars['String'];
  treeDataId: Scalars['String'];
};


export type MutationDeleteDicomSeriesArgs = {
  seriesId: Scalars['String'];
};


export type MutationDeleteIllnessArgs = {
  id: Scalars['String'];
};


export type MutationDeleteImageAnnotationArgs = {
  annotationId: Scalars['String'];
};


export type MutationDeleteInactiveEmptyUsersArgs = {
  secondsInactive: Scalars['Int'];
};


export type MutationDeleteLastUserResponseArgs = {
  lastElementId?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['String'];
};


export type MutationDeleteReportArgs = {
  reportId: Scalars['String'];
};


export type MutationDeleteResearchUserArgs = {
  userId: Scalars['String'];
};


export type MutationDuplicateConversationTreeDataArgs = {
  currentTreeDataId: Scalars['String'];
  newIdentifier: Scalars['String'];
};


export type MutationEditReportArgs = {
  reportId: Scalars['String'];
  text: Scalars['String'];
};


export type MutationFreezeReportArgs = {
  reportId: Scalars['String'];
};


export type MutationLoginUserArgs = {
  email: Scalars['String'];
  factor: Scalars['String'];
  password: Scalars['String'];
  transferSession?: Scalars['Boolean'];
};


export type MutationLoginUserWithTokenArgs = {
  token: Scalars['String'];
};


export type MutationMarkOrderAsPaidArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type MutationPostponePaymentArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type MutationReauthenticateSocketArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
  languateCode?: InputMaybe<Scalars['String']>;
};


export type MutationRefundOrderPaymentsArgs = {
  orderId: Scalars['String'];
};


export type MutationRegenerateReportArgs = {
  reportId: Scalars['String'];
};


export type MutationRegisterUserAndSendEmailVerificationArgs = {
  email: Scalars['String'];
  next?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};


export type MutationRegisterUserProfileAndSendPhoneVerificationArgs = {
  phone: Scalars['String'];
  profile: ProfileInput;
};


export type MutationReleaseReportArgs = {
  reportId: Scalars['String'];
};


export type MutationRequestEvaluationReportArgs = {
  dicomSeriesIds?: InputMaybe<Array<Scalars['String']>>;
  question: EvaluationQuestion;
};


export type MutationRestartSessionArgs = {
  sessionId: Scalars['String'];
};


export type MutationSendDicomInstancesToReportingArgs = {
  instanceIds: Array<Scalars['String']>;
};


export type MutationSendPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationSendSecondAuthFactorArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSetConversationTreeDataSettingsArgs = {
  input: TreeSettingsInput;
  treeDataId: Scalars['String'];
};


export type MutationSetConversationTreeReportTemplatesArgs = {
  input: TreeReportTemplatesInput;
  treeDataId: Scalars['String'];
};


export type MutationSetDicomSeriesOfReportArgs = {
  dicomSeriesIds: Array<Scalars['String']>;
  regenerate?: InputMaybe<Scalars['Boolean']>;
  reportId: Scalars['String'];
};


export type MutationShareReportArgs = {
  reportId: Scalars['String'];
  stop?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUnfreezeReportArgs = {
  reportId: Scalars['String'];
};


export type MutationUnfreezeSessionArgs = {
  sessionId: Scalars['String'];
};


export type MutationUpdateEmailArgs = {
  email: Scalars['String'];
};


export type MutationUpdateImageOperandArgs = {
  operandId: Scalars['String'];
  positive: Scalars['Boolean'];
  value: Scalars['String'];
};


export type MutationUpdatePasswordArgs = {
  oldPassword?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdatePhoneArgs = {
  phone: Scalars['String'];
};


export type MutationUpdateProfileArgs = {
  profile: ProfileInput;
};


export type MutationUpdateUserActiveStateArgs = {
  isActivated: Scalars['Boolean'];
  userId: Scalars['String'];
};


export type MutationUploadDicomFilesArgs = {
  files: Array<Scalars['Upload']>;
};


export type MutationUpsertConversationTreeArgs = {
  input: ConversationTreeInput;
};


export type MutationUpsertConversationTreeActionArgs = {
  input: TreeActionInput;
  treeDataId: Scalars['String'];
};


export type MutationUpsertConversationTreeCheckArgs = {
  input: TreeCheckInput;
  treeDataId: Scalars['String'];
};


export type MutationUpsertConversationTreeCriterionArgs = {
  input: TreeCriterionInput;
  treeDataId: Scalars['String'];
};


export type MutationUpsertConversationTreeDataArgs = {
  input: ConversationTreeDataInput;
  treeDataJson?: InputMaybe<Scalars['JSON']>;
};


export type MutationUpsertConversationTreeStatementArgs = {
  input: TreeStatementInput;
  treeDataId: Scalars['String'];
};


export type MutationUpsertIllnessArgs = {
  input: IllnessInput;
};


export type MutationUpsertImageAnnotationArgs = {
  input: ImageAnnotationInput;
};


export type MutationUpsertProductArgs = {
  input: UpsertProductInput;
};


export type MutationUpsertProductPriceArgs = {
  input: UpsertProductPriceInput;
};


export type MutationUpsertProductsSetArgs = {
  input: ProductsSetInput;
};


export type MutationUpsertUserArgs = {
  user: UserInput;
};


export type MutationVerifyImageOperandsArgs = {
  operandIds: Array<Scalars['String']>;
};


export type MutationVerifyUserEmailArgs = {
  token: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationVerifyUserPhoneArgs = {
  smsToken: Scalars['String'];
};

export enum NextObjectType {
  Action = 'ACTION',
  Check = 'CHECK',
  Statement = 'STATEMENT',
  Tree = 'TREE'
}

export enum OperandError {
  NotAllowed = 'NOT_ALLOWED',
  UnknownOperand = 'UNKNOWN_OPERAND',
  WrongArguments = 'WRONG_ARGUMENTS'
}

export type OptionObject = {
  __typename?: 'OptionObject';
  id: Scalars['String'];
  text: Scalars['String'];
};

export enum OrderError {
  CantSendInvoice = 'CANT_SEND_INVOICE',
  EmptyDetails = 'EMPTY_DETAILS',
  InvoiceGenerateError = 'INVOICE_GENERATE_ERROR',
  NoOrder = 'NO_ORDER',
  PartiallyPaidOrder = 'PARTIALLY_PAID_ORDER',
  ReleasedReport = 'RELEASED_REPORT',
  ReportGenerateError = 'REPORT_GENERATE_ERROR',
  UnknownOrder = 'UNKNOWN_ORDER',
  UnknownReport = 'UNKNOWN_REPORT',
  WrongPaymentIntent = 'WRONG_PAYMENT_INTENT'
}

export type OrderObject = {
  __typename?: 'OrderObject';
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  id: Scalars['String'];
  invoices: Array<InvoiceObject>;
  languageCode: LanguageCode;
  orderNumber: Scalars['String'];
  patientInvoices: Array<InvoiceObject>;
  payments: Array<PaymentObject>;
  report: ReportObject;
  summary: PayersSummaryObject;
};

export type OrderSummaryCurrencyObject = {
  __typename?: 'OrderSummaryCurrencyObject';
  currency: Currency;
  open: Scalars['Float'];
  paid: Scalars['Float'];
  total: Scalars['Float'];
};

export type OrderSummaryObject = {
  __typename?: 'OrderSummaryObject';
  paidAt?: Maybe<Scalars['DateTime']>;
  state: InvoiceState;
  values: Array<OrderSummaryCurrencyObject>;
  voidAt?: Maybe<Scalars['DateTime']>;
};

export type PayersSummaryObject = {
  __typename?: 'PayersSummaryObject';
  docrobin: OrderSummaryObject;
  patient: OrderSummaryObject;
  reviewer: OrderSummaryObject;
};

export enum PaymentError {
  InvalidConversationSession = 'INVALID_CONVERSATION_SESSION',
  InvalidCountry = 'INVALID_COUNTRY',
  InvalidProductsSet = 'INVALID_PRODUCTS_SET',
  InvoiceGenerateError = 'INVOICE_GENERATE_ERROR',
  UnknownConversationSession = 'UNKNOWN_CONVERSATION_SESSION',
  UnknownIllness = 'UNKNOWN_ILLNESS',
  UnknownOrder = 'UNKNOWN_ORDER',
  UnknownProductsSet = 'UNKNOWN_PRODUCTS_SET'
}

export type PaymentIntentObject = {
  __typename?: 'PaymentIntentObject';
  id: Scalars['String'];
  status: Scalars['String'];
};

export type PaymentObject = {
  __typename?: 'PaymentObject';
  currency: Currency;
  id: Scalars['String'];
  stripe: StripeObject;
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
  vat: Scalars['Float'];
};

export type ProductMetadata = {
  buyer?: InputMaybe<InvoiceParty>;
  feeSeduleNumber?: InputMaybe<Scalars['String']>;
  increaseRate?: InputMaybe<Scalars['Float']>;
  seller?: InputMaybe<InvoiceParty>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductObject = {
  __typename?: 'ProductObject';
  buyer: InvoiceParty;
  currency?: Maybe<Currency>;
  feeSeduleNumber?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  increaseRate?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  prices: Array<ProductPriceObject>;
  rawVat?: Maybe<Scalars['Float']>;
  seller: InvoiceParty;
  title: Scalars['String'];
  titleLocales: Array<TranslationObject>;
  vat?: Maybe<Scalars['Float']>;
};

export type ProductPriceObject = {
  __typename?: 'ProductPriceObject';
  countryCode: CountryCode;
  currency: Currency;
  id: Scalars['String'];
  subtotal: Scalars['Float'];
};

export type ProductSetMetadataInput = {
  illnessId?: InputMaybe<Scalars['String']>;
  reportType?: InputMaybe<ReportType>;
};

export type ProductSetTranslationInput = {
  languageCode: Scalars['String'];
  text: Scalars['String'];
};

export type ProductTranslationInput = {
  languageCode: Scalars['String'];
  text: Scalars['String'];
};

export type ProductsSetInput = {
  locales?: InputMaybe<Array<ProductSetTranslationInput>>;
  metadata: ProductSetMetadataInput;
  productSetId: Scalars['String'];
};

export type ProductsSetObject = {
  __typename?: 'ProductsSetObject';
  id: Scalars['String'];
  illness: IllnessObject;
  patientProducts: Array<ProductObject>;
  products: Array<ProductObject>;
  reportType: ReportType;
  title: Scalars['String'];
  titleLocales: Array<TranslationObject>;
};

/** Types of errors occuring during changing user profile information. */
export enum ProfileError {
  InvalidBirthdateFormat = 'INVALID_BIRTHDATE_FORMAT',
  InvalidCityFormat = 'INVALID_CITY_FORMAT',
  InvalidCountryFormat = 'INVALID_COUNTRY_FORMAT',
  InvalidEmailFormat = 'INVALID_EMAIL_FORMAT',
  InvalidFirstnameFormat = 'INVALID_FIRSTNAME_FORMAT',
  InvalidInstructionFormat = 'INVALID_INSTRUCTION_FORMAT',
  InvalidLastnameFormat = 'INVALID_LASTNAME_FORMAT',
  InvalidPasswordFormat = 'INVALID_PASSWORD_FORMAT',
  InvalidPhoneFormat = 'INVALID_PHONE_FORMAT',
  InvalidStreetFormat = 'INVALID_STREET_FORMAT',
  InvalidTitleFormat = 'INVALID_TITLE_FORMAT',
  InvalidZipFormat = 'INVALID_ZIP_FORMAT',
  UnsupportedCountry = 'UNSUPPORTED_COUNTRY'
}

export type ProfileInput = {
  birthdate: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  sex: ProfileSex;
  title?: InputMaybe<Scalars['String']>;
};

export enum ProfileSex {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type ProfileUserResponse = {
  __typename?: 'ProfileUserResponse';
  authError?: Maybe<AuthError>;
  profileErrors?: Maybe<Array<ProfileError>>;
  user?: Maybe<UserObject>;
};

export type ProfileVerificationResponse = {
  __typename?: 'ProfileVerificationResponse';
  accessToken?: Maybe<Scalars['String']>;
  error?: Maybe<AuthError>;
  user?: Maybe<UserObject>;
};

export type Query = {
  __typename?: 'Query';
  getAccessRefreshToken: Scalars['String'];
  getActiveSession: GetActiveSessionResponse;
  getAdminDashboardInfo: Array<AdminDashboardInfo>;
  getAnamnesis: GetReportTextResponse;
  getConversationTree: GetConversationTreeResponse;
  getConversationTreeData: GetConversationTreeDataResponse;
  getConversationTrees: GetConversationTreesResponse;
  getCurrentUser?: Maybe<UserObject>;
  getDicomPatient: GetDicomPatientResponse;
  getIllness: GetIllnessResponse;
  getIllnesses: GetIllnessesResponse;
  getImageAnnotations: GetImageAnnotationsResponse;
  getImageOperands: GetImageOperandsResponse;
  getOrder: GetOrderResponse;
  getOrderInvoice: GetOrderInvoiceResponse;
  getOrderPaymentIntent: GetOrderPaymentIntentResponse;
  getOrders: GetOrdersResponse;
  getProductSetForSession: GetProductSetForSessionResponse;
  getProductSets: GetProductSetsResponse;
  getRegisteredUsers: Array<UserObject>;
  getReport: GetReportResponse;
  getReportPreview: GetReportTextResponse;
  getReports: GetReportsResponse;
  getReportsQueue: GetReportsResponse;
  getResearchResults: Scalars['String'];
  /** Returns all reports of a user. Users cannot pass userId argument, as allways their own reports are returned. Doctors can ask for specific user id. */
  getSession: GetSessionResponse;
  /** Returns all reports of a user. Users cannot pass userId argument, as allways their own reports are returned. Doctors can ask for specific user id. */
  getSessions: GetSessionsResponse;
  getUser?: Maybe<UserObject>;
  getUserFromPasswordRefreshToken: GetUserFromTokenResponse;
};


export type QueryGetAccessRefreshTokenArgs = {
  create?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetActiveSessionArgs = {
  startId?: InputMaybe<Scalars['String']>;
};


export type QueryGetAnamnesisArgs = {
  sessionId?: InputMaybe<Scalars['String']>;
};


export type QueryGetConversationTreeArgs = {
  treeId: Scalars['String'];
};


export type QueryGetConversationTreeDataArgs = {
  treeDataId: Scalars['String'];
};


export type QueryGetDicomPatientArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetIllnessArgs = {
  illnessId: Scalars['String'];
};


export type QueryGetImageAnnotationsArgs = {
  instanceId: Scalars['String'];
};


export type QueryGetImageOperandsArgs = {
  seriesId: Scalars['String'];
};


export type QueryGetOrderArgs = {
  orderId: Scalars['String'];
  reviewerId?: InputMaybe<Scalars['String']>;
};


export type QueryGetOrderInvoiceArgs = {
  orderId: Scalars['String'];
};


export type QueryGetOrderPaymentIntentArgs = {
  clientSecret: Scalars['String'];
  orderId: Scalars['String'];
};


export type QueryGetOrdersArgs = {
  invoiceState?: InputMaybe<Array<InvoiceState>>;
  reviewerId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductSetForSessionArgs = {
  sessionId: Scalars['String'];
};


export type QueryGetReportArgs = {
  reportId?: InputMaybe<Scalars['String']>;
  secret?: InputMaybe<Scalars['String']>;
};


export type QueryGetReportPreviewArgs = {
  sessionId?: InputMaybe<Scalars['String']>;
};


export type QueryGetReportsArgs = {
  invoiceState?: InputMaybe<Array<InvoiceState>>;
  reviewerId?: InputMaybe<Scalars['String']>;
  userFirstname?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userLastname?: InputMaybe<Scalars['String']>;
};


export type QueryGetSessionArgs = {
  sessionId?: InputMaybe<Scalars['String']>;
};


export type QueryGetSessionsArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserArgs = {
  userId: Scalars['String'];
};


export type QueryGetUserFromPasswordRefreshTokenArgs = {
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type RegisterResponse = {
  __typename?: 'RegisterResponse';
  accessToken?: Maybe<Scalars['String']>;
  authError?: Maybe<AuthError>;
  profileErrors?: Maybe<Array<ProfileError>>;
  user?: Maybe<UserObject>;
};

export type ReleaseReportResponse = {
  __typename?: 'ReleaseReportResponse';
  error?: Maybe<ReportError>;
  success: Scalars['Boolean'];
};

/** Types of errors occuring during report generation. */
export enum ReportError {
  DifferentPatient = 'DIFFERENT_PATIENT',
  EmptyReport = 'EMPTY_REPORT',
  EmptySession = 'EMPTY_SESSION',
  ExpiredSecret = 'EXPIRED_SECRET',
  FrozenReport = 'FROZEN_REPORT',
  IncompleteChat = 'INCOMPLETE_CHAT',
  InvalidImage = 'INVALID_IMAGE',
  InvalidSession = 'INVALID_SESSION',
  NotAllowed = 'NOT_ALLOWED',
  NoIllness = 'NO_ILLNESS',
  ReleasedReport = 'RELEASED_REPORT',
  UnfrozenReport = 'UNFROZEN_REPORT',
  UnknownQuestion = 'UNKNOWN_QUESTION',
  UnknownReport = 'UNKNOWN_REPORT',
  UnknownSession = 'UNKNOWN_SESSION'
}

export type ReportObject = {
  __typename?: 'ReportObject';
  conversationSession?: Maybe<ConversationSessionObject>;
  createdTimestamp: Scalars['DateTime'];
  evaluationQuestion?: Maybe<EvaluationQuestion>;
  frozen: Scalars['Boolean'];
  id: Scalars['String'];
  illness: IllnessObject;
  languageCode: LanguageCode;
  linkedSeriesIds: Array<Scalars['String']>;
  orders: Array<OrderObject>;
  releaseTimestamp?: Maybe<Scalars['DateTime']>;
  released: Scalars['Boolean'];
  reportType: ReportType;
  requestTimestamp?: Maybe<Scalars['DateTime']>;
  reviewer?: Maybe<UserObject>;
  shareExpirationTimestamp?: Maybe<Scalars['DateTime']>;
  shareSecret?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  user: UserObject;
};

export type ReportSecretResponse = {
  __typename?: 'ReportSecretResponse';
  expirationTimestamp?: Maybe<Scalars['DateTime']>;
  reportId: Scalars['String'];
  secret?: Maybe<Scalars['String']>;
};

/** Types of different reports. */
export enum ReportType {
  AnamnesisReport = 'ANAMNESIS_REPORT',
  EvaluationReport = 'EVALUATION_REPORT',
  MedicalReport = 'MEDICAL_REPORT'
}

export type RequestReportResponse = {
  __typename?: 'RequestReportResponse';
  error?: Maybe<ReportError>;
  success: Scalars['Boolean'];
};

export enum ResearchCenter {
  Lmu = 'LMU',
  Ocm = 'OCM',
  Weilheim = 'WEILHEIM'
}

export enum ResearchGroup {
  Avatar = 'AVATAR',
  Chat = 'CHAT'
}

export enum ResearchName {
  AnamnesisKnee_2023 = 'ANAMNESIS_KNEE_2023'
}

export type ResearchUserResponse = {
  __typename?: 'ResearchUserResponse';
  error?: Maybe<AuthError>;
  user?: Maybe<UserObject>;
};

export type StripeObject = {
  __typename?: 'StripeObject';
  clientSecret: Scalars['String'];
  paymentIntent: Scalars['String'];
};

export type SubmitConversationResponse = {
  __typename?: 'SubmitConversationResponse';
  error?: Maybe<ConversationError>;
  success: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  getActiveSession: GetActiveSessionResponse;
  getAdminDashboardInfo: Array<AdminDashboardInfo>;
  getAnamnesis: GetReportTextResponse;
  getConversationTree: GetConversationTreeResponse;
  getConversationTreeData: GetConversationTreeDataResponse;
  getConversationTrees: GetConversationTreesResponse;
  getCurrentUser?: Maybe<UserObject>;
  getDicomPatient: GetDicomPatientResponse;
  getIllness: GetIllnessResponse;
  getIllnesses: GetIllnessesResponse;
  getImageAnnotations: GetImageAnnotationsResponse;
  getImageOperands: GetImageOperandsResponse;
  getOrder: GetOrderResponse;
  getOrders: GetOrdersResponse;
  getProductSetForSession: GetProductSetForSessionResponse;
  getProductSets: GetProductSetsResponse;
  getRegisteredUsers: Array<UserObject>;
  getReport: GetReportResponse;
  getReportPreview: GetReportTextResponse;
  getReports: GetReportsResponse;
  getReportsQueue: GetReportsResponse;
  getSession: GetSessionResponse;
  getSessions: GetSessionsResponse;
  getUser?: Maybe<UserObject>;
};


export type SubscriptionGetActiveSessionArgs = {
  startId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionGetAnamnesisArgs = {
  sessionId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionGetConversationTreeArgs = {
  treeId: Scalars['String'];
};


export type SubscriptionGetConversationTreeDataArgs = {
  treeDataId: Scalars['String'];
};


export type SubscriptionGetDicomPatientArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionGetIllnessArgs = {
  illnessId: Scalars['String'];
};


export type SubscriptionGetImageAnnotationsArgs = {
  instanceId: Scalars['String'];
};


export type SubscriptionGetImageOperandsArgs = {
  seriesId: Scalars['String'];
};


export type SubscriptionGetOrderArgs = {
  orderId: Scalars['String'];
  reviewerId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionGetOrdersArgs = {
  invoiceState?: InputMaybe<Array<InvoiceState>>;
  reviewerId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionGetProductSetForSessionArgs = {
  sessionId: Scalars['String'];
};


export type SubscriptionGetReportArgs = {
  reportId?: InputMaybe<Scalars['String']>;
  secret?: InputMaybe<Scalars['String']>;
};


export type SubscriptionGetReportPreviewArgs = {
  sessionId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionGetReportsArgs = {
  invoiceState?: InputMaybe<Array<InvoiceState>>;
  reviewerId?: InputMaybe<Scalars['String']>;
  userFirstname?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userLastname?: InputMaybe<Scalars['String']>;
};


export type SubscriptionGetSessionArgs = {
  sessionId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionGetSessionsArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionGetUserArgs = {
  userId: Scalars['String'];
};

export type TranslationInput = {
  languageCode: LanguageCode;
  text: Scalars['String'];
};

export type TranslationObject = {
  __typename?: 'TranslationObject';
  languageCode: LanguageCode;
  text: Scalars['String'];
};

export type TreeActionInput = {
  action: ActionType;
  errorNext?: InputMaybe<TreeNextInput>;
  id?: InputMaybe<Scalars['String']>;
  identifier: Scalars['String'];
  successNext?: InputMaybe<TreeNextInput>;
  text: Array<TranslationInput>;
  url?: InputMaybe<Scalars['String']>;
};

export type TreeActionObject = {
  __typename?: 'TreeActionObject';
  action: ActionType;
  errorNext?: Maybe<TreeNextObject>;
  id: Scalars['String'];
  identifier: Scalars['String'];
  successNext?: Maybe<TreeNextObject>;
  text: Array<TranslationObject>;
  url?: Maybe<Scalars['String']>;
};

export type TreeCheckInput = {
  falseNext?: InputMaybe<TreeNextInput>;
  id?: InputMaybe<Scalars['String']>;
  identifier: Scalars['String'];
  trueNext?: InputMaybe<TreeNextInput>;
  validator: Scalars['String'];
};

export type TreeCheckObject = {
  __typename?: 'TreeCheckObject';
  falseNext?: Maybe<TreeNextObject>;
  id: Scalars['String'];
  identifier: Scalars['String'];
  trueNext?: Maybe<TreeNextObject>;
  validator: Scalars['String'];
};

export type TreeCriterionInput = {
  id?: InputMaybe<Scalars['String']>;
  identifier: Scalars['String'];
  imageFinding?: InputMaybe<ImageFindingType>;
  validator: Scalars['String'];
  value: Scalars['String'];
};

export type TreeCriterionObject = {
  __typename?: 'TreeCriterionObject';
  id: Scalars['String'];
  identifier: Scalars['String'];
  imageFinding?: Maybe<ImageFindingType>;
  validator: Scalars['String'];
  value: Scalars['String'];
};

export type TreeFreeInput = {
  criterionId?: InputMaybe<Scalars['String']>;
  type: FreeType;
};

export type TreeFreeObject = {
  __typename?: 'TreeFreeObject';
  criterionId?: Maybe<Scalars['String']>;
  type: FreeType;
};

export type TreeMultiOptionContainerInput = {
  id?: InputMaybe<Scalars['String']>;
  next?: InputMaybe<TreeNextInput>;
  options: Array<TreeMultiOptionInput>;
};

export type TreeMultiOptionContainerObject = {
  __typename?: 'TreeMultiOptionContainerObject';
  id: Scalars['String'];
  next?: Maybe<TreeNextObject>;
  options: Array<TreeMultiOptionObject>;
};

export type TreeMultiOptionInput = {
  criterionId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Float']>;
  text: Array<TranslationInput>;
};

export type TreeMultiOptionObject = {
  __typename?: 'TreeMultiOptionObject';
  criterionId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  score?: Maybe<Scalars['Float']>;
  text: Array<TranslationObject>;
};

export type TreeNextInput = {
  nextId: Scalars['String'];
  type: NextObjectType;
};

export type TreeNextObject = {
  __typename?: 'TreeNextObject';
  nextId: Scalars['String'];
  type: NextObjectType;
};

export type TreeReportTemplatesInput = {
  anamnesisReport: Array<TranslationInput>;
  medicalReport: Array<TranslationInput>;
};

export type TreeReportTemplatesObject = {
  __typename?: 'TreeReportTemplatesObject';
  anamnesisReport: Array<TranslationObject>;
  medicalReport: Array<TranslationObject>;
};

export type TreeSettingsInput = {
  startId?: InputMaybe<Scalars['String']>;
};

export type TreeSettingsObject = {
  __typename?: 'TreeSettingsObject';
  startId?: Maybe<Scalars['String']>;
};

export type TreeSingleOptionInput = {
  criterionId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  next?: InputMaybe<TreeNextInput>;
  score?: InputMaybe<Scalars['Float']>;
  text: Array<TranslationInput>;
};

export type TreeSingleOptionObject = {
  __typename?: 'TreeSingleOptionObject';
  criterionId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  next?: Maybe<TreeNextObject>;
  score?: Maybe<Scalars['Float']>;
  text: Array<TranslationObject>;
};

export type TreeStatementInput = {
  freeInput?: InputMaybe<TreeFreeInput>;
  id?: InputMaybe<Scalars['String']>;
  identifier: Scalars['String'];
  inputType: ConversationTreeStatementInputType;
  multiOptions?: InputMaybe<Array<TreeMultiOptionContainerInput>>;
  next?: InputMaybe<TreeNextInput>;
  singleOptions?: InputMaybe<Array<TreeSingleOptionInput>>;
  text: Array<TranslationInput>;
};

export type TreeStatementObject = {
  __typename?: 'TreeStatementObject';
  freeInput?: Maybe<TreeFreeObject>;
  id: Scalars['String'];
  identifier: Scalars['String'];
  inputType: ConversationTreeStatementInputType;
  multiOptions?: Maybe<Array<TreeMultiOptionContainerObject>>;
  next?: Maybe<TreeNextObject>;
  singleOptions?: Maybe<Array<TreeSingleOptionObject>>;
  text: Array<TranslationObject>;
};

export type UpdatePasswordResponse = {
  __typename?: 'UpdatePasswordResponse';
  authError?: Maybe<AuthError>;
  profileErrors?: Maybe<Array<ProfileError>>;
  success: Scalars['Boolean'];
};

export type UpsertProductInput = {
  locales: Array<ProductTranslationInput>;
  metadata: ProductMetadata;
  productId: Scalars['String'];
  productSetId: Scalars['String'];
};

export type UpsertProductPrice = {
  currency: Scalars['String'];
  subtotal: Scalars['Float'];
};

export type UpsertProductPriceInput = {
  prices: Array<UpsertProductPrice>;
  productId: Scalars['String'];
};

export type UpsertProductPriceResponse = {
  __typename?: 'UpsertProductPriceResponse';
  error?: Maybe<PaymentError>;
  success: Scalars['Boolean'];
};

export type UpsertProductResponse = {
  __typename?: 'UpsertProductResponse';
  error?: Maybe<PaymentError>;
  success: Scalars['Boolean'];
};

export type UpsertProductSetResponse = {
  __typename?: 'UpsertProductSetResponse';
  error?: Maybe<PaymentError>;
  success: Scalars['Boolean'];
};

export type UserInput = {
  address?: InputMaybe<AddressInput>;
  birthdate: Scalars['String'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isActivated: Scalars['Boolean'];
  lastname: Scalars['String'];
  phone: Scalars['String'];
  role: UserRole;
  sex: ProfileSex;
  title?: InputMaybe<Scalars['String']>;
};

export type UserObject = {
  __typename?: 'UserObject';
  address?: Maybe<AddressObject>;
  birthdate?: Maybe<Scalars['String']>;
  conversationSessions?: Maybe<Array<ConversationSessionObject>>;
  creationTimestamp: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  emailVerificationTimestamp?: Maybe<Scalars['DateTime']>;
  firstname?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActivated: Scalars['Boolean'];
  isComplete: Scalars['Boolean'];
  isEmailVerified: Scalars['Boolean'];
  isPhoneVerified: Scalars['Boolean'];
  isRegistered: Scalars['Boolean'];
  isResearch: Scalars['Boolean'];
  lastActiveTimestamp: Scalars['DateTime'];
  lastname?: Maybe<Scalars['String']>;
  loginLinkExpirationTimestamp?: Maybe<Scalars['DateTime']>;
  pendingEmail?: Maybe<Scalars['String']>;
  pendingPhone?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerificationTimestamp?: Maybe<Scalars['DateTime']>;
  registrationTimestamp?: Maybe<Scalars['DateTime']>;
  researchCenter?: Maybe<ResearchCenter>;
  researchGroup?: Maybe<ResearchGroup>;
  researchName?: Maybe<ResearchName>;
  researchParticipantId?: Maybe<Scalars['String']>;
  rights: UserRights;
  role: UserRole;
  sex?: Maybe<ProfileSex>;
  title?: Maybe<Scalars['String']>;
};

export type UserRights = {
  __typename?: 'UserRights';
  adminApplications: Array<AdminApplication>;
  webApplications: Array<WebApplication>;
};

/** Different user roles in the DocRobin ecosystem. */
export enum UserRole {
  Admin = 'ADMIN',
  Author = 'AUTHOR',
  Doctor = 'DOCTOR',
  User = 'USER'
}

export enum WebApplication {
  Chat = 'CHAT',
  Dialog = 'DIALOG',
  Files = 'FILES',
  Order = 'ORDER',
  Profile = 'PROFILE',
  Reports = 'REPORTS'
}

export type LoginUserMutationVariables = Exact<{
  factor: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
  transferSession?: InputMaybe<Scalars['Boolean']>;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser: { __typename?: 'LoginResponse', accessToken?: string | null, error?: AuthError | null, user?: { __typename?: 'UserObject', id: string, role: UserRole } | null } };

export type LoginUserWithTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type LoginUserWithTokenMutation = { __typename?: 'Mutation', loginUserWithToken: { __typename?: 'LoginResponse', accessToken?: string | null, error?: AuthError | null, user?: { __typename?: 'UserObject', id: string, role: UserRole } | null } };

export type LogoutUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutUserMutation = { __typename?: 'Mutation', logoutUser: boolean };

export type ReauthenticateSocketMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type ReauthenticateSocketMutation = { __typename?: 'Mutation', reauthenticateSocket: boolean };

export type GetAccessRefreshTokenQueryVariables = Exact<{
  create?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetAccessRefreshTokenQuery = { __typename?: 'Query', getAccessRefreshToken: string };

export type DicomPatientFragment = { __typename?: 'DicomPatientObject', id: string, userId: string, studies: Array<{ __typename?: 'DicomStudyObject', id: string, uid: string, description?: string | null, date?: any | null, institution?: string | null, series: Array<{ __typename?: 'DicomSeriesObject', id: string, uid: string, description?: string | null, date?: any | null, modality?: string | null, deletable: boolean, instances: Array<{ __typename?: 'DicomInstanceObject', id: string, uid: string, number?: number | null, seriesIndex?: number | null }> }> }> };

export type UploadDicomFilesMutationVariables = Exact<{
  files: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type UploadDicomFilesMutation = { __typename?: 'Mutation', uploadDicomFiles: { __typename?: 'GetDicomPatientResponse', error?: DicomPatientError | null } };

export type GetDicomPatientQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type GetDicomPatientQuery = { __typename?: 'Query', getDicomPatient: { __typename?: 'GetDicomPatientResponse', error?: DicomPatientError | null, dicomPatient?: { __typename?: 'DicomPatientObject', id: string, userId: string, studies: Array<{ __typename?: 'DicomStudyObject', id: string, uid: string, description?: string | null, date?: any | null, institution?: string | null, series: Array<{ __typename?: 'DicomSeriesObject', id: string, uid: string, description?: string | null, date?: any | null, modality?: string | null, deletable: boolean, instances: Array<{ __typename?: 'DicomInstanceObject', id: string, uid: string, number?: number | null, seriesIndex?: number | null }> }> }> } | null } };

export type GetDicomPatientUpdateSubscriptionVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type GetDicomPatientUpdateSubscription = { __typename?: 'Subscription', getDicomPatient: { __typename?: 'GetDicomPatientResponse', error?: DicomPatientError | null, dicomPatient?: { __typename?: 'DicomPatientObject', id: string, userId: string, studies: Array<{ __typename?: 'DicomStudyObject', id: string, uid: string, description?: string | null, date?: any | null, institution?: string | null, series: Array<{ __typename?: 'DicomSeriesObject', id: string, uid: string, description?: string | null, date?: any | null, modality?: string | null, deletable: boolean, instances: Array<{ __typename?: 'DicomInstanceObject', id: string, uid: string, number?: number | null, seriesIndex?: number | null }> }> }> } | null } };

export type DeleteDicomSeriesMutationVariables = Exact<{
  seriesId: Scalars['String'];
}>;


export type DeleteDicomSeriesMutation = { __typename?: 'Mutation', deleteDicomSeries: { __typename?: 'GetDicomPatientResponse', error?: DicomPatientError | null } };

export type PaymentFragment = { __typename?: 'PaymentObject', id: string, currency: Currency, total: number, stripe: { __typename?: 'StripeObject', paymentIntent: string, clientSecret: string } };

export type OrderFragment = { __typename?: 'OrderObject', id: string, createdAt: any, orderNumber: string, date: any, payments: Array<{ __typename?: 'PaymentObject', id: string, currency: Currency, total: number, stripe: { __typename?: 'StripeObject', paymentIntent: string, clientSecret: string } }>, summary: { __typename?: 'PayersSummaryObject', patient: { __typename?: 'OrderSummaryObject', state: InvoiceState } }, report: { __typename?: 'ReportObject', illness: { __typename?: 'IllnessObject', name: string } } };

export type CreateOrderMutationVariables = Exact<{
  sessionId: Scalars['String'];
  productSetId?: InputMaybe<Scalars['String']>;
  allowsDataProcessing?: InputMaybe<Scalars['Boolean']>;
  allowsFollowUp?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'CreateOrderResponse', error?: OrderError | null, order?: { __typename?: 'OrderObject', id: string } | null } };

export type CancelOrderReportMutationVariables = Exact<{
  reportId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
}>;


export type CancelOrderReportMutation = { __typename?: 'Mutation', cancelOrderReport: { __typename?: 'CancelOrderReportResponse', success: boolean, error?: OrderError | null } };

export type PostponePaymentMutationVariables = Exact<{
  orderId?: InputMaybe<Scalars['String']>;
}>;


export type PostponePaymentMutation = { __typename?: 'Mutation', postponePayment: { __typename?: 'CancelOrderReportResponse', error?: OrderError | null, success: boolean } };

export type GetOrderInvoiceQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type GetOrderInvoiceQuery = { __typename?: 'Query', getOrderInvoice: { __typename?: 'GetOrderInvoiceResponse', uri?: string | null, error?: OrderError | null } };

export type GetOrderQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type GetOrderQuery = { __typename?: 'Query', getOrder: { __typename?: 'GetOrderResponse', error?: OrderError | null, order?: { __typename?: 'OrderObject', id: string, createdAt: any, orderNumber: string, date: any, payments: Array<{ __typename?: 'PaymentObject', id: string, currency: Currency, total: number, stripe: { __typename?: 'StripeObject', paymentIntent: string, clientSecret: string } }>, summary: { __typename?: 'PayersSummaryObject', patient: { __typename?: 'OrderSummaryObject', state: InvoiceState } }, report: { __typename?: 'ReportObject', illness: { __typename?: 'IllnessObject', name: string } } } | null } };

export type GetOrderUpdatesSubscriptionVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type GetOrderUpdatesSubscription = { __typename?: 'Subscription', getOrder: { __typename?: 'GetOrderResponse', error?: OrderError | null, order?: { __typename?: 'OrderObject', id: string, createdAt: any, orderNumber: string, date: any, payments: Array<{ __typename?: 'PaymentObject', id: string, currency: Currency, total: number, stripe: { __typename?: 'StripeObject', paymentIntent: string, clientSecret: string } }>, summary: { __typename?: 'PayersSummaryObject', patient: { __typename?: 'OrderSummaryObject', state: InvoiceState } }, report: { __typename?: 'ReportObject', illness: { __typename?: 'IllnessObject', name: string } } } | null } };

export type GetOrderPaymentIntentQueryVariables = Exact<{
  clientSecret: Scalars['String'];
  orderId: Scalars['String'];
}>;


export type GetOrderPaymentIntentQuery = { __typename?: 'Query', getOrderPaymentIntent: { __typename?: 'GetOrderPaymentIntentResponse', error?: string | null, paymentIntent?: { __typename?: 'PaymentIntentObject', id: string, status: string } | null } };

export type ProductSetFragment = { __typename?: 'ProductsSetObject', id: string, reportType: ReportType, title: string, illness: { __typename?: 'IllnessObject', name: string }, patientProducts: Array<{ __typename?: 'ProductObject', id: string, title: string, price?: number | null, vat?: number | null, seller: InvoiceParty, currency?: Currency | null }> };

export type GetProductSetForSessionQueryVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type GetProductSetForSessionQuery = { __typename?: 'Query', getProductSetForSession: { __typename?: 'GetProductSetForSessionResponse', error?: PaymentError | null, session?: { __typename?: 'ConversationSessionObject', id: string, lastActiveTimestamp?: any | null, report?: { __typename?: 'ReportObject', id: string, orders: Array<{ __typename?: 'OrderObject', id: string }> } | null } | null, productsSet?: { __typename?: 'ProductsSetObject', id: string, reportType: ReportType, title: string, illness: { __typename?: 'IllnessObject', name: string }, patientProducts: Array<{ __typename?: 'ProductObject', id: string, title: string, price?: number | null, vat?: number | null, seller: InvoiceParty, currency?: Currency | null }> } | null } };

export type GetProductSetForSessionUpdatesSubscriptionVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type GetProductSetForSessionUpdatesSubscription = { __typename?: 'Subscription', getProductSetForSession: { __typename?: 'GetProductSetForSessionResponse', error?: PaymentError | null, session?: { __typename?: 'ConversationSessionObject', id: string, lastActiveTimestamp?: any | null, report?: { __typename?: 'ReportObject', id: string, orders: Array<{ __typename?: 'OrderObject', id: string }> } | null } | null, productsSet?: { __typename?: 'ProductsSetObject', id: string, reportType: ReportType, title: string, illness: { __typename?: 'IllnessObject', name: string }, patientProducts: Array<{ __typename?: 'ProductObject', id: string, title: string, price?: number | null, vat?: number | null, seller: InvoiceParty, currency?: Currency | null }> } | null } };

export type BaseReportFragment = { __typename?: 'ReportObject', id: string, languageCode: LanguageCode, reportType: ReportType, createdTimestamp: any, requestTimestamp?: any | null, releaseTimestamp?: any | null, released: boolean, illness: { __typename?: 'IllnessObject', id: string, name: string }, reviewer?: { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null } | null, conversationSession?: { __typename?: 'ConversationSessionObject', id: string } | null, orders: Array<{ __typename?: 'OrderObject', id: string, createdAt: any, summary: { __typename?: 'PayersSummaryObject', patient: { __typename?: 'OrderSummaryObject', state: InvoiceState } }, patientInvoices: Array<{ __typename?: 'InvoiceObject', paidAt?: any | null, voidAt?: any | null }> }> };

export type ReportFragment = { __typename?: 'ReportObject', text?: string | null, shareSecret?: string | null, shareExpirationTimestamp?: any | null, id: string, languageCode: LanguageCode, reportType: ReportType, createdTimestamp: any, requestTimestamp?: any | null, releaseTimestamp?: any | null, released: boolean, user: { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null, email?: string | null, phone?: string | null, birthdate?: string | null }, conversationSession?: { __typename?: 'ConversationSessionObject', id: string, messages: Array<{ __typename?: 'ConversationMessageObject', id: string, botText?: string | null, creationTimestamp: any, infoText?: string | null, treeElementId: string, userText?: string | null }> } | null, illness: { __typename?: 'IllnessObject', id: string, name: string }, reviewer?: { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null } | null, orders: Array<{ __typename?: 'OrderObject', id: string, createdAt: any, summary: { __typename?: 'PayersSummaryObject', patient: { __typename?: 'OrderSummaryObject', state: InvoiceState } }, patientInvoices: Array<{ __typename?: 'InvoiceObject', paidAt?: any | null, voidAt?: any | null }> }> };

export type ShareReportFragment = { __typename?: 'ReportObject', id: string, languageCode: LanguageCode, reportType: ReportType, createdTimestamp: any, requestTimestamp?: any | null, releaseTimestamp?: any | null, released: boolean, text?: string | null, shareSecret?: string | null, shareExpirationTimestamp?: any | null, illness: { __typename?: 'IllnessObject', id: string, name: string }, reviewer?: { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null } | null, user: { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null } };

export type ReportUserFragment = { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null, email?: string | null, phone?: string | null, birthdate?: string | null };

export type ReportReviewerFragment = { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null };

export type GetReportsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type GetReportsQuery = { __typename?: 'Query', getReports: { __typename?: 'GetReportsResponse', error?: ReportError | null, reports?: Array<{ __typename?: 'ReportObject', id: string, languageCode: LanguageCode, reportType: ReportType, createdTimestamp: any, requestTimestamp?: any | null, releaseTimestamp?: any | null, released: boolean, illness: { __typename?: 'IllnessObject', id: string, name: string }, reviewer?: { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null } | null, conversationSession?: { __typename?: 'ConversationSessionObject', id: string } | null, orders: Array<{ __typename?: 'OrderObject', id: string, createdAt: any, summary: { __typename?: 'PayersSummaryObject', patient: { __typename?: 'OrderSummaryObject', state: InvoiceState } }, patientInvoices: Array<{ __typename?: 'InvoiceObject', paidAt?: any | null, voidAt?: any | null }> }> }> | null } };

export type GetReportsUpdatesSubscriptionVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type GetReportsUpdatesSubscription = { __typename?: 'Subscription', getReports: { __typename?: 'GetReportsResponse', error?: ReportError | null, reports?: Array<{ __typename?: 'ReportObject', id: string, languageCode: LanguageCode, reportType: ReportType, createdTimestamp: any, requestTimestamp?: any | null, releaseTimestamp?: any | null, released: boolean, illness: { __typename?: 'IllnessObject', id: string, name: string }, reviewer?: { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null } | null, conversationSession?: { __typename?: 'ConversationSessionObject', id: string } | null, orders: Array<{ __typename?: 'OrderObject', id: string, createdAt: any, summary: { __typename?: 'PayersSummaryObject', patient: { __typename?: 'OrderSummaryObject', state: InvoiceState } }, patientInvoices: Array<{ __typename?: 'InvoiceObject', paidAt?: any | null, voidAt?: any | null }> }> }> | null } };

export type GetReportQueryVariables = Exact<{
  reportId: Scalars['String'];
}>;


export type GetReportQuery = { __typename?: 'Query', getReport: { __typename?: 'GetReportResponse', error?: ReportError | null, report?: { __typename?: 'ReportObject', text?: string | null, shareSecret?: string | null, shareExpirationTimestamp?: any | null, id: string, languageCode: LanguageCode, reportType: ReportType, createdTimestamp: any, requestTimestamp?: any | null, releaseTimestamp?: any | null, released: boolean, user: { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null, email?: string | null, phone?: string | null, birthdate?: string | null }, conversationSession?: { __typename?: 'ConversationSessionObject', id: string, messages: Array<{ __typename?: 'ConversationMessageObject', id: string, botText?: string | null, creationTimestamp: any, infoText?: string | null, treeElementId: string, userText?: string | null }> } | null, illness: { __typename?: 'IllnessObject', id: string, name: string }, reviewer?: { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null } | null, orders: Array<{ __typename?: 'OrderObject', id: string, createdAt: any, summary: { __typename?: 'PayersSummaryObject', patient: { __typename?: 'OrderSummaryObject', state: InvoiceState } }, patientInvoices: Array<{ __typename?: 'InvoiceObject', paidAt?: any | null, voidAt?: any | null }> }> } | null } };

export type GetReportUpdatesSubscriptionVariables = Exact<{
  reportId: Scalars['String'];
}>;


export type GetReportUpdatesSubscription = { __typename?: 'Subscription', getReport: { __typename?: 'GetReportResponse', error?: ReportError | null, report?: { __typename?: 'ReportObject', text?: string | null, shareSecret?: string | null, shareExpirationTimestamp?: any | null, id: string, languageCode: LanguageCode, reportType: ReportType, createdTimestamp: any, requestTimestamp?: any | null, releaseTimestamp?: any | null, released: boolean, user: { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null, email?: string | null, phone?: string | null, birthdate?: string | null }, conversationSession?: { __typename?: 'ConversationSessionObject', id: string, messages: Array<{ __typename?: 'ConversationMessageObject', id: string, botText?: string | null, creationTimestamp: any, infoText?: string | null, treeElementId: string, userText?: string | null }> } | null, illness: { __typename?: 'IllnessObject', id: string, name: string }, reviewer?: { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null } | null, orders: Array<{ __typename?: 'OrderObject', id: string, createdAt: any, summary: { __typename?: 'PayersSummaryObject', patient: { __typename?: 'OrderSummaryObject', state: InvoiceState } }, patientInvoices: Array<{ __typename?: 'InvoiceObject', paidAt?: any | null, voidAt?: any | null }> }> } | null } };

export type GetReportBySecretQueryVariables = Exact<{
  secret: Scalars['String'];
}>;


export type GetReportBySecretQuery = { __typename?: 'Query', getReport: { __typename?: 'GetReportResponse', error?: ReportError | null, report?: { __typename?: 'ReportObject', id: string, languageCode: LanguageCode, reportType: ReportType, createdTimestamp: any, requestTimestamp?: any | null, releaseTimestamp?: any | null, released: boolean, text?: string | null, shareSecret?: string | null, shareExpirationTimestamp?: any | null, illness: { __typename?: 'IllnessObject', id: string, name: string }, reviewer?: { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null } | null, user: { __typename?: 'UserObject', id: string, title?: string | null, firstname?: string | null, lastname?: string | null } } | null } };

export type RequestEvaluationReportMutationVariables = Exact<{
  question: EvaluationQuestion;
  dicomSeriesIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type RequestEvaluationReportMutation = { __typename?: 'Mutation', requestEvaluationReport: { __typename?: 'RequestReportResponse', success: boolean, error?: ReportError | null } };

export type GetAnamnesisQueryVariables = Exact<{
  sessionId?: InputMaybe<Scalars['String']>;
}>;


export type GetAnamnesisQuery = { __typename?: 'Query', getAnamnesis: { __typename?: 'GetReportTextResponse', error?: ReportError | null, text?: string | null } };

export type GetAnamnesisUpdatesSubscriptionVariables = Exact<{
  sessionId?: InputMaybe<Scalars['String']>;
}>;


export type GetAnamnesisUpdatesSubscription = { __typename?: 'Subscription', getAnamnesis: { __typename?: 'GetReportTextResponse', error?: ReportError | null, text?: string | null } };

export type GetReportPreviewQueryVariables = Exact<{
  sessionId?: InputMaybe<Scalars['String']>;
}>;


export type GetReportPreviewQuery = { __typename?: 'Query', getReportPreview: { __typename?: 'GetReportTextResponse', error?: ReportError | null, text?: string | null } };

export type GetReportPreviewUpdatesSubscriptionVariables = Exact<{
  sessionId?: InputMaybe<Scalars['String']>;
}>;


export type GetReportPreviewUpdatesSubscription = { __typename?: 'Subscription', getReportPreview: { __typename?: 'GetReportTextResponse', error?: ReportError | null, text?: string | null } };

export type ShareReportMutationVariables = Exact<{
  reportId: Scalars['String'];
  stop?: InputMaybe<Scalars['Boolean']>;
}>;


export type ShareReportMutation = { __typename?: 'Mutation', shareReport: { __typename?: 'ReportSecretResponse', reportId: string, secret?: string | null, expirationTimestamp?: any | null } };

export type SessionFragment = { __typename?: 'ConversationSessionObject', id: string, isActive: number };

export type SessionChatFragment = { __typename?: 'ConversationSessionObject', id: string, isEndOfSession: number, messages: Array<{ __typename?: 'ConversationMessageObject', id: string, infoText?: string | null, botText?: string | null, userText?: string | null, creationTimestamp: any, treeElementId: string, treeElementIdentifier?: string | null }> };

export type ActiveSessionFragment = { __typename?: 'GetActiveSessionResponse', videoPath?: string | null, session?: { __typename?: 'ConversationSessionObject', id: string, isEndOfSession: number, messages: Array<{ __typename?: 'ConversationMessageObject', id: string, infoText?: string | null, botText?: string | null, userText?: string | null, creationTimestamp: any, treeElementId: string, treeElementIdentifier?: string | null }> } | null, action?: { __typename?: 'ActionObject', type: ActionType, url?: string | null } | null, error?: { __typename?: 'ActiveSessionErrorDetails', error?: ConversationError | null, orderId?: string | null } | null, input?: { __typename?: 'ActiveConversationInputObject', freeType?: FreeType | null, options?: Array<Array<{ __typename?: 'OptionObject', id: string, text: string }>> | null } | null };

export type ActiveResearchSessionFragment = { __typename?: 'GetActiveSessionResponse', videoPath?: string | null, session?: { __typename?: 'ConversationSessionObject', progress: number, id: string, isEndOfSession: number, messages: Array<{ __typename?: 'ConversationMessageObject', id: string, infoText?: string | null, botText?: string | null, userText?: string | null, creationTimestamp: any, treeElementId: string, treeElementIdentifier?: string | null }> } | null, action?: { __typename?: 'ActionObject', type: ActionType, url?: string | null } | null, error?: { __typename?: 'ActiveSessionErrorDetails', error?: ConversationError | null, orderId?: string | null } | null, input?: { __typename?: 'ActiveConversationInputObject', freeType?: FreeType | null, options?: Array<Array<{ __typename?: 'OptionObject', id: string, text: string }>> | null } | null };

export type GetActiveResearchSessionQueryVariables = Exact<{
  startId?: InputMaybe<Scalars['String']>;
}>;


export type GetActiveResearchSessionQuery = { __typename?: 'Query', getActiveSession: { __typename?: 'GetActiveSessionResponse', videoPath?: string | null, session?: { __typename?: 'ConversationSessionObject', progress: number, id: string, isEndOfSession: number, messages: Array<{ __typename?: 'ConversationMessageObject', id: string, infoText?: string | null, botText?: string | null, userText?: string | null, creationTimestamp: any, treeElementId: string, treeElementIdentifier?: string | null }> } | null, action?: { __typename?: 'ActionObject', type: ActionType, url?: string | null } | null, error?: { __typename?: 'ActiveSessionErrorDetails', error?: ConversationError | null, orderId?: string | null } | null, input?: { __typename?: 'ActiveConversationInputObject', freeType?: FreeType | null, options?: Array<Array<{ __typename?: 'OptionObject', id: string, text: string }>> | null } | null } };

export type GetActiveResearchSessionUpdatesSubscriptionVariables = Exact<{
  startId?: InputMaybe<Scalars['String']>;
}>;


export type GetActiveResearchSessionUpdatesSubscription = { __typename?: 'Subscription', getActiveSession: { __typename?: 'GetActiveSessionResponse', videoPath?: string | null, session?: { __typename?: 'ConversationSessionObject', progress: number, id: string, isEndOfSession: number, messages: Array<{ __typename?: 'ConversationMessageObject', id: string, infoText?: string | null, botText?: string | null, userText?: string | null, creationTimestamp: any, treeElementId: string, treeElementIdentifier?: string | null }> } | null, action?: { __typename?: 'ActionObject', type: ActionType, url?: string | null } | null, error?: { __typename?: 'ActiveSessionErrorDetails', error?: ConversationError | null, orderId?: string | null } | null, input?: { __typename?: 'ActiveConversationInputObject', freeType?: FreeType | null, options?: Array<Array<{ __typename?: 'OptionObject', id: string, text: string }>> | null } | null } };

export type GetActiveSessionQueryVariables = Exact<{
  startId?: InputMaybe<Scalars['String']>;
}>;


export type GetActiveSessionQuery = { __typename?: 'Query', getActiveSession: { __typename?: 'GetActiveSessionResponse', videoPath?: string | null, session?: { __typename?: 'ConversationSessionObject', id: string, isEndOfSession: number, messages: Array<{ __typename?: 'ConversationMessageObject', id: string, infoText?: string | null, botText?: string | null, userText?: string | null, creationTimestamp: any, treeElementId: string, treeElementIdentifier?: string | null }> } | null, action?: { __typename?: 'ActionObject', type: ActionType, url?: string | null } | null, error?: { __typename?: 'ActiveSessionErrorDetails', error?: ConversationError | null, orderId?: string | null } | null, input?: { __typename?: 'ActiveConversationInputObject', freeType?: FreeType | null, options?: Array<Array<{ __typename?: 'OptionObject', id: string, text: string }>> | null } | null } };

export type GetActiveSessionUpdatesSubscriptionVariables = Exact<{
  startId?: InputMaybe<Scalars['String']>;
}>;


export type GetActiveSessionUpdatesSubscription = { __typename?: 'Subscription', getActiveSession: { __typename?: 'GetActiveSessionResponse', videoPath?: string | null, session?: { __typename?: 'ConversationSessionObject', id: string, isEndOfSession: number, messages: Array<{ __typename?: 'ConversationMessageObject', id: string, infoText?: string | null, botText?: string | null, userText?: string | null, creationTimestamp: any, treeElementId: string, treeElementIdentifier?: string | null }> } | null, action?: { __typename?: 'ActionObject', type: ActionType, url?: string | null } | null, error?: { __typename?: 'ActiveSessionErrorDetails', error?: ConversationError | null, orderId?: string | null } | null, input?: { __typename?: 'ActiveConversationInputObject', freeType?: FreeType | null, options?: Array<Array<{ __typename?: 'OptionObject', id: string, text: string }>> | null } | null } };

export type GetSessionQueryVariables = Exact<{
  sessionId?: InputMaybe<Scalars['String']>;
}>;


export type GetSessionQuery = { __typename?: 'Query', getSession: { __typename?: 'GetSessionResponse', error?: ConversationError | null } };

export type GetSessionUpdatesSubscriptionVariables = Exact<{
  sessionId?: InputMaybe<Scalars['String']>;
}>;


export type GetSessionUpdatesSubscription = { __typename?: 'Subscription', getSession: { __typename?: 'GetSessionResponse', error?: ConversationError | null } };

export type ContinueSessionMutationVariables = Exact<{
  responseOptionIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  responseText?: InputMaybe<Scalars['String']>;
  lastElementId?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['String'];
}>;


export type ContinueSessionMutation = { __typename?: 'Mutation', continueSession: { __typename?: 'SubmitConversationResponse', error?: ConversationError | null, success: boolean } };

export type RestartSessionMutationVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type RestartSessionMutation = { __typename?: 'Mutation', restartSession: { __typename?: 'DeleteMessageResponse', error?: ConversationError | null, success: boolean } };

export type DeleteLastUserResponseMutationVariables = Exact<{
  sessionId: Scalars['String'];
  lastElementId?: InputMaybe<Scalars['String']>;
}>;


export type DeleteLastUserResponseMutation = { __typename?: 'Mutation', deleteLastUserResponse: { __typename?: 'DeleteMessageResponse', error?: ConversationError | null, success: boolean } };

export type UnfreezeSessionMutationVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type UnfreezeSessionMutation = { __typename?: 'Mutation', unfreezeSession: { __typename?: 'GetSessionResponse', error?: ConversationError | null } };

export type GetSessionsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type GetSessionsQuery = { __typename?: 'Query', getSessions: { __typename?: 'GetSessionsResponse', error?: ConversationError | null, sessions?: Array<{ __typename?: 'ConversationSessionObject', id: string, isActive: number }> | null } };

export type GetSessionsUpdatesSubscriptionVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type GetSessionsUpdatesSubscription = { __typename?: 'Subscription', getSessions: { __typename?: 'GetSessionsResponse', error?: ConversationError | null, sessions?: Array<{ __typename?: 'ConversationSessionObject', id: string, isActive: number }> | null } };

export type AddressFragment = { __typename?: 'AddressObject', instruction?: string | null, street: string, city: string, zip: string, country: CountryCode };

export type UserFragment = { __typename?: 'UserObject', id: string, role: UserRole, isRegistered: boolean, isEmailVerified: boolean, isPhoneVerified: boolean, isResearch: boolean, isComplete: boolean, title?: string | null, firstname?: string | null, lastname?: string | null, birthdate?: string | null, email?: string | null, phone?: string | null, pendingEmail?: string | null, pendingPhone?: string | null, researchCenter?: ResearchCenter | null, researchGroup?: ResearchGroup | null, researchParticipantId?: string | null, sex?: ProfileSex | null, rights: { __typename?: 'UserRights', webApplications: Array<WebApplication>, adminApplications: Array<AdminApplication> }, address?: { __typename?: 'AddressObject', instruction?: string | null, street: string, city: string, zip: string, country: CountryCode } | null };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', getCurrentUser?: { __typename?: 'UserObject', id: string, role: UserRole, isRegistered: boolean, isEmailVerified: boolean, isPhoneVerified: boolean, isResearch: boolean, isComplete: boolean, title?: string | null, firstname?: string | null, lastname?: string | null, birthdate?: string | null, email?: string | null, phone?: string | null, pendingEmail?: string | null, pendingPhone?: string | null, researchCenter?: ResearchCenter | null, researchGroup?: ResearchGroup | null, researchParticipantId?: string | null, sex?: ProfileSex | null, rights: { __typename?: 'UserRights', webApplications: Array<WebApplication>, adminApplications: Array<AdminApplication> }, address?: { __typename?: 'AddressObject', instruction?: string | null, street: string, city: string, zip: string, country: CountryCode } | null } | null };

export type GetCurrentUserChangesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserChangesSubscription = { __typename?: 'Subscription', getCurrentUser?: { __typename?: 'UserObject', id: string, role: UserRole, isRegistered: boolean, isEmailVerified: boolean, isPhoneVerified: boolean, isResearch: boolean, isComplete: boolean, title?: string | null, firstname?: string | null, lastname?: string | null, birthdate?: string | null, email?: string | null, phone?: string | null, pendingEmail?: string | null, pendingPhone?: string | null, researchCenter?: ResearchCenter | null, researchGroup?: ResearchGroup | null, researchParticipantId?: string | null, sex?: ProfileSex | null, rights: { __typename?: 'UserRights', webApplications: Array<WebApplication>, adminApplications: Array<AdminApplication> }, address?: { __typename?: 'AddressObject', instruction?: string | null, street: string, city: string, zip: string, country: CountryCode } | null } | null };

export type RegisterUserAndSendEmailVerificationMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  next?: InputMaybe<Scalars['String']>;
}>;


export type RegisterUserAndSendEmailVerificationMutation = { __typename?: 'Mutation', registerUserAndSendEmailVerification: { __typename?: 'RegisterResponse', profileErrors?: Array<ProfileError> | null, authError?: AuthError | null, accessToken?: string | null, user?: { __typename?: 'UserObject', id: string } | null } };

export type RegisterUserDiscardMailMutationVariables = Exact<{ [key: string]: never; }>;


export type RegisterUserDiscardMailMutation = { __typename?: 'Mutation', registerUserDiscardMail: { __typename?: 'RegisterResponse', profileErrors?: Array<ProfileError> | null, authError?: AuthError | null, accessToken?: string | null, user?: { __typename?: 'UserObject', id: string } | null } };

export type RegisterUserDiscardPhoneMutationVariables = Exact<{ [key: string]: never; }>;


export type RegisterUserDiscardPhoneMutation = { __typename?: 'Mutation', registerUserDiscardPhone: { __typename?: 'RegisterResponse', profileErrors?: Array<ProfileError> | null, authError?: AuthError | null, accessToken?: string | null, user?: { __typename?: 'UserObject', id: string } | null } };

export type RegisterUserProfileAndSendPhoneVerificationMutationVariables = Exact<{
  phone: Scalars['String'];
  profile: ProfileInput;
}>;


export type RegisterUserProfileAndSendPhoneVerificationMutation = { __typename?: 'Mutation', registerUserProfileAndSendPhoneVerification: { __typename?: 'RegisterResponse', accessToken?: string | null, authError?: AuthError | null, profileErrors?: Array<ProfileError> | null, user?: { __typename?: 'UserObject', id: string } | null } };

export type VerifyUserPhoneMutationVariables = Exact<{
  smsToken: Scalars['String'];
}>;


export type VerifyUserPhoneMutation = { __typename?: 'Mutation', verifyUserPhone: { __typename?: 'ProfileVerificationResponse', accessToken?: string | null, error?: AuthError | null, user?: { __typename?: 'UserObject', id: string } | null } };

export type SendPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendPasswordResetMutation = { __typename?: 'Mutation', sendPasswordReset: boolean };

export type VerifyUserEmailMutationVariables = Exact<{
  token: Scalars['String'];
  userId: Scalars['String'];
}>;


export type VerifyUserEmailMutation = { __typename?: 'Mutation', verifyUserEmail: { __typename?: 'ProfileVerificationResponse', accessToken?: string | null, error?: AuthError | null } };

export type UpdatePasswordMutationVariables = Exact<{
  password: Scalars['String'];
  oldPassword?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword: { __typename?: 'UpdatePasswordResponse', profileErrors?: Array<ProfileError> | null, authError?: AuthError | null, success: boolean } };

export type UpdateEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type UpdateEmailMutation = { __typename?: 'Mutation', updateEmail: { __typename?: 'ProfileUserResponse', profileErrors?: Array<ProfileError> | null, authError?: AuthError | null, user?: { __typename?: 'UserObject', id: string, pendingEmail?: string | null } | null } };

export type UpdatePhoneMutationVariables = Exact<{
  phone: Scalars['String'];
}>;


export type UpdatePhoneMutation = { __typename?: 'Mutation', updatePhone: { __typename?: 'ProfileUserResponse', profileErrors?: Array<ProfileError> | null, authError?: AuthError | null, user?: { __typename?: 'UserObject', id: string, pendingPhone?: string | null } | null } };

export type UpdateProfileMutationVariables = Exact<{
  profile: ProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'ProfileUserResponse', profileErrors?: Array<ProfileError> | null, authError?: AuthError | null, user?: { __typename?: 'UserObject', id: string, role: UserRole, isRegistered: boolean, isEmailVerified: boolean, isPhoneVerified: boolean, isResearch: boolean, isComplete: boolean, title?: string | null, firstname?: string | null, lastname?: string | null, birthdate?: string | null, email?: string | null, phone?: string | null, pendingEmail?: string | null, pendingPhone?: string | null, researchCenter?: ResearchCenter | null, researchGroup?: ResearchGroup | null, researchParticipantId?: string | null, sex?: ProfileSex | null, rights: { __typename?: 'UserRights', webApplications: Array<WebApplication>, adminApplications: Array<AdminApplication> }, address?: { __typename?: 'AddressObject', instruction?: string | null, street: string, city: string, zip: string, country: CountryCode } | null } | null } };

export type GetUserFromPasswordRefreshTokenQueryVariables = Exact<{
  userId: Scalars['String'];
  token: Scalars['String'];
}>;


export type GetUserFromPasswordRefreshTokenQuery = { __typename?: 'Query', getUserFromPasswordRefreshToken: { __typename?: 'GetUserFromTokenResponse', id?: string | null, email?: string | null, error?: AuthError | null } };

export type SendSecondAuthFactorMutationVariables = Exact<{
  password: Scalars['String'];
  email: Scalars['String'];
}>;


export type SendSecondAuthFactorMutation = { __typename?: 'Mutation', sendSecondAuthFactor: { __typename?: 'AuthResponse', success: boolean, error?: AuthError | null } };

export const DicomPatientFragmentDoc = gql`
    fragment DicomPatient on DicomPatientObject {
  id
  userId
  studies {
    id
    uid
    description
    date
    institution
    series {
      id
      uid
      description
      date
      modality
      deletable
      instances {
        id
        uid
        number
        seriesIndex
      }
    }
  }
}
    `;
export const PaymentFragmentDoc = gql`
    fragment Payment on PaymentObject {
  id
  stripe {
    paymentIntent
    clientSecret
  }
  currency
  total
}
    `;
export const OrderFragmentDoc = gql`
    fragment Order on OrderObject {
  id
  createdAt
  payments {
    ...Payment
  }
  summary {
    patient {
      state
    }
  }
  orderNumber
  report {
    illness {
      name
    }
  }
  date
}
    ${PaymentFragmentDoc}`;
export const ProductSetFragmentDoc = gql`
    fragment ProductSet on ProductsSetObject {
  id
  illness {
    name
  }
  reportType
  title
  patientProducts {
    id
    title
    price
    vat
    seller
    currency
  }
}
    `;
export const ReportReviewerFragmentDoc = gql`
    fragment ReportReviewer on UserObject {
  id
  title
  firstname
  lastname
}
    `;
export const BaseReportFragmentDoc = gql`
    fragment BaseReport on ReportObject {
  id
  illness {
    id
    name
  }
  languageCode
  reportType
  createdTimestamp
  requestTimestamp
  releaseTimestamp
  released
  reviewer {
    ...ReportReviewer
  }
  conversationSession {
    id
  }
  orders {
    id
    createdAt
    summary {
      patient {
        state
      }
    }
    patientInvoices {
      paidAt
      voidAt
    }
  }
}
    ${ReportReviewerFragmentDoc}`;
export const ReportUserFragmentDoc = gql`
    fragment ReportUser on UserObject {
  id
  title
  firstname
  lastname
  email
  phone
  birthdate
}
    `;
export const ReportFragmentDoc = gql`
    fragment Report on ReportObject {
  ...BaseReport
  user {
    ...ReportUser
  }
  text
  shareSecret
  shareExpirationTimestamp
  conversationSession {
    messages {
      id
      botText
      creationTimestamp
      infoText
      treeElementId
      userText
    }
  }
}
    ${BaseReportFragmentDoc}
${ReportUserFragmentDoc}`;
export const ShareReportFragmentDoc = gql`
    fragment ShareReport on ReportObject {
  id
  illness {
    id
    name
  }
  languageCode
  reportType
  createdTimestamp
  requestTimestamp
  releaseTimestamp
  released
  reviewer {
    ...ReportReviewer
  }
  user {
    id
    title
    firstname
    lastname
  }
  text
  shareSecret
  shareExpirationTimestamp
}
    ${ReportReviewerFragmentDoc}`;
export const SessionFragmentDoc = gql`
    fragment Session on ConversationSessionObject {
  id
  isActive
}
    `;
export const SessionChatFragmentDoc = gql`
    fragment SessionChat on ConversationSessionObject {
  id
  messages {
    id
    infoText
    botText
    userText
    creationTimestamp
    treeElementId
    treeElementIdentifier
  }
  isEndOfSession
}
    `;
export const ActiveSessionFragmentDoc = gql`
    fragment ActiveSession on GetActiveSessionResponse {
  videoPath
  session {
    ...SessionChat
  }
  action {
    type
    url
  }
  error {
    error
    orderId
  }
  input {
    freeType
    options {
      id
      text
    }
  }
}
    ${SessionChatFragmentDoc}`;
export const ActiveResearchSessionFragmentDoc = gql`
    fragment ActiveResearchSession on GetActiveSessionResponse {
  videoPath
  session {
    ...SessionChat
    progress
  }
  action {
    type
    url
  }
  error {
    error
    orderId
  }
  input {
    freeType
    options {
      id
      text
    }
  }
}
    ${SessionChatFragmentDoc}`;
export const AddressFragmentDoc = gql`
    fragment Address on AddressObject {
  instruction
  street
  city
  zip
  country
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UserObject {
  id
  role
  rights {
    webApplications
    adminApplications
  }
  isRegistered
  isEmailVerified
  isPhoneVerified
  isResearch
  isComplete
  title
  firstname
  lastname
  birthdate
  email
  phone
  pendingEmail
  pendingPhone
  researchCenter
  researchGroup
  researchParticipantId
  sex
  address {
    ...Address
  }
}
    ${AddressFragmentDoc}`;
export const LoginUserDocument = gql`
    mutation LoginUser($factor: String!, $password: String!, $email: String!, $transferSession: Boolean) {
  loginUser(
    factor: $factor
    password: $password
    email: $email
    transferSession: $transferSession
  ) {
    accessToken
    user {
      id
      role
    }
    error
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const LoginUserWithTokenDocument = gql`
    mutation LoginUserWithToken($token: String!) {
  loginUserWithToken(token: $token) {
    accessToken
    user {
      id
      role
    }
    error
  }
}
    `;
export type LoginUserWithTokenMutationFn = Apollo.MutationFunction<LoginUserWithTokenMutation, LoginUserWithTokenMutationVariables>;
export type LoginUserWithTokenMutationResult = Apollo.MutationResult<LoginUserWithTokenMutation>;
export type LoginUserWithTokenMutationOptions = Apollo.BaseMutationOptions<LoginUserWithTokenMutation, LoginUserWithTokenMutationVariables>;
export const LogoutUserDocument = gql`
    mutation LogoutUser {
  logoutUser
}
    `;
export type LogoutUserMutationFn = Apollo.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<LogoutUserMutation, LogoutUserMutationVariables>;
export const ReauthenticateSocketDocument = gql`
    mutation ReauthenticateSocket($accessToken: String!) {
  reauthenticateSocket(accessToken: $accessToken)
}
    `;
export type ReauthenticateSocketMutationFn = Apollo.MutationFunction<ReauthenticateSocketMutation, ReauthenticateSocketMutationVariables>;
export type ReauthenticateSocketMutationResult = Apollo.MutationResult<ReauthenticateSocketMutation>;
export type ReauthenticateSocketMutationOptions = Apollo.BaseMutationOptions<ReauthenticateSocketMutation, ReauthenticateSocketMutationVariables>;
export const GetAccessRefreshTokenDocument = gql`
    query GetAccessRefreshToken($create: Boolean) {
  getAccessRefreshToken(create: $create)
}
    `;
export type GetAccessRefreshTokenQueryResult = Apollo.QueryResult<GetAccessRefreshTokenQuery, GetAccessRefreshTokenQueryVariables>;
export const UploadDicomFilesDocument = gql`
    mutation UploadDicomFiles($files: [Upload!]!) {
  uploadDicomFiles(files: $files) {
    error
  }
}
    `;
export type UploadDicomFilesMutationFn = Apollo.MutationFunction<UploadDicomFilesMutation, UploadDicomFilesMutationVariables>;
export type UploadDicomFilesMutationResult = Apollo.MutationResult<UploadDicomFilesMutation>;
export type UploadDicomFilesMutationOptions = Apollo.BaseMutationOptions<UploadDicomFilesMutation, UploadDicomFilesMutationVariables>;
export const GetDicomPatientDocument = gql`
    query GetDicomPatient($userId: String) {
  getDicomPatient(userId: $userId) {
    error
    dicomPatient {
      ...DicomPatient
    }
  }
}
    ${DicomPatientFragmentDoc}`;
export type GetDicomPatientQueryResult = Apollo.QueryResult<GetDicomPatientQuery, GetDicomPatientQueryVariables>;
export const GetDicomPatientUpdateDocument = gql`
    subscription GetDicomPatientUpdate($userId: String) {
  getDicomPatient(userId: $userId) {
    error
    dicomPatient {
      ...DicomPatient
    }
  }
}
    ${DicomPatientFragmentDoc}`;
export type GetDicomPatientUpdateSubscriptionResult = Apollo.SubscriptionResult<GetDicomPatientUpdateSubscription>;
export const DeleteDicomSeriesDocument = gql`
    mutation DeleteDicomSeries($seriesId: String!) {
  deleteDicomSeries(seriesId: $seriesId) {
    error
  }
}
    `;
export type DeleteDicomSeriesMutationFn = Apollo.MutationFunction<DeleteDicomSeriesMutation, DeleteDicomSeriesMutationVariables>;
export type DeleteDicomSeriesMutationResult = Apollo.MutationResult<DeleteDicomSeriesMutation>;
export type DeleteDicomSeriesMutationOptions = Apollo.BaseMutationOptions<DeleteDicomSeriesMutation, DeleteDicomSeriesMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($sessionId: String!, $productSetId: String, $allowsDataProcessing: Boolean, $allowsFollowUp: Boolean) {
  createOrder(
    sessionId: $sessionId
    productSetId: $productSetId
    allowsDataProcessing: $allowsDataProcessing
    allowsFollowUp: $allowsFollowUp
  ) {
    error
    order {
      id
    }
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const CancelOrderReportDocument = gql`
    mutation CancelOrderReport($reportId: String, $orderId: String) {
  cancelOrderReport(reportId: $reportId, orderId: $orderId) {
    success
    error
  }
}
    `;
export type CancelOrderReportMutationFn = Apollo.MutationFunction<CancelOrderReportMutation, CancelOrderReportMutationVariables>;
export type CancelOrderReportMutationResult = Apollo.MutationResult<CancelOrderReportMutation>;
export type CancelOrderReportMutationOptions = Apollo.BaseMutationOptions<CancelOrderReportMutation, CancelOrderReportMutationVariables>;
export const PostponePaymentDocument = gql`
    mutation PostponePayment($orderId: String) {
  postponePayment(orderId: $orderId) {
    error
    success
  }
}
    `;
export type PostponePaymentMutationFn = Apollo.MutationFunction<PostponePaymentMutation, PostponePaymentMutationVariables>;
export type PostponePaymentMutationResult = Apollo.MutationResult<PostponePaymentMutation>;
export type PostponePaymentMutationOptions = Apollo.BaseMutationOptions<PostponePaymentMutation, PostponePaymentMutationVariables>;
export const GetOrderInvoiceDocument = gql`
    query GetOrderInvoice($orderId: String!) {
  getOrderInvoice(orderId: $orderId) {
    uri
    error
  }
}
    `;
export type GetOrderInvoiceQueryResult = Apollo.QueryResult<GetOrderInvoiceQuery, GetOrderInvoiceQueryVariables>;
export const GetOrderDocument = gql`
    query GetOrder($orderId: String!) {
  getOrder(orderId: $orderId) {
    order {
      ...Order
    }
    error
  }
}
    ${OrderFragmentDoc}`;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetOrderUpdatesDocument = gql`
    subscription GetOrderUpdates($orderId: String!) {
  getOrder(orderId: $orderId) {
    order {
      ...Order
    }
    error
  }
}
    ${OrderFragmentDoc}`;
export type GetOrderUpdatesSubscriptionResult = Apollo.SubscriptionResult<GetOrderUpdatesSubscription>;
export const GetOrderPaymentIntentDocument = gql`
    query GetOrderPaymentIntent($clientSecret: String!, $orderId: String!) {
  getOrderPaymentIntent(clientSecret: $clientSecret, orderId: $orderId) {
    paymentIntent {
      id
      status
    }
    error
  }
}
    `;
export type GetOrderPaymentIntentQueryResult = Apollo.QueryResult<GetOrderPaymentIntentQuery, GetOrderPaymentIntentQueryVariables>;
export const GetProductSetForSessionDocument = gql`
    query GetProductSetForSession($sessionId: String!) {
  getProductSetForSession(sessionId: $sessionId) {
    session {
      id
      lastActiveTimestamp
      report {
        id
        orders {
          id
        }
      }
    }
    productsSet {
      ...ProductSet
    }
    error
  }
}
    ${ProductSetFragmentDoc}`;
export type GetProductSetForSessionQueryResult = Apollo.QueryResult<GetProductSetForSessionQuery, GetProductSetForSessionQueryVariables>;
export const GetProductSetForSessionUpdatesDocument = gql`
    subscription GetProductSetForSessionUpdates($sessionId: String!) {
  getProductSetForSession(sessionId: $sessionId) {
    session {
      id
      lastActiveTimestamp
      report {
        id
        orders {
          id
        }
      }
    }
    productsSet {
      ...ProductSet
    }
    error
  }
}
    ${ProductSetFragmentDoc}`;
export type GetProductSetForSessionUpdatesSubscriptionResult = Apollo.SubscriptionResult<GetProductSetForSessionUpdatesSubscription>;
export const GetReportsDocument = gql`
    query GetReports($userId: String) {
  getReports(userId: $userId) {
    error
    reports {
      ...BaseReport
    }
  }
}
    ${BaseReportFragmentDoc}`;
export type GetReportsQueryResult = Apollo.QueryResult<GetReportsQuery, GetReportsQueryVariables>;
export const GetReportsUpdatesDocument = gql`
    subscription GetReportsUpdates($userId: String) {
  getReports(userId: $userId) {
    error
    reports {
      ...BaseReport
    }
  }
}
    ${BaseReportFragmentDoc}`;
export type GetReportsUpdatesSubscriptionResult = Apollo.SubscriptionResult<GetReportsUpdatesSubscription>;
export const GetReportDocument = gql`
    query GetReport($reportId: String!) {
  getReport(reportId: $reportId) {
    error
    report {
      ...Report
    }
  }
}
    ${ReportFragmentDoc}`;
export type GetReportQueryResult = Apollo.QueryResult<GetReportQuery, GetReportQueryVariables>;
export const GetReportUpdatesDocument = gql`
    subscription GetReportUpdates($reportId: String!) {
  getReport(reportId: $reportId) {
    error
    report {
      ...Report
    }
  }
}
    ${ReportFragmentDoc}`;
export type GetReportUpdatesSubscriptionResult = Apollo.SubscriptionResult<GetReportUpdatesSubscription>;
export const GetReportBySecretDocument = gql`
    query GetReportBySecret($secret: String!) {
  getReport(secret: $secret) {
    error
    report {
      ...ShareReport
    }
  }
}
    ${ShareReportFragmentDoc}`;
export type GetReportBySecretQueryResult = Apollo.QueryResult<GetReportBySecretQuery, GetReportBySecretQueryVariables>;
export const RequestEvaluationReportDocument = gql`
    mutation RequestEvaluationReport($question: EvaluationQuestion!, $dicomSeriesIds: [String!]) {
  requestEvaluationReport(question: $question, dicomSeriesIds: $dicomSeriesIds) {
    success
    error
  }
}
    `;
export type RequestEvaluationReportMutationFn = Apollo.MutationFunction<RequestEvaluationReportMutation, RequestEvaluationReportMutationVariables>;
export type RequestEvaluationReportMutationResult = Apollo.MutationResult<RequestEvaluationReportMutation>;
export type RequestEvaluationReportMutationOptions = Apollo.BaseMutationOptions<RequestEvaluationReportMutation, RequestEvaluationReportMutationVariables>;
export const GetAnamnesisDocument = gql`
    query GetAnamnesis($sessionId: String) {
  getAnamnesis(sessionId: $sessionId) {
    error
    text
  }
}
    `;
export type GetAnamnesisQueryResult = Apollo.QueryResult<GetAnamnesisQuery, GetAnamnesisQueryVariables>;
export const GetAnamnesisUpdatesDocument = gql`
    subscription GetAnamnesisUpdates($sessionId: String) {
  getAnamnesis(sessionId: $sessionId) {
    error
    text
  }
}
    `;
export type GetAnamnesisUpdatesSubscriptionResult = Apollo.SubscriptionResult<GetAnamnesisUpdatesSubscription>;
export const GetReportPreviewDocument = gql`
    query GetReportPreview($sessionId: String) {
  getReportPreview(sessionId: $sessionId) {
    error
    text
  }
}
    `;
export type GetReportPreviewQueryResult = Apollo.QueryResult<GetReportPreviewQuery, GetReportPreviewQueryVariables>;
export const GetReportPreviewUpdatesDocument = gql`
    subscription GetReportPreviewUpdates($sessionId: String) {
  getReportPreview(sessionId: $sessionId) {
    error
    text
  }
}
    `;
export type GetReportPreviewUpdatesSubscriptionResult = Apollo.SubscriptionResult<GetReportPreviewUpdatesSubscription>;
export const ShareReportDocument = gql`
    mutation ShareReport($reportId: String!, $stop: Boolean) {
  shareReport(reportId: $reportId, stop: $stop) {
    reportId
    secret
    expirationTimestamp
  }
}
    `;
export type ShareReportMutationFn = Apollo.MutationFunction<ShareReportMutation, ShareReportMutationVariables>;
export type ShareReportMutationResult = Apollo.MutationResult<ShareReportMutation>;
export type ShareReportMutationOptions = Apollo.BaseMutationOptions<ShareReportMutation, ShareReportMutationVariables>;
export const GetActiveResearchSessionDocument = gql`
    query GetActiveResearchSession($startId: String) {
  getActiveSession(startId: $startId) {
    ...ActiveResearchSession
  }
}
    ${ActiveResearchSessionFragmentDoc}`;
export type GetActiveResearchSessionQueryResult = Apollo.QueryResult<GetActiveResearchSessionQuery, GetActiveResearchSessionQueryVariables>;
export const GetActiveResearchSessionUpdatesDocument = gql`
    subscription GetActiveResearchSessionUpdates($startId: String) {
  getActiveSession(startId: $startId) {
    ...ActiveResearchSession
  }
}
    ${ActiveResearchSessionFragmentDoc}`;
export type GetActiveResearchSessionUpdatesSubscriptionResult = Apollo.SubscriptionResult<GetActiveResearchSessionUpdatesSubscription>;
export const GetActiveSessionDocument = gql`
    query GetActiveSession($startId: String) {
  getActiveSession(startId: $startId) {
    ...ActiveSession
  }
}
    ${ActiveSessionFragmentDoc}`;
export type GetActiveSessionQueryResult = Apollo.QueryResult<GetActiveSessionQuery, GetActiveSessionQueryVariables>;
export const GetActiveSessionUpdatesDocument = gql`
    subscription GetActiveSessionUpdates($startId: String) {
  getActiveSession(startId: $startId) {
    ...ActiveSession
  }
}
    ${ActiveSessionFragmentDoc}`;
export type GetActiveSessionUpdatesSubscriptionResult = Apollo.SubscriptionResult<GetActiveSessionUpdatesSubscription>;
export const GetSessionDocument = gql`
    query GetSession($sessionId: String) {
  getSession(sessionId: $sessionId) {
    error
  }
}
    `;
export type GetSessionQueryResult = Apollo.QueryResult<GetSessionQuery, GetSessionQueryVariables>;
export const GetSessionUpdatesDocument = gql`
    subscription GetSessionUpdates($sessionId: String) {
  getSession(sessionId: $sessionId) {
    error
  }
}
    `;
export type GetSessionUpdatesSubscriptionResult = Apollo.SubscriptionResult<GetSessionUpdatesSubscription>;
export const ContinueSessionDocument = gql`
    mutation ContinueSession($responseOptionIds: [String!], $responseText: String, $lastElementId: String, $sessionId: String!) {
  continueSession(
    responseOptionIds: $responseOptionIds
    responseText: $responseText
    lastElementId: $lastElementId
    sessionId: $sessionId
  ) {
    error
    success
  }
}
    `;
export type ContinueSessionMutationFn = Apollo.MutationFunction<ContinueSessionMutation, ContinueSessionMutationVariables>;
export type ContinueSessionMutationResult = Apollo.MutationResult<ContinueSessionMutation>;
export type ContinueSessionMutationOptions = Apollo.BaseMutationOptions<ContinueSessionMutation, ContinueSessionMutationVariables>;
export const RestartSessionDocument = gql`
    mutation RestartSession($sessionId: String!) {
  restartSession(sessionId: $sessionId) {
    error
    success
  }
}
    `;
export type RestartSessionMutationFn = Apollo.MutationFunction<RestartSessionMutation, RestartSessionMutationVariables>;
export type RestartSessionMutationResult = Apollo.MutationResult<RestartSessionMutation>;
export type RestartSessionMutationOptions = Apollo.BaseMutationOptions<RestartSessionMutation, RestartSessionMutationVariables>;
export const DeleteLastUserResponseDocument = gql`
    mutation DeleteLastUserResponse($sessionId: String!, $lastElementId: String) {
  deleteLastUserResponse(sessionId: $sessionId, lastElementId: $lastElementId) {
    error
    success
  }
}
    `;
export type DeleteLastUserResponseMutationFn = Apollo.MutationFunction<DeleteLastUserResponseMutation, DeleteLastUserResponseMutationVariables>;
export type DeleteLastUserResponseMutationResult = Apollo.MutationResult<DeleteLastUserResponseMutation>;
export type DeleteLastUserResponseMutationOptions = Apollo.BaseMutationOptions<DeleteLastUserResponseMutation, DeleteLastUserResponseMutationVariables>;
export const UnfreezeSessionDocument = gql`
    mutation UnfreezeSession($sessionId: String!) {
  unfreezeSession(sessionId: $sessionId) {
    error
  }
}
    `;
export type UnfreezeSessionMutationFn = Apollo.MutationFunction<UnfreezeSessionMutation, UnfreezeSessionMutationVariables>;
export type UnfreezeSessionMutationResult = Apollo.MutationResult<UnfreezeSessionMutation>;
export type UnfreezeSessionMutationOptions = Apollo.BaseMutationOptions<UnfreezeSessionMutation, UnfreezeSessionMutationVariables>;
export const GetSessionsDocument = gql`
    query GetSessions($userId: String) {
  getSessions(userId: $userId) {
    error
    sessions {
      ...Session
    }
  }
}
    ${SessionFragmentDoc}`;
export type GetSessionsQueryResult = Apollo.QueryResult<GetSessionsQuery, GetSessionsQueryVariables>;
export const GetSessionsUpdatesDocument = gql`
    subscription GetSessionsUpdates($userId: String) {
  getSessions(userId: $userId) {
    error
    sessions {
      ...Session
    }
  }
}
    ${SessionFragmentDoc}`;
export type GetSessionsUpdatesSubscriptionResult = Apollo.SubscriptionResult<GetSessionsUpdatesSubscription>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  getCurrentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetCurrentUserChangesDocument = gql`
    subscription GetCurrentUserChanges {
  getCurrentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type GetCurrentUserChangesSubscriptionResult = Apollo.SubscriptionResult<GetCurrentUserChangesSubscription>;
export const RegisterUserAndSendEmailVerificationDocument = gql`
    mutation RegisterUserAndSendEmailVerification($email: String!, $password: String!, $next: String) {
  registerUserAndSendEmailVerification(
    email: $email
    password: $password
    next: $next
  ) {
    user {
      id
    }
    profileErrors
    authError
    accessToken
  }
}
    `;
export type RegisterUserAndSendEmailVerificationMutationFn = Apollo.MutationFunction<RegisterUserAndSendEmailVerificationMutation, RegisterUserAndSendEmailVerificationMutationVariables>;
export type RegisterUserAndSendEmailVerificationMutationResult = Apollo.MutationResult<RegisterUserAndSendEmailVerificationMutation>;
export type RegisterUserAndSendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<RegisterUserAndSendEmailVerificationMutation, RegisterUserAndSendEmailVerificationMutationVariables>;
export const RegisterUserDiscardMailDocument = gql`
    mutation RegisterUserDiscardMail {
  registerUserDiscardMail {
    user {
      id
    }
    profileErrors
    authError
    accessToken
  }
}
    `;
export type RegisterUserDiscardMailMutationFn = Apollo.MutationFunction<RegisterUserDiscardMailMutation, RegisterUserDiscardMailMutationVariables>;
export type RegisterUserDiscardMailMutationResult = Apollo.MutationResult<RegisterUserDiscardMailMutation>;
export type RegisterUserDiscardMailMutationOptions = Apollo.BaseMutationOptions<RegisterUserDiscardMailMutation, RegisterUserDiscardMailMutationVariables>;
export const RegisterUserDiscardPhoneDocument = gql`
    mutation RegisterUserDiscardPhone {
  registerUserDiscardPhone {
    user {
      id
    }
    profileErrors
    authError
    accessToken
  }
}
    `;
export type RegisterUserDiscardPhoneMutationFn = Apollo.MutationFunction<RegisterUserDiscardPhoneMutation, RegisterUserDiscardPhoneMutationVariables>;
export type RegisterUserDiscardPhoneMutationResult = Apollo.MutationResult<RegisterUserDiscardPhoneMutation>;
export type RegisterUserDiscardPhoneMutationOptions = Apollo.BaseMutationOptions<RegisterUserDiscardPhoneMutation, RegisterUserDiscardPhoneMutationVariables>;
export const RegisterUserProfileAndSendPhoneVerificationDocument = gql`
    mutation RegisterUserProfileAndSendPhoneVerification($phone: String!, $profile: ProfileInput!) {
  registerUserProfileAndSendPhoneVerification(phone: $phone, profile: $profile) {
    user {
      id
    }
    accessToken
    authError
    profileErrors
  }
}
    `;
export type RegisterUserProfileAndSendPhoneVerificationMutationFn = Apollo.MutationFunction<RegisterUserProfileAndSendPhoneVerificationMutation, RegisterUserProfileAndSendPhoneVerificationMutationVariables>;
export type RegisterUserProfileAndSendPhoneVerificationMutationResult = Apollo.MutationResult<RegisterUserProfileAndSendPhoneVerificationMutation>;
export type RegisterUserProfileAndSendPhoneVerificationMutationOptions = Apollo.BaseMutationOptions<RegisterUserProfileAndSendPhoneVerificationMutation, RegisterUserProfileAndSendPhoneVerificationMutationVariables>;
export const VerifyUserPhoneDocument = gql`
    mutation VerifyUserPhone($smsToken: String!) {
  verifyUserPhone(smsToken: $smsToken) {
    user {
      id
    }
    accessToken
    error
  }
}
    `;
export type VerifyUserPhoneMutationFn = Apollo.MutationFunction<VerifyUserPhoneMutation, VerifyUserPhoneMutationVariables>;
export type VerifyUserPhoneMutationResult = Apollo.MutationResult<VerifyUserPhoneMutation>;
export type VerifyUserPhoneMutationOptions = Apollo.BaseMutationOptions<VerifyUserPhoneMutation, VerifyUserPhoneMutationVariables>;
export const SendPasswordResetDocument = gql`
    mutation SendPasswordReset($email: String!) {
  sendPasswordReset(email: $email)
}
    `;
export type SendPasswordResetMutationFn = Apollo.MutationFunction<SendPasswordResetMutation, SendPasswordResetMutationVariables>;
export type SendPasswordResetMutationResult = Apollo.MutationResult<SendPasswordResetMutation>;
export type SendPasswordResetMutationOptions = Apollo.BaseMutationOptions<SendPasswordResetMutation, SendPasswordResetMutationVariables>;
export const VerifyUserEmailDocument = gql`
    mutation VerifyUserEmail($token: String!, $userId: String!) {
  verifyUserEmail(token: $token, userId: $userId) {
    accessToken
    error
  }
}
    `;
export type VerifyUserEmailMutationFn = Apollo.MutationFunction<VerifyUserEmailMutation, VerifyUserEmailMutationVariables>;
export type VerifyUserEmailMutationResult = Apollo.MutationResult<VerifyUserEmailMutation>;
export type VerifyUserEmailMutationOptions = Apollo.BaseMutationOptions<VerifyUserEmailMutation, VerifyUserEmailMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($password: String!, $oldPassword: String, $userId: String, $token: String) {
  updatePassword(
    password: $password
    oldPassword: $oldPassword
    userId: $userId
    token: $token
  ) {
    profileErrors
    authError
    success
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdateEmailDocument = gql`
    mutation UpdateEmail($email: String!) {
  updateEmail(email: $email) {
    user {
      id
      pendingEmail
    }
    profileErrors
    authError
  }
}
    `;
export type UpdateEmailMutationFn = Apollo.MutationFunction<UpdateEmailMutation, UpdateEmailMutationVariables>;
export type UpdateEmailMutationResult = Apollo.MutationResult<UpdateEmailMutation>;
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<UpdateEmailMutation, UpdateEmailMutationVariables>;
export const UpdatePhoneDocument = gql`
    mutation UpdatePhone($phone: String!) {
  updatePhone(phone: $phone) {
    profileErrors
    authError
    user {
      id
      pendingPhone
    }
  }
}
    `;
export type UpdatePhoneMutationFn = Apollo.MutationFunction<UpdatePhoneMutation, UpdatePhoneMutationVariables>;
export type UpdatePhoneMutationResult = Apollo.MutationResult<UpdatePhoneMutation>;
export type UpdatePhoneMutationOptions = Apollo.BaseMutationOptions<UpdatePhoneMutation, UpdatePhoneMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($profile: ProfileInput!) {
  updateProfile(profile: $profile) {
    profileErrors
    authError
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const GetUserFromPasswordRefreshTokenDocument = gql`
    query GetUserFromPasswordRefreshToken($userId: String!, $token: String!) {
  getUserFromPasswordRefreshToken(userId: $userId, token: $token) {
    id
    email
    error
  }
}
    `;
export type GetUserFromPasswordRefreshTokenQueryResult = Apollo.QueryResult<GetUserFromPasswordRefreshTokenQuery, GetUserFromPasswordRefreshTokenQueryVariables>;
export const SendSecondAuthFactorDocument = gql`
    mutation SendSecondAuthFactor($password: String!, $email: String!) {
  sendSecondAuthFactor(password: $password, email: $email) {
    success
    error
  }
}
    `;
export type SendSecondAuthFactorMutationFn = Apollo.MutationFunction<SendSecondAuthFactorMutation, SendSecondAuthFactorMutationVariables>;
export type SendSecondAuthFactorMutationResult = Apollo.MutationResult<SendSecondAuthFactorMutation>;
export type SendSecondAuthFactorMutationOptions = Apollo.BaseMutationOptions<SendSecondAuthFactorMutation, SendSecondAuthFactorMutationVariables>;