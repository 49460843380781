import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Button } from '../../Components/Button'
import { DownloadInvoiceButton } from '../../Components/DownloadInvoiceButton/DownloadInvoiceButton'
import { Heading } from '../../Components/Heading/Heading'
import { Shell } from '../../Components/Shell/Shell'
import { Spinner } from '../../Components/Spinner/Spinner'
import { InvoiceState } from '../../GraphQL/graphql'
import { dateToString } from '../../Helper/TextHelper'
import { useReport } from '../../Hooks/useReport'
import { Alert } from '../../Modules/Alert/Alert'

export const ReportOrders = () => {
  const { t } = useTranslation([], { keyPrefix: 'pages.reports' })
  const { reportId } = useParams()

  const { loading, error, report } = useReport(reportId as string)

  if (loading) {
    return <Spinner />
  }

  if (error || !report) {
    return <Alert message={t('Could not load report.')} />
  }

  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]} noLayout>
      <div className="space-y-3">
        <Heading variant="h1">
          {`Zahlungsverkehr bzgl. des Berichts zu${
            report.illness.name ? ': ' + report.illness.name : ''
          }`}
        </Heading>
        <h2 className="font-noto-serif text-1xl text-dro-blue font-medium mb-5">
          {`Beauftragungsdatum: ${dateToString(report.requestTimestamp)}`}
        </h2>

        <div>
          <table className="">
            <tbody>
              {report.orders.map((order) => (
                <tr key={order.id}>
                  <td>{dateToString(order.createdAt)}</td>
                  <td>
                    <DownloadInvoiceButton orderId={order.id} />
                  </td>
                  <td className="text-right">
                    {[
                      InvoiceState.Created,
                      InvoiceState.PartiallyPaid,
                    ].includes(order.summary.patient.state) ? (
                      <Button to={`/orders/${order.id}/payment`}>
                        {t('Pay now')}
                      </Button>
                    ) : [
                        InvoiceState.PartiallyVoid,
                        InvoiceState.Void,
                      ].includes(order.summary.patient.state) ? (
                      <>{t('Cancelled')}</>
                    ) : (
                      <>{t('Completed')}</>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Shell>
  )
}
