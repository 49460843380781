import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ReportContent } from '../../Components/ReportContent/ReportContent'
import { ReportLayout } from '../../Components/ReportLayout/ReportLayout'
import { Shell } from '../../Components/Shell/Shell'
import { getReportTitle } from '../../Helper/ReportHelper'
import { useReport } from '../../Hooks/useReport'
import { Alert } from '../../Modules/Alert/Alert'
import { Loading } from '../../Modules/Loading/Loading'

export const Report: React.FC = () => {
  const params = useParams()
  const { t } = useTranslation([], { keyPrefix: 'pages.reports' })
  const { error, report, loading } = useReport(params.id)

  const reportDate: Date | undefined = report?.releaseTimestamp
    ? new Date(report.releaseTimestamp)
    : undefined

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Alert message={t('Could not load report.')} />
  }

  return (
    <Shell autogeneratedCircles={2} preGeneratedCircles={[]}>
      {!loading && report && !error && (
        <ReportLayout
          title={getReportTitle(t, report)}
          date={reportDate}
          user={report.user}
          reviewer={report.reviewer ?? undefined}
        >
          <ReportContent report={report} />
        </ReportLayout>
      )}
    </Shell>
  )
}
