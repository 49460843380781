import { Shell } from '../../Components/Shell/Shell'
import { Conversation } from '../../Modules/Conversation/Conversation'

export const DialogBot: React.FC = () => {
  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]} noLayout>
      <div className="flex flex-col items-center">
        <Conversation type="dialog" />
      </div>
    </Shell>
  )
}
