import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { Alert } from '../../Modules/Alert/Alert'

export const SocketDownAlert: React.FC = () => {
  // const { t } = useTranslation()

  function refreshPage() {
    window.location.reload()
  }

  return (
    <header className="flex justify-between items-center">
      <div className="fixed z-20 top-0 left-0 right-0 bottom-0 backdrop-blur-[20px] overflow-y-auto">
        <div className="flex flex-col items-center h-full justify-between pb-[50px] pt-16">
          <Alert message="Verbindung verloren, bitte die Seite neu laden." />
          <button onClick={refreshPage}>
            <ArrowPathIcon className="w-12 h-12" />
          </button>
        </div>
      </div>
    </header>
  )
}
