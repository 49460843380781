import type { JwtPayload } from 'jwt-decode'
import jwtDecode from 'jwt-decode'
import { DateTime } from 'luxon'
import type { FC, PropsWithChildren } from 'react'
import { createContext, useCallback, useEffect, useRef, useState } from 'react'

import type { AccessTokenType } from '../Helper/AuthHelper'
import {
  getAccessTokenContent,
  getAccessToken as getCookieAccessToken,
  setAccessToken as setCookieAccessToken,
} from '../Helper/AuthHelper'
import {
  fetchAccessRefreshToken,
  subscribeNewAccessToken,
} from '../Helper/GraphQLHelper'

type AuthContextType = Partial<AccessTokenType> & {
  accessToken?: string
  loadingAuth: boolean
  updateAuth: (accessToken?: string | null) => Promise<void>
  fetchAuth: (create?: boolean) => Promise<string | undefined>
}

enum STATE {
  'UPDATING',
  'CREATING',
  'IDLE',
}

export const AuthContext = createContext<AuthContextType>({
  updateAuth: async () => {
    return Promise.reject()
  },
  fetchAuth: async () => {
    return Promise.reject()
  },
  loadingAuth: false,
})

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const accessTokenCookie = getCookieAccessToken()
  const [currentAccessToken, setAccessToken] = useState<string | undefined>(
    accessTokenCookie
  )
  const [accessTokenContent, setAccessTokenContent] = useState(
    getAccessTokenContent(accessTokenCookie)
  )
  const isFirstFetch = useRef(false)

  const fetchState = useRef<STATE>(STATE.IDLE)
  const updateAuth = useCallback(async (accessToken?: string | null) => {
    await subscribeNewAccessToken(accessToken ?? undefined)
    if (
      'setAccessToken' in window &&
      typeof window.setAccessToken === 'function'
    ) {
      window.setAccessToken(accessToken ?? undefined)
    }
    setAccessToken(accessToken ?? undefined)
    setAccessTokenContent(getAccessTokenContent(accessToken ?? undefined))
    setCookieAccessToken(accessToken)
  }, [])

  const fetchAuth = useCallback(
    async (create = false) => {
      if (fetchState.current === STATE.CREATING) {
        return
      }
      if (fetchState.current === STATE.UPDATING && !create) {
        return
      }
      if (fetchState.current === STATE.UPDATING && create) {
        fetchState.current = STATE.CREATING
        // after updating, in will fetch another time with creating
        return
      }

      fetchState.current = create ? STATE.CREATING : STATE.UPDATING
      let at = (await fetchAccessRefreshToken(create)) ?? undefined
      if (!create && fetchState.current === STATE.CREATING && !at) {
        at = (await fetchAccessRefreshToken(true)) ?? undefined
      }
      await updateAuth(at)
      fetchState.current = STATE.IDLE
      return at
    },
    [updateAuth]
  )

  useEffect(() => {
    if (!isFirstFetch.current) {
      fetchAuth(false)
      isFirstFetch.current = true
    }
  }, [fetchAuth])

  useEffect(() => {
    let exp = 0
    try {
      const at = accessTokenCookie ? accessTokenCookie : currentAccessToken
      at && ({ exp = 0 } = jwtDecode<JwtPayload>(at))
    } catch {
      exp = 0
    }
    const expiration = DateTime.fromSeconds(exp).minus({ minutes: 2 })
    const expirationMillis = expiration.diffNow().toMillis()
    const timer = setTimeout(async () => {
      fetchAuth(false)
    }, Math.max(expirationMillis, 10000))

    return () => {
      clearTimeout(timer)
    }
  }, [currentAccessToken, accessTokenCookie, fetchAuth])

  return (
    <AuthContext.Provider
      value={{
        accessToken: currentAccessToken,
        updateAuth,
        fetchAuth,
        loadingAuth: fetchState.current !== STATE.IDLE ?? !isFirstFetch.current,
        ...accessTokenContent,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
