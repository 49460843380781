import { PaperAirplaneIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>

export const SendInputButton: React.FC<Props> = ({ className, ...rest }) => {
  const { t } = useTranslation()

  return (
    <button
      className={classNames(
        'flex justify-center items-center rounded-full bg-dro-green',
        !rest.disabled ? 'cursor-pointer' : 'cursor-not-allowed',
        className
      )}
      {...rest}
    >
      <div>
        <PaperAirplaneIcon className="text-white w-7 h-7 mx-auto -rotate-45" />
        <span className="sr-only">{t('components.button.Send')}</span>
      </div>
    </button>
  )
}
