import { Shell } from '../../Components/Shell/Shell'
import { Conversation } from '../../Modules/Conversation/Conversation'

export const Chatbot: React.FC = () => {
  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]} noLayout>
      <Conversation type="chat" />
    </Shell>
  )
}
