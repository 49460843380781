import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type {
  DeleteDicomSeriesMutation,
  DicomPatientFragment,
} from '../../GraphQL/graphql'
import { DeleteDicomSeriesDocument } from '../../GraphQL/graphql'
import { urlSeriesRendered } from '../../Helper/DicomHelper'
import { Button } from '../Button'
import { Card } from '../Card/Card'
import { CardList } from '../CardList/CardList'
import { Spinner } from '../Spinner/Spinner'

interface Props {
  dicomPatient: DicomPatientFragment
  // reports?: BaseReportFragment[] | null
}

export const DicomFileList: React.FC<Props> = ({ dicomPatient }) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const handle = async () => {
      for (const ser of dicomPatient.studies.flatMap((study) => study.series)) {
        // newSrcs[ser.id] = await urlSeriesThumbnail(ser.id)
        try {
          urlSeriesRendered(ser.id, 300)
            .then((src) => {
              const image = document.getElementById(`thumbnail-${ser.id}`)
              if (image) {
                image.setAttribute('src', src)
              }
            })
            .catch(() => {
              // TODO: handle error properly
            })
            .finally(() => {
              const spinner = document.getElementById(`spinner-${ser.id}`)
              const image = document.getElementById(`thumbnail-${ser.id}`)
              if (spinner) {
                spinner.hidden = true
              }
              if (image) {
                image.hidden = false
              }
            })
        } catch (exception) {
          const image = document.getElementById(`thumbnail-${ser.id}`)
          const spinner = document.getElementById(`spinner-${ser.id}`)
          if (spinner) {
            spinner.hidden = true
          }
          if (image) {
            image.hidden = false
          }
        }
      }
    }
    handle()
  }, [dicomPatient])

  // const [getRenderedInstance, {data:imgData, loading: imgLoading}] = useLazyQuery<GetRenderedDicomInstanceQuery>(GetRenderedDicomInstanceDocument)
  const [deleteDicomSeries] = useMutation<DeleteDicomSeriesMutation>(
    DeleteDicomSeriesDocument
  )

  return (
    <CardList>
      {dicomPatient?.studies.map((study) =>
        study.series.map((series) => {
          return (
            <Card key={series.id} title={study.description ?? undefined}>
              <div className="flex flex-col space-y-6">
                <div>
                  <p>
                    {`${t('pages.files.Image Series')}: ${
                      series.instances.length
                    }`}
                  </p>
                  {study.date && (
                    <p>
                      {t('pages.files.Date of study')}:{' '}
                      {new Date(study.date).toLocaleDateString(i18n.language)}
                    </p>
                  )}
                  {study.institution && <p>{study.institution}</p>}
                </div>
                <div className="w-full grow-0 shrink-0 basis-1/5 max-h-80 h-full">
                  <div id={`spinner-${series.id}`}>
                    <Spinner />
                  </div>
                  <img
                    hidden={true}
                    id={`thumbnail-${series.id}`}
                    className="object-contain mx-auto h-full w-full"
                    alt="Error Loading"
                  />
                </div>

                {series.deletable && (
                  <Button
                    onClick={() => {
                      deleteDicomSeries({
                        variables: { seriesId: series.id },
                      })
                    }}
                  >
                    {t('components.button.Delete')}
                  </Button>
                )}
              </div>
            </Card>
          )
        })
      )}
    </CardList>
  )
}
