import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Button } from '../../Components/Button'
import { DicomFileList } from '../../Components/DicomFileList/DicomFileList'
import { DicomUpload } from '../../Components/DicomUpload/DicomUpload'
import { Heading } from '../../Components/Heading/Heading'
import { Modal } from '../../Components/Modal/Modal'
import { Shell } from '../../Components/Shell/Shell'
import { Spinner } from '../../Components/Spinner/Spinner'

import { useDicomPatient } from '../../Hooks/useDicomPatient'
import { Alert } from '../../Modules/Alert/Alert'

export const Files: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const next = searchParams.get('next') || null

  // const successDialogRef = useRef<A11yDialogInstance>(null)
  // const warnDialogRef = useRef<A11yDialogInstance>(null)

  const { dicomPatient, dicomPatientLoading } = useDicomPatient()

  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const dialog = (
    <Modal isOpen={dialogIsOpen}>
      <div className="flex flex-col m-4 justify-center">
        <DicomUpload onFinish={() => setDialogIsOpen(false)} />
      </div>
    </Modal>
  )

  return (
    <Shell autogeneratedCircles={2} preGeneratedCircles={[]} noLayout>
      <div>
        <Heading variant="h1">{t('pages.files.X-ray images')}</Heading>

        <p className="my-5">
          <Button onClick={() => setDialogIsOpen(true)}>
            {t('pages.files.Upload new x-ray images')}
          </Button>
        </p>

        {next !== null && (
          <p>
            <Button variant="secondary" to={next}>
              {t('pages.files.Continue doctor talk')}
            </Button>
          </p>
        )}

        {dicomPatientLoading ? (
          <div>
            <Spinner />
          </div>
        ) : !dicomPatient?.studies.some((s) =>
            s.series.some((s) => s.instances.length > 0)
          ) ? (
          <Alert message={t('pages.files.No files have been uploaded yet')} />
        ) : (
          <DicomFileList dicomPatient={dicomPatient} />
        )}
      </div>

      {dialog}

      {/* <A11yDialog
        dialogRef={(instance) => (uploadDialogRef.current = instance)}
        {...getDialogOptions('', 'dialog--upload', DIALOG_SIZE.SMALL)}
      >
        <div className="overflow-y-auto h-full w-full px-4 flex flex-col justify-center">
          <DicomUpload onFinish={() => uploadDialogRef.current?.hide()} />
        </div>
      </A11yDialog> */}

      {/* <A11yDialog
        dialogRef={(instance) => (warnDialogRef.current = instance)}
        {...getDialogOptions(
          'Auswahl bestätigen',
          'dialog--warn',
          DIALOG_SIZE.SMALL
        )}
      >
        <div className="overflow-y-auto h-full w-full p-4 pr-12">
          <p>{t('confirm-isolated-xray-request')}</p>

          <button
            className="button-primary h-[5vh] px-4"
            onClick={() => {
              handleRequestReport()
            }}
          >
            {t('Request x-ray report')}
          </button>
          <button
            className="button-error px-5 py-3 ml-2"
            onClick={() => {
              warnDialogRef.current?.hide()
            }}
          >
            {t('components.button.Abort')}
          </button>
        </div>
      </A11yDialog>

      <A11yDialog
        dialogRef={(instance) => (successDialogRef.current = instance)}
        {...getDialogOptions('Fertig.', 'dialog--success')}
      >
        <div className="overflow-y-auto h-full w-full p-4 pr-12">
          <p>{t('confirm-xray-requested')}</p>

          <button
            className="button-primary px-4 py-2"
            onClick={() => {
              warnDialogRef.current?.hide()
              successDialogRef.current?.hide()
            }}
          >
            {t('Okay')}
          </button>
        </div>
      </A11yDialog> */}
    </Shell>
  )
}
