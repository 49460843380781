import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MdRenderer } from '../../Components/MdRenderer/MdRenderer'
import { ReportLayout } from '../../Components/ReportLayout/ReportLayout'
import { Shell } from '../../Components/Shell/Shell'
import { useProfile } from '../../Hooks/useProfile'
import { useReportPreview } from '../../Hooks/useReportPreview'
import { Alert } from '../../Modules/Alert/Alert'
import { Loading } from '../../Modules/Loading/Loading'

type ReportPreviewParams = {
  sessionId?: string
}

export const ReportPreview: React.FC = () => {
  const { t } = useTranslation([], { keyPrefix: 'pages.reports' })
  const { sessionId } = useParams<ReportPreviewParams>()
  const { report, reportLoading, reportError } = useReportPreview(sessionId)

  const { profile, profileLoading } = useProfile()

  if (reportLoading || profileLoading) {
    return (
      <Shell preGeneratedCircles={[]} autogeneratedCircles={0}>
        <Loading />
      </Shell>
    )
  }

  if (reportError || !report) {
    return (
      <Shell preGeneratedCircles={[]} autogeneratedCircles={0}>
        <Alert message={t('Could not load report.')} />
      </Shell>
    )
  }

  return (
    <Shell preGeneratedCircles={[]} autogeneratedCircles={0}>
      <ReportLayout title={t('Anamnesis Report')} user={profile}>
        <MdRenderer mdText={report} styling />
      </ReportLayout>
    </Shell>
  )
}
