import classNames from 'classnames'
import { isString } from 'lodash'
import { type FC, type InputHTMLAttributes } from 'react'
import type {
  FieldErrors,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form'
import { Input } from '../Input'

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  id: string
  label: string
  info?: string
  invisible?: boolean
  register: UseFormRegister<any>
  options?: RegisterOptions<FieldValues>
  errors?: FieldErrors<FieldValues>
  size?: 'sm' | 'md' | 'lg'
}

export const FormInput: FC<InputProps> = ({
  className,
  id,
  label,
  info,
  invisible = false,
  register,
  errors,
  options,
  size = 'lg',
  ...rest
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        className={classNames(
          'text-lg cursor-pointer',
          !invisible && 'mb-2 inline-block',
          invisible && 'sr-only'
        )}
      >
        {label}
      </label>

      <Input
        className={classNames(
          errors && errors[id]?.message && 'border-dro-red border-2',
          className
        )}
        aria-label={label}
        placeholder={label}
        size={size}
        {...register(id, options)}
        {...rest}
      />

      {info && (
        <div className="justify-end text-right">
          <label htmlFor={id} className="text-sm">
            {info}
          </label>
        </div>
      )}

      {errors && isString(errors[id]?.message) && (
        <label htmlFor={id} className="text-dro-red justify-end text-right">
          {errors[id]?.message as string}
          {!['.', '!', '?'].includes(
            (errors[id]?.message as string).at(-1) ?? '.'
          ) && '.'}
        </label>
      )}
    </div>
  )
}
