import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Chat } from '../../Components/Chat/Chat'
import { ChatInput } from '../../Components/ChatInput/ChatInput'
import { ConversationProgressBar } from '../../Components/ConversationProgressBar/ConversationProgressBar'
import { Dialog } from '../../Components/Dialog/Dialog'
import { Spinner } from '../../Components/Spinner/Spinner'
import type {
  ContinueSessionMutation,
  ContinueSessionMutationVariables,
  GetActiveSessionResponse,
  SessionChatFragment,
} from '../../GraphQL/graphql'
import {
  ActionType,
  ContinueSessionDocument,
  ConversationError,
} from '../../GraphQL/graphql'

import { useActiveSession } from '../../Hooks/useActiveSession'
import { useDialogFullscreen } from '../../Hooks/useDialogFullscreen'
import { useGtm } from '../../Hooks/useGtm'
import { useProfile } from '../../Hooks/useProfile'
import { Alert } from '../Alert/Alert'
import { Loading } from '../Loading/Loading'

const DELAY_MSEC_BEFORE_ACTION = 2000

type Props = {
  type: 'chat' | 'dialog'
}

export const Conversation: React.FC<Props> = ({ type }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const start = searchParams.get('start') ?? undefined
  const { trackStartConversation } = useGtm()

  const activeSession = useActiveSession(start)
  const {
    session,
    action,
    error,
    loading,
    useSessionState,
    videoPath,
    progress,
  } = activeSession

  const { profile } = useProfile()
  const [sessionState, setSessionState] = useSessionState
  const inputRef = useRef<HTMLDivElement | null>(null)

  const [continueSession] = useMutation<
    ContinueSessionMutation,
    ContinueSessionMutationVariables
  >(ContinueSessionDocument, {
    onCompleted: (data) =>
      setSessionState &&
      data.continueSession.error &&
      setSessionState({
        loading: false,
        error: data.continueSession.error ?? undefined,
      }),
  })

  const lastUserMessage = useMemo(
    () => session?.messages?.filter(({ userText }) => !!userText).at(-1),
    [session]
  )

  const lastBotMessage = useMemo(
    () => session?.messages?.filter(({ botText }) => !!botText).at(-1),
    [session]
  )

  const { isFullscreen, switchFullscreen } = useDialogFullscreen(type)

  const handleNewNext = useCallback(
    async (
      session?: SessionChatFragment,
      action?: GetActiveSessionResponse['action'],
      error?: GetActiveSessionResponse['error']
    ) => {
      const pushContinue = async () => {
        if (session?.messages?.at(-1)?.infoText) {
          await continueSession({
            variables: {
              sessionId: session.id,
              lastElementId: session.messages.at(-1)?.treeElementId,
            },
          })
        }
      }

      if (error?.error === ConversationError.OpenOrder && !!error.orderId) {
        await new Promise((f) => setTimeout(f, DELAY_MSEC_BEFORE_ACTION))
        navigate(`/orders/${error.orderId}/payment`)
      }
      if (error?.error === ConversationError.FrozenSession && !!session?.id) {
        await new Promise((f) => setTimeout(f, DELAY_MSEC_BEFORE_ACTION))
        navigate(`/sessions/${session?.id}/checkout?next=/${type}`)
      }

      if (session?.messages?.at(-1)?.infoText && !action) {
        pushContinue()
        return
      }

      const next = `/${type}?start=${start}`

      switch (action?.type) {
        case ActionType.GoToProfile:
          if (profile?.isComplete) {
            await new Promise((f) => setTimeout(f, DELAY_MSEC_BEFORE_ACTION))
            navigate('/profile')
          } else {
            pushContinue()
            return
          }
          break
        case ActionType.GoToLogin:
          if (!profile?.isComplete) {
            await new Promise((f) => setTimeout(f, DELAY_MSEC_BEFORE_ACTION))
            navigate(`/login?next=${next}&transfer`)
          } else {
            pushContinue()
            return
          }
          break
        case ActionType.GoToRegistration:
          if (!profile?.isComplete) {
            await new Promise((f) => setTimeout(f, DELAY_MSEC_BEFORE_ACTION))
            navigate(`/register?next=${next}&transfer`)
          } else {
            pushContinue()
            return
          }
          break
        case ActionType.GoToFiles:
          if (profile?.isComplete) {
            await new Promise((f) => setTimeout(f, DELAY_MSEC_BEFORE_ACTION))
            navigate(`/files?next=${next}`)
          } else {
            pushContinue()
            return
          }
          break
        case ActionType.GoToReports:
          if (profile?.isComplete) {
            await new Promise((f) => setTimeout(f, DELAY_MSEC_BEFORE_ACTION))
            navigate('/reports')
          } else {
            pushContinue()
            return
          }
          break
        case ActionType.LogoutUser:
          if (profile?.isComplete) {
            await new Promise((f) => setTimeout(f, DELAY_MSEC_BEFORE_ACTION))
            navigate('/logout')
          } else {
            pushContinue()
            return
          }
          break
        case ActionType.GoToAnamnesis:
          await new Promise((f) => setTimeout(f, DELAY_MSEC_BEFORE_ACTION))
          navigate(`/anamnesis?next=/${type}`)
          break
        case ActionType.GoToUrl:
          if (action.url) {
            await new Promise((f) => setTimeout(f, DELAY_MSEC_BEFORE_ACTION))
            navigate(action.url)
          }
          break
        default:
          break
      }

      setSessionState({ loading: false })
    },
    [continueSession, navigate, profile, setSessionState, type]
  )

  useEffect(() => {
    setSessionState({ loading: true })
    handleNewNext(session, action, error)
  }, [handleNewNext, session, action, error, setSessionState])

  const onAnswerCallback = useCallback(() => {
    // start conversation event
    if (session?.messages.length === 1) {
      trackStartConversation({
        conversation_type: type,
      })
    }
  }, [session?.messages, type, trackStartConversation])

  useEffect(() => {
    const handle = async () => {
      await new Promise((f) => setTimeout(f, 100))

      if (inputRef.current) {
        inputRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      }
    }
    type === 'chat' && handle()
  }, [lastUserMessage, type, session, sessionState])

  if (loading) {
    return <Loading />
  }

  if (
    error?.error &&
    [ConversationError.FrozenSession, ConversationError.OpenOrder].includes(
      error.error
    )
  ) {
    return (
      <Alert
        icon="loading"
        message={t(
          'pages.conversation.An open order has been found, you will be forwarded'
        )}
      />
    )
  }

  if (
    (error?.error &&
      ![ConversationError.FrozenSession, ConversationError.OpenOrder].includes(
        error.error
      )) ||
    !session
  ) {
    return <Alert message={t('error.Could not load data')} />
  }

  return (
    <>
      {process.env.REACT_APP_IS_RESEARCH_INSTANCE && profile?.isResearch && (
        <ConversationProgressBar progress={progress} />
      )}
      <div className="flex flex-col items-center">
        {type === 'chat' && (
          <Chat
            sessionId={session.id ?? undefined}
            messages={session.messages ?? undefined}
            lastUserMessage={lastUserMessage}
            withVideo={false}
            readOnly={false}
            useSessionState={useSessionState}
            videoPath={videoPath}
          />
        )}
        {type === 'dialog' && (
          <Dialog
            sessionId={session.id ?? undefined}
            messages={session.messages ?? undefined}
            lastBotMessage={lastBotMessage}
            isFullscreen={isFullscreen}
            switchFullscreen={switchFullscreen}
            useSessionState={useSessionState}
            videoPath={videoPath}
          />
        )}
        <div className="max-w-6xl w-full" ref={inputRef}>
          {!sessionState.loading ? (
            !session.isEndOfSession && (
              <div className={classNames(type === 'dialog' && 'mt-8')}>
                <ChatInput
                  useSessionState={useSessionState}
                  activeSession={activeSession}
                  onAnswerCallback={onAnswerCallback}
                />
              </div>
            )
          ) : (
            <div className="flex items-center justify-start w-full h-auto overflow-hidden">
              <Spinner withLayout />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
