import classNames from 'classnames'
import type { AnchorHTMLAttributes, FC } from 'react'
import type { LinkProps } from 'react-router-dom'
import { Link as ReactLink } from 'react-router-dom'

export type HeadingProps = LinkProps &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    // to: To
    blank?: boolean
    variant?: 'primary' | 'secondary'
  }

export const Link: FC<HeadingProps> = ({
  className,
  variant = 'primary',
  blank = false,
  children,
  ...props
}) => {
  return (
    <ReactLink
      target={blank ? '_blank' : ''}
      className={classNames(
        'underline underline-offset-4 cursor-pointer',
        variant === 'primary' && 'text-dro-green hover:text-dro-darkgreen',
        variant === 'secondary' && 'text-dro-blue hover:text-dro-lightblue',
        className
      )}
      {...props}
    >
      {children}
    </ReactLink>
  )
}
