import { useMutation } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../Components/Button'
import { Shell } from '../../Components/Shell/Shell'
import { Spinner } from '../../Components/Spinner/Spinner'
import { AuthContext } from '../../Contexts/AuthContext'
import type {
  LoginUserWithTokenMutation,
  LoginUserWithTokenMutationVariables,
} from '../../GraphQL/graphql'
import { LoginUserWithTokenDocument } from '../../GraphQL/graphql'

type LoginParams = {
  token?: string
}

export const TokenLogin: React.FC = () => {
  const { t } = useTranslation()
  const { updateAuth } = useContext(AuthContext)

  const params = useParams<LoginParams>()
  const navigate = useNavigate()

  const [loginUser, { data, loading }] = useMutation<
    LoginUserWithTokenMutation,
    LoginUserWithTokenMutationVariables
  >(LoginUserWithTokenDocument, {
    async onCompleted(data) {
      await updateAuth(data?.loginUserWithToken.accessToken ?? undefined)
    },
  })

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (params.token) {
      loginUser({ variables: { token: params.token } })
    } else {
      setErrorMessage(t('error.Invalid link'))
    }
  }, [loginUser, params, t])

  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]}>
      <div className="flex flex-col space-y-6">
        {loading ? (
          <Spinner />
        ) : errorMessage ? (
          <>{errorMessage}</>
        ) : !params.token || data?.loginUserWithToken.error ? (
          <>{t('error.Invalid link')}</>
        ) : (
          <>
            <p className="mt-6">
              {t('pages.login.Welcome to DocRobin. You will be redirected')}
            </p>
            <Button
              onClick={() => {
                navigate('/')
              }}
            >
              {t('pages.login.Start conversation')}
            </Button>
          </>
        )}
      </div>
    </Shell>
  )
}
