import { useEffect, useState } from 'react'

export const useDialogFullscreen = (type: string) => {
  const [isFullscreen, setIsFullscreen] = useState(false)

  const enterFullscreen = () => {
    if (type !== 'dialog') return
    if (isFullscreen) return

    const headers = document.getElementsByTagName('header')
    if (headers && headers[0]) {
      headers[0].style.display = 'none'
    }

    const footers = document.getElementsByTagName('footer')
    if (footers && footers[footers.length - 1]) {
      footers[0].style.display = 'none'
    }

    setIsFullscreen(true)
  }

  const exitFullscreen = (forceExit?: boolean) => {
    if (!isFullscreen && !forceExit) return

    const headers = document.getElementsByTagName('header')
    if (headers && headers[0]) {
      headers[0].style.display = 'initial'
    }

    const footers = document.getElementsByTagName('footer')
    if (footers && footers[footers.length - 1]) {
      footers[0].style.display = 'initial'
    }

    setIsFullscreen(false)
  }

  const switchFullscreen = () => {
    if (isFullscreen) {
      exitFullscreen()
    } else {
      enterFullscreen()
    }
  }

  useEffect(() => {
    if (type === 'dialog') {
      enterFullscreen()
    }

    return () => {
      if (type === 'dialog') {
        exitFullscreen(true)
      }
    }
  }, [type])

  useEffect(() => {
    const onKeyPressed = (e) => {
      if (e.keyCode !== 27) return

      exitFullscreen()
    }

    document.addEventListener('keydown', onKeyPressed)

    return () => {
      document.removeEventListener('keydown', onKeyPressed)
    }
  }, [exitFullscreen])

  return {
    isFullscreen,
    switchFullscreen,
    enterFullscreen,
    exitFullscreen,
  }
}
