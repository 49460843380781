import { useMutation } from '@apollo/client'
import type { FC } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '../../Components/Link/Link'
import { AuthContext } from '../../Contexts/AuthContext'
import type {
  RegisterUserDiscardMailMutation,
  RegisterUserDiscardMailMutationVariables,
  UserFragment,
} from '../../GraphQL/graphql'
import { RegisterUserDiscardMailDocument } from '../../GraphQL/graphql'
import { Button } from '../Button'
import { Spinner } from '../Spinner/Spinner'

type Props = {
  profile?: UserFragment
}

export const RegisterWaitEmailVerification: FC<Props> = ({ profile }) => {
  const { t } = useTranslation()
  const { updateAuth } = useContext(AuthContext)

  const [discardMail, { loading }] = useMutation<
    RegisterUserDiscardMailMutation,
    RegisterUserDiscardMailMutationVariables
  >(RegisterUserDiscardMailDocument, {
    async onCompleted(data) {
      if (data.registerUserDiscardMail.accessToken) {
        updateAuth(data.registerUserDiscardMail.accessToken)
      }
    },
  })

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="flex flex-col items-center justify-center space-y-6 text-center">
      {profile?.pendingEmail && (
        <div className="flex flex-col items-center">
          <p>{t('pages.registration.We have sent an activation link to')}</p>
          <Link to={`mailto:${profile?.pendingEmail}`}>
            {profile?.pendingEmail}
          </Link>
        </div>
      )}

      <p className="font-bold">
        {t('pages.registration.Please verify your E-Mail using the sent link')}
      </p>
      <p>
        {t(
          'pages.registration.No e-mail received? Please check your spam folder or correct your e-mail address.'
        )}
      </p>
      <Button size="lg" className="w-fit" onClick={() => discardMail()}>
        {t('pages.profile.Change Mail')}
      </Button>
    </div>
  )
}
