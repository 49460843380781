import { useTranslation } from 'react-i18next'
import type { To } from 'react-router-dom'
import { useParams, useSearchParams } from 'react-router-dom'
import { Button } from '../../Components/Button'
import { Chat } from '../../Components/Chat/Chat'
import { Heading } from '../../Components/Heading/Heading'
import { Shell } from '../../Components/Shell/Shell'
import { useReport } from '../../Hooks/useReport'
import { Alert } from '../../Modules/Alert/Alert'
import { Loading } from '../../Modules/Loading/Loading'

type ChatArchiveParams = {
  reportId: string
}

export const ChatArchive: React.FC = () => {
  const { reportId } = useParams<ChatArchiveParams>()
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const nextUrl = searchParams.get('next')

  const { report, loading, error } = useReport(reportId)

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Alert message={t('error.Could not load data')} />
  }

  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]}>
      <header className="z-10 sticky top-0 old-bg-8 print:hidden bg-old-8">
        <div className="flex justify-between py-4">
          <div className="basis-1/2">
            <Button to={nextUrl ? nextUrl : (-1 as To)}>
              {nextUrl
                ? t('components.button.Back')
                : t('pages.conversation.Go to overview')}
            </Button>
          </div>
        </div>
      </header>
      <div className="p-3 old-bg-3 text-[16px]/[22px]">
        <div className="flex flex-col">
          <Heading variant="h1">
            {t('pages.conversation.Conversation history')}
          </Heading>

          {!report?.conversationSession?.messages.length ? (
            <Alert
              message={'pages.conversation.No conversation history found'}
            />
          ) : (
            <Chat messages={report.conversationSession.messages} readOnly />
          )}
        </div>
      </div>
    </Shell>
  )
}
