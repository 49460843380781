import { useLazyQuery } from '@apollo/client'
import type { Dispatch, SetStateAction } from 'react'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { AuthContext } from '../Contexts/AuthContext'
import { WebSocketContext } from '../Contexts/WebSocketContext'
import type {
  ConversationError,
  GetActiveResearchSessionQuery,
  GetActiveResearchSessionQueryVariables,
  GetActiveResearchSessionUpdatesSubscription,
  GetActiveResearchSessionUpdatesSubscriptionVariables,
  GetActiveSessionQuery,
  GetActiveSessionQueryVariables,
  GetActiveSessionUpdatesSubscription,
  GetActiveSessionUpdatesSubscriptionVariables,
} from '../GraphQL/graphql'
import {
  GetActiveResearchSessionDocument,
  GetActiveResearchSessionUpdatesDocument,
  GetActiveSessionDocument,
  GetActiveSessionUpdatesDocument,
} from '../GraphQL/graphql'

type ActiveSessionStateType = {
  loading: boolean
  error?: ConversationError
}

export type UseSessionStateType = [
  ActiveSessionStateType,
  Dispatch<SetStateAction<ActiveSessionStateType>>
]

export const useActiveSession = (startId?: string) => {
  const { onError } = useContext(WebSocketContext)
  const { userId, fetchAuth, loadingAuth } = useContext(AuthContext)

  const [getActiveSession, { error, data, loading, subscribeToMore }] =
    useLazyQuery<
      | GetActiveSessionQuery
      | GetActiveResearchSessionQuery
      | GetActiveSessionUpdatesSubscription
      | GetActiveResearchSessionUpdatesSubscription,
      GetActiveSessionQueryVariables | GetActiveResearchSessionQueryVariables
    >(
      process.env.REACT_APP_IS_RESEARCH_INSTANCE
        ? GetActiveResearchSessionDocument
        : GetActiveSessionDocument,
      {
        fetchPolicy: 'network-only',
      }
    )

  useEffect(() => {
    if (!userId) {
      fetchAuth(true)
    } else {
      getActiveSession({ variables: { startId } })
    }
  }, [fetchAuth, getActiveSession, userId, startId])

  const retries = useRef(0)

  useEffect(() => {
    if (!userId) return
    if (retries.current > 5) return
    const unsubscribe = subscribeToMore<
      GetActiveSessionUpdatesSubscription | GetActiveSessionUpdatesSubscription,
      | GetActiveSessionUpdatesSubscriptionVariables
      | GetActiveResearchSessionUpdatesSubscriptionVariables
    >({
      document: process.env.REACT_APP_IS_RESEARCH_INSTANCE
        ? GetActiveResearchSessionUpdatesDocument
        : GetActiveSessionUpdatesDocument,
      variables: { startId },
      updateQuery: (_, { subscriptionData }) => {
        return subscriptionData.data
      },
      onError,
    })
    return unsubscribe
  }, [onError, subscribeToMore, userId, startId])

  const useSessionState = useState<ActiveSessionStateType>({
    loading: false,
    error: undefined,
  })
  const [_, setSessionState] = useSessionState

  useEffect(() => {
    if (loading) {
      setSessionState({ loading: true, error: undefined })
    } else if (data?.getActiveSession.error) {
      setSessionState((prevState) => ({
        loading: prevState.loading,
        error: data?.getActiveSession.error?.error ?? undefined,
      }))
    }
  }, [loading, data, setSessionState])

  return useMemo(
    () => ({
      session: data?.getActiveSession.session ?? undefined,
      error: data?.getActiveSession.error ?? undefined,
      input: data?.getActiveSession.input ?? undefined,
      action: data?.getActiveSession.action ?? undefined,
      loading: loading || loadingAuth || (!data && !error),
      useSessionState,
      videoPath: data?.getActiveSession.videoPath ?? undefined,
      progress:
        data?.getActiveSession.session &&
        'progress' in data.getActiveSession.session
          ? data?.getActiveSession.session.progress
          : undefined,
    }),
    [data, useSessionState, loading, loadingAuth, error]
  )
}
