import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  CancelOrderReportMutation,
  CancelOrderReportMutationVariables,
  GetReportQuery,
  GetReportQueryVariables,
  GetReportUpdatesSubscription,
  GetReportUpdatesSubscriptionVariables,
} from '../GraphQL/graphql'
import {
  CancelOrderReportDocument,
  GetReportDocument,
  GetReportUpdatesDocument,
} from '../GraphQL/graphql'

export const useReport = (id?: string) => {
  const [getReport, { data, loading, subscribeToMore }] = useLazyQuery<
    GetReportQuery,
    GetReportQueryVariables
  >(GetReportDocument, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    id && getReport({ variables: { reportId: id } })
  }, [getReport, id])

  useEffect(() => {
    if (!id) return
    const unsubscribe = subscribeToMore<
      GetReportUpdatesSubscription,
      GetReportUpdatesSubscriptionVariables
    >({
      document: GetReportUpdatesDocument,
      variables: {
        reportId: id,
      },
      updateQuery: (prev, { subscriptionData: { data } }) => ({
        ...data,
        __typename: prev.__typename,
      }),
    })

    return () => unsubscribe()
  }, [id, subscribeToMore])

  const cancelOrderReport = useMutation<
    CancelOrderReportMutation,
    CancelOrderReportMutationVariables
  >(CancelOrderReportDocument, { variables: { reportId: id } })

  const mutations = useMemo(
    () => ({
      cancelOrderReport,
    }),
    [cancelOrderReport]
  )

  return useMemo(
    () => ({
      report: data?.getReport.report ?? null,
      loading,
      error: data?.getReport.error ?? null,
      mutations,
    }),
    [data, loading, mutations]
  )
}
