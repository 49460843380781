import { useMutation } from '@apollo/client'
import { DateTime, Duration } from 'luxon'
import type { FC } from 'react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../Contexts/AuthContext'
import type {
  ProfileInput,
  RegisterUserProfileAndSendPhoneVerificationMutation,
  RegisterUserProfileAndSendPhoneVerificationMutationVariables,
  UserFragment,
} from '../../GraphQL/graphql'
import {
  ProfileError,
  ProfileSex,
  RegisterUserProfileAndSendPhoneVerificationDocument,
} from '../../GraphQL/graphql'
import {
  authErrorToMessage,
  birthdateRegex,
  firstnameRegex,
  lastnameRegex,
  phoneRegex,
} from '../../Helper/AuthHelper'
import { profileErrorToMessage } from '../../Helper/UserHelper'
import { AlertMessage } from '../AlertMessage/AlertMessage'
import { Anchor } from '../Anchor/Anchor'
import { Button } from '../Button'
import { FormInput } from '../FormInput/FormInput'
import { FormRadioGroup } from '../FormRadioGroup/FormRadioGroup'
import { Modal } from '../Modal/Modal'

type Props = {
  isDisabled?: boolean
  smallPaddingBottom?: boolean
  profile?: UserFragment
}

export const RegisterEnterProfileInfo: FC<Props> = ({
  isDisabled,
  profile,
}) => {
  const { t } = useTranslation()
  const { updateAuth } = useContext(AuthContext)

  const [registerProfile, { loading }] = useMutation<
    RegisterUserProfileAndSendPhoneVerificationMutation,
    RegisterUserProfileAndSendPhoneVerificationMutationVariables
  >(RegisterUserProfileAndSendPhoneVerificationDocument, {
    onCompleted: ({ registerUserProfileAndSendPhoneVerification }) => {
      if (registerUserProfileAndSendPhoneVerification.authError) {
        setError('root', {
          message: authErrorToMessage(
            registerUserProfileAndSendPhoneVerification.authError,
            t
          ),
        })
      } else if (registerUserProfileAndSendPhoneVerification.profileErrors) {
        const errors: string[] = []
        registerUserProfileAndSendPhoneVerification.profileErrors.forEach(
          (error) => {
            switch (error) {
              case ProfileError.InvalidBirthdateFormat:
                setError('birthdate', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidFirstnameFormat:
                setError('firstname', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidLastnameFormat:
                setError('lastname', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidTitleFormat:
                setError('title', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidPhoneFormat:
                setError('phone', {
                  message: profileErrorToMessage(error, t),
                })
                break
              default:
                errors.push(profileErrorToMessage(error, t))
                break
            }
            if (errors.length) {
              setError('root', {
                message: errors.join(' | '),
              })
            }
          }
        )
      } else if (registerUserProfileAndSendPhoneVerification.accessToken) {
        updateAuth(registerUserProfileAndSendPhoneVerification.accessToken)
      }
    },
    onError: () => {
      setError('root', {
        message: t(
          'error.Saving not possible. If this error occurs repeatedly, please contact our support.'
        ),
      })
    },
  })

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitting },
  } = useForm<ProfileInput & { phone: string }>({
    defaultValues: useMemo(() => {
      return {
        phone: profile?.pendingPhone ?? undefined,
        sex: profile?.sex ?? undefined,
        title: profile?.title ?? undefined,
        firstname: profile?.firstname ?? undefined,
        lastname: profile?.lastname ?? undefined,
        birthdate: profile?.birthdate ?? undefined,
      }
    }, [
      profile?.pendingPhone,
      profile?.sex,
      profile?.title,
      profile?.firstname,
      profile?.lastname,
      profile?.birthdate,
    ]),
  })

  useEffect(() => {
    reset({
      phone: profile?.pendingPhone ?? undefined,
      sex: profile?.sex ?? ProfileSex.Female,
      title: profile?.title ?? undefined,
      firstname: profile?.firstname ?? undefined,
      lastname: profile?.lastname ?? undefined,
      birthdate: profile?.birthdate ?? undefined,
    })
  }, [profile, reset])

  const onSubmit = handleSubmit(async (profileinput) => {
    const { phone, ...profile } = profileinput
    await registerProfile({
      variables: {
        profile,
        phone,
      },
    })
  })

  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const dialog = (
    <Modal isOpen={dialogIsOpen}>
      <div className="dialog-content p-4 overflow-auto">
        <p className="mb-4 text-lg">
          {t('pages.registration.If we fear misuse')}
        </p>
        <div className="flex justify-end">
          <Button
            onClick={() => setDialogIsOpen(false)}
            variant="secondary"
            className="px-16 text-lg"
            outline
          >
            {t('components.button.Back')}
          </Button>
        </div>
      </div>
    </Modal>
  )

  return (
    <>
      <div className="space-y-6">
        {errors.root?.message && (
          <div className="mb-10">
            <AlertMessage
              type="warning"
              message={errors.root.message}
              onClose={() => clearErrors('root')}
            />
          </div>
        )}
        {isSubmitSuccessful && !isDirty && (
          <div className="mb-10">
            <AlertMessage
              type="success"
              message={t('pages.profile.Profile successfully saved.')}
            />
          </div>
        )}
        <p className="text-center">
          {t(
            'pages.registration.These statements form the basis for your consultation with your doctor.'
          )}
        </p>
        <form className="space-y-6" onSubmit={onSubmit}>
          <div className="sm:flex sm:justify-center">
            <FormRadioGroup
              id={'sex'}
              label={t('pages.profile.Gender')}
              invisible
              buttons={[
                { value: ProfileSex.Female, title: t('pages.profile.Mrs.') },
                { value: ProfileSex.Male, title: t('pages.profile.Mr.') },
              ]}
              disabled={isSubmitting || isDisabled}
              register={register}
              options={{ required: true }}
            />
          </div>

          <FormInput
            id="firstname"
            size="lg"
            label={t('pages.profile.First name')}
            disabled={isSubmitting || isDisabled}
            type="text"
            register={register}
            options={{
              required: t('error.This field cannot be empty.'),
              pattern: {
                value: firstnameRegex,
                message: profileErrorToMessage(
                  ProfileError.InvalidFirstnameFormat,
                  t
                ),
              },
            }}
            errors={errors}
          />

          <FormInput
            id="lastname"
            size="lg"
            label={t('pages.profile.Last name')}
            disabled={isSubmitting || isDisabled}
            type="text"
            register={register}
            options={{
              required: t('error.This field cannot be empty.'),
              pattern: {
                value: lastnameRegex,
                message: profileErrorToMessage(
                  ProfileError.InvalidLastnameFormat,
                  t
                ),
              },
            }}
            errors={errors}
          />

          <FormInput
            id="birthdate"
            size="lg"
            label={t('pages.profile.Day of birth')}
            disabled={isSubmitting || isDisabled}
            type="date"
            register={register}
            options={{
              required: t('error.This field cannot be empty.'),
              pattern: {
                value: birthdateRegex,
                message: profileErrorToMessage(
                  ProfileError.InvalidBirthdateFormat,
                  t
                ),
              },
              validate: (date) => {
                return DateTime.fromISO(date) <
                  DateTime.now().minus(Duration.fromObject({ years: 18 }))
                  ? undefined
                  : profileErrorToMessage(
                      ProfileError.InvalidBirthdateFormat,
                      t
                    )
              },
            }}
            errors={errors}
          />

          <div>
            <p>{t('pages.registration.Protect your data!')}</p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Anchor onClick={() => setDialogIsOpen(true)}>
              {t('pages.registration.Why provide the mobile phone number?')}
            </Anchor>
          </div>

          <FormInput
            id="phone"
            size="lg"
            label={t('pages.profile.Phone')}
            info={t('error.profile.Including country code, e.g. +10123456789.')}
            disabled={isSubmitting}
            type="text"
            register={register}
            options={{
              required: t('error.This field cannot be empty.'),
              pattern: {
                value: phoneRegex,
                message: profileErrorToMessage(
                  ProfileError.InvalidPhoneFormat,
                  t
                ),
              },
            }}
            errors={errors}
          />

          {!isDisabled && (
            <div className="mt-5 flex space-x-3 justify-center">
              <Button disabled={!isDirty} type="submit" size="lg">
                {loading
                  ? t('components.button.Loading')
                  : t('pages.registration.Continue doctor talk')}
              </Button>
            </div>
          )}
        </form>
      </div>
      {dialog}
    </>
  )
}
