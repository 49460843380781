import classNames from 'classnames'
import markdownIt from 'markdown-it'
import './MdRenderer.scss'

interface Props {
  mdText: string
  styling?: boolean
  className?: string
}

const mdi = markdownIt('commonmark')

const defaultRender =
  mdi.renderer.rules.link_open ||
  function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options)
  }
mdi.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  const aIndex = tokens[idx].attrIndex('target')
  if (aIndex < 0) {
    tokens[idx].attrPush(['target', '_blank'])
  } else {
    tokens[idx].attrs[aIndex][1] = '_blank'
  }
  return defaultRender(tokens, idx, options, env, self)
}

export const MdRenderer: React.FC<Props> = ({
  mdText,
  styling = false,
  className,
}) => {
  return (
    <div
      className={classNames(
        'h-full',
        className,
        styling && 'report text-dro-blue space-y-6'
      )}
      dangerouslySetInnerHTML={{ __html: mdi.render(mdText) }}
    />
  )
}
