import { useQuery } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { AuthContext } from '../Contexts/AuthContext'
import {
  GetReportsDocument,
  GetReportsUpdatesDocument,
} from '../GraphQL/graphql'
import type {
  GetReportsQuery,
  GetReportsQueryVariables,
  GetReportsUpdatesSubscription,
  GetReportsUpdatesSubscriptionVariables,
} from '../GraphQL/graphql'

export const useReports = () => {
  const { userId } = useContext(AuthContext)

  const { data, loading, subscribeToMore } = useQuery<
    GetReportsQuery,
    GetReportsQueryVariables
  >(GetReportsDocument, {
    variables: { userId },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      GetReportsUpdatesSubscription,
      GetReportsUpdatesSubscriptionVariables
    >({
      document: GetReportsUpdatesDocument,
      variables: { userId },
      updateQuery: (prev, { subscriptionData: { data } }) => ({
        ...data,
        __typename: prev.__typename,
      }),
    })

    return () => unsubscribe()
  }, [subscribeToMore, userId])

  return useMemo(
    () => ({
      reports: data?.getReports?.reports ?? [],
      reportsLoading: loading,
      reportsError: data?.getReports?.error ?? undefined,
    }),
    [data, loading]
  )
}
