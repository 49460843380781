export const DE_DE = {
  company: {
    'company-ceo': 'Geschäftsführer: Markus Helfenstein',
    'company-city': 'Pullach im Isartal',
    'company-copyright': 'Copyright © 2010-2024 DocRobin',
    'company-email': 'info@docrobin.net',
    'company-legal-court': 'Amtsgericht München',
    'company-legal-location': 'Sitz: München',
    'company-legal-number': 'HRB 277443',
    'company-name': 'Medical Intelligence Lab GmbH',
    'company-phone': '+49 (0)7565-5054069',
    'company-street': 'Birkenallee 35',
    'company-zip': '82049',
    'company-bank-iban': 'DE 8744 0400 3703 2150 5000',
    'company-bank-holder': 'DocRobin',
  },
  components: {
    button: {
      Abort: 'Abbrechen',
      Back: 'Zurück',
      Continue: 'Fortsetzen',
      Delete: 'Löschen',
      Edit: 'Bearbeiten',
      Loading: 'Lädt…',
      Save: 'Speichern',
      Saving: 'Speichert…',
      Send: 'Senden',
      Sending: 'Sendet…',
    },
    footer: {
      Help: 'Hilfe',
      Imprint: 'Impressum',
      'Privacy Policy': 'Datenschutz',
      'Terms of Use': 'Allgemeinen Geschäftsbedingungen',
    },
    navigation: {
      Chat: 'Chat',
      Dialog: 'Dialog',
      Files: 'Dateien',
      Login: 'Anmelden',
      Logout: 'Abmelden',
      'Primary navigation': 'Hauptnavigation',
      Profile: 'Profil',
      Reports: 'Berichte',
      'Sign up': 'Registrieren',
    },
  },
  language: {
    de: 'Deutsch',
    en: 'Englisch',
  },
  error: {
    'An error has occurred. If this error occurs repeatedly, please get in touch with our support team.':
      'Ein Fehler ist aufgetreten. Sollte dieser Fehler wiederholt auftreten, wenden Sie sich bitte an unser Support-Team.',
    'An error has occurred. Please get in touch with our support team.':
      'Es ist ein Fehler aufgetreten. Bitte setzen Sie sich mit unserem Support-Team in Verbindung.',
    'An error has occurred. Please try to reload the page.':
      'Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.',
    'Invalid link': 'Link ungültig',
    auth: {
      'Could not find user.': 'Der Nutzer konnte nicht gefunden werden.',
      'Invalid Password.': 'Passwort fehlerhaft.',
      'Please contact our support team to unlock your account.':
        'Bitte kontaktieren Sie unseren Service, um Ihr Nutzerkonto freizuschalten.',
      'The activation link has expired or is invalid.':
        'Der Aktivierungslink ist abgelaufen oder ungültig.',
      'The Code has expired or is invalid.':
        'Der Code ist abgelaufen oder ungültig.',
      'The data is incorrect.': 'Die angegebenen Informationen sind ungültig.',
      'The E-Mail with the activation code could not be sent.':
        'Eine E-Mail mit dem Aktivierungs-Code konnte nicht gesendet werden.',
      'The email has not been updated, because it matches the old one.':
        'Die E-Mail wurde nicht aktualisiert, da sie die selbe wie die alte ist.',
      'The email must be confirmed with the link from the activation email.':
        'Die E-Mail muss mit dem Link der Aktivierungs-E-Mail freigeschaltet werden.',
      'The phone number must be confirmed.':
        'Die Telefonnummer muss bestätigt werden.',
      'The registration has failed.': 'Die Registrierung ist fehlgeschlagen.',
      'The user has been verified already.':
        'Der Nutzer wurde bereits freigeschaltet.',
      'This user exists already.': 'Dieser Nutzer existiert bereits.',
      'Wrong password. Typo?': 'Fehlerhaftes Passwort.',
    },
    'Could not load data':
      'Die angeforderten Informationen konnten nicht geladen werden',
    'Could not load user': 'Der Nutzer konnte nicht geladen werden',
    profile: {
      'Including country code, e.g. +10123456789.':
        'Inklusive Ländervorwahl, e.g. +49123456789.',
      'Passwords do not match': 'Die Passwörten stimmen nicht überein',
      'Please enter an email': 'Bitte geben Sie eine E-Mail Adresse an',
      'The postal code is not valid': 'Die Postleitzahl ist nicht gültig',
      'This country is not yet supported':
        'Dieses Land wird noch nicht unterstützt',
      'Wrong format.': 'Falsches Format.',
      'You must be at least 18 years old.':
        'Sie müssen mindestens 18 Jahre alt sein.',
    },
    'Saving not possible. If this error occurs repeatedly, please contact our support.':
      'Speichern nicht möglich. Sollte dieser Fehler wiederholt auftreten, wenden Sie sich bitte an unseren Support.',
    'This field cannot be empty.': 'Dieses Feld darf nicht leer sein.',
  },
  link: {
    'company-help-page': 'https://docrobin.net/hilfe/',
    'company-imprint-page': 'https://docrobin.net/impressum/',
    'company-privacy-policy': 'https://docrobin.net/datenschutzerklaerung/',
    'company-terms-of-use':
      'https://docrobin.net/allgemeine-geschaeftsbedingungen-agb/',
    'company-website': 'https://docrobin.net',
    'research-lmu-help':
      'https://lmu-assets.eu-central-1.linodeobjects.com/hilfe.pdf',
    'research-lmu-patient-information':
      'https://lmu-assets.eu-central-1.linodeobjects.com/patienteninformationsschrift.pdf',
  },
  pages: {
    conversation: {
      'An open order has been found, you will be forwarded':
        'Eine offene Bestellung wurde gefunden, Sie werden weitergeleitet',
      'Change answer': 'Antwort ändern',
      'Change last answer': 'Letzte Antwort ändern',
      'Confirm selection': 'Auswahl bestätigen',
      'Conversation history': 'Gesprächsverlauf',
      'End conversation': 'Unterhaltung beendet',
      'Go to overview': 'Zur Übersicht',
      Help: 'Help',
      'Help and Answers': 'Hilfe und Antworten',
      'Load previous state (login)': 'Früheren Stand laden (anmelden)',
      'No conversation history found': 'Kein Gesprächsverlauf gefunden',
      Options: 'Optionen',
      'Patient Information and Consent Form':
        'Patienteninformationsschrift und Einwilligungserklärung',
      'Repeat video': 'Video wiederholen',
      'Save current state (register and transfer)':
        'Aktuellen Stand speichern (registrieren und übertragen)',
      'Save current state (register)':
        'Aktuellen Stand speichern (registrieren)',
      'Show current anamnesis': 'Aktuelle Anamnese anzeigen',
      'Start over': 'Neu beginnen',
      'The session has expired': 'Die Sitzung ist abgelaufen',
    },
    files: {
      'Continue doctor talk': 'Arztgespräch fortsetzen',
      'Currently, we are only evaluating knee X-rays. Please upload only X-ray images as shown in the sample images. Others will not be evaluated and will be deleted.':
        'Derzeit werten wir nur Knie-Röntgenbilder aus. Bitte laden Sie nur Röntgenbilder hoch, die in den Beispielbildern gezeigt werden. Andere werden nicht ausgewertet und gelöscht.',
      'Date of study': 'Aufnahmedatum',
      'Drag’n’drop DICOM files or click to select':
        'DICOM-Dateien hier hinziehen oder klicken, um Dateien auszuwählen',
      'Drop files here': 'Dateien hier loslassen',
      'Image Series': 'Röntgenbildserien',
      'No files have been uploaded yet':
        'Es wurden noch keine Dateien hochgeladen',
      'Upload count image series': '{{count}} Röntgenbildserien hochladen',
      'Upload new x-ray images': 'Neue Röntgenbilder hochladen',
      'Warning! Only x-ray images will be uploaded!':
        'Warnung! Es werden nur Röntgenbilder hochgeladen!',
      'X-ray images': 'Röntgenbilder',
    },
    login: {
      'Anamnesis Report': 'Anamnese-Bericht',
      'E-Mail': 'E-Mail',
      'E-Mail Token': 'E-Mail Code',
      Login: 'Anmelden',
      'Not registered yet?': 'Noch nicht registriert?',
      Password: 'Passwort',
      'Password reset instructions has been sent to email.':
        'Eine Anleitung zum Zurücksetzen des Passworts wurde an {{email}} verschickt.',
      'Resend E-Mail confirmation': 'Bestätigungs-Mail erneut senden',
      'Reset password': 'Passwort zurücksetzen',
      'Send E-Mail confirmation': 'Bestätigungs-E-Mail senden',
      'Sending E-Mail': 'E-Mail wird gesendet…',
      'Sign up': 'Registrieren',
      'SMS Token': 'SMS Code',
      'Start conversation': 'Unterhaltung starten',
      'Transfer chat history and overwrite the one saved in your profile.':
        'Aktuellen Chatverlauf übertragen und den im Profil gespeicherten überschreiben.',
      'Welcome to DocRobin. You will be redirected':
        'Willkommen bei DocRobin. Sie werden weitergeleitet…',
    },
    password: {
      'Change password of user email.':
        'Das Password des Benutzers {{email}} wird geändert.',
      'Reset password': 'Passwort zurücksetzen',
    },
    payment: {
      'Right of Cancellation': 'Widerrufsrecht',
      'Your report is now being processed. Please pay for your order now.':
        'Ihr Gutachten ist nun in Bearbeitung. Bitte zahlen Sie jetzt Ihren Auftrag.',
      'I am aware that my right of withdrawal expires upon complete fulfillment of the contract by the expert.':
        'Mit ist bekannt, dass mein Widerrufsrecht mit vollständiger Vertragserfüllung durch den Experten erlischt.',
      'Go to reports': 'Zu den Berichten',
      Order: 'Bestellung',
      'Order amount': 'Auftragssumme',
      'Total amount amount. How would you like to pay?':
        'Gesamtbetrag: {{amount}}. Wie möchten Sie zahlen?',
      'Order with costs': 'Kostenpflichtig bestellen',
      'Expert Assessment Contract': 'Begutachtungsvertrag',
      'The information I provide when using this portal may be used for the purposes stated therein in compliance with all data protection regulations. (optional)':
        'Meine bei Nutzung dieses Portals gemachten Angaben dürfen zu den darin genannten Zwecken unter Einhaltung aller datenschutzrechtlicher Bestimmungen weiter verwendet werden. (optional)',
      'The DocRobin service may contact me at regular intervals to request information about my further course of treatment and my state of health after completion of the second opinion for the above-mentioned purposes in compliance with all data protection regulations. (optional)':
        'Der Service von DocRobin darf mich in regelmäßigen Abständen kontaktieren, um meine Informationen über meinen weiteren Behandlungsverlauf sowie meinen Gesundheitszustand nach Abschluss des Zweitgutachtens zu den oben genannten Zwecken unter Einhaltung aller datenschutzrechtlicher Bestimmungen abzufragen. (optional)',
      IBAN: 'IBAN',
      Amount: 'Betrag',
      'Account holder': 'Kontoinhaber',
      'Transfer purpose': 'Verwendungszweck',
      'I agree to the exchange of data between DocRobin and the medical expert in accordance with this declaration of consent. (necessary)':
        'Mit dem Datenaustausch zwischen DocRobin und dem Zweitgutachter gemäß dieser Einwilligungserklärung bin ich einverstanden. (notwendig)',
      Overview: 'Übersicht',
      'Pay later or by bank transfer': 'Später oder per Überweisung bezahlen',
      'Pay now': 'Jetzt bezahlen',
      'Payment cancelled': 'Zahlung annulliert',
      'Payment failed!': 'Zahlung fehlgeschlagen!',
      'Payment processing': 'Zahlungsabwicklung…',
      'Payment successful': 'Zahlung erfolgreich',
      'We will clarify the question for you To what extent does the indication for knee replacement surgery I have correspond to the national guideline? based solely on the information you have provided here.':
        'Wir klären für Sie die Fragestellung „Inwieweit entspricht die mir vorliegende Indikation zur Knie-TEP der nationalen Leitlinie?“ unter ausschließlicher Berücksichtigung der von Ihnen hier gemachten Angaben.',
      'The order total includes vat VAT.':
        'In der Auftragssumme sind {{vat}} USt enthalten.',
      'My assignment is based on the following appraisal contract with right of revocation':
        'Folgender Begutachtungsvertrag mit Widerrufsrecht liegt meiner Beauftragung zugrunde:',
      'I agree to the assessment contract. (necessary)':
        'Dem Begutachtungsvertrag stimme ich zu. (notwendig)',
      'I agree to the right of withdrawal. (necessary)':
        'Dem Widerrufsrecht stimme ich zu. (notwendig)',
      'Please select the language in which you would like to receive the report':
        'Bitte wählen Sie die Sprache, in der Sie den Bericht erhalten möchten',
      'You can also pay by bank transfer. Transfer the order amount with the intended purpose to the following account.':
        'Sie können auch per Banküberweisung bezahlen. Überweisen Sie den Bestellbetrag mit dem Verwendungszweck auf das folgende Konto.',
      'The data donation is based on the following declaration':
        'Folgender Erklärung liegt die Datenspende zugrunde',
      'Declaration of consent under data protection law':
        'Datenschutzrechtlichen Einwilligungserklärung',
    },

    profile: {
      Abort: 'Abbrechen',
      'Back to Profile': 'Zurück zum Profil',
      'Change Mail': 'E-Mail ändern',
      'Confirm Phone': 'Handynummer bestätigen',
      'Current password': 'Aktuelles Passwort',
      'Day of birth': 'Geburtsdatum',
      'E-Mail': 'E-Mail',
      'E-mail successfully saved. You have been sent a confirmation link to {{email}} that you can use to activate it.':
        'E-Mail erfolgreich gespeichert. Ihnen wurde ein Bestätigungslink an {{email}} gesendet, über den Sie die Adresse freischalten können.',
      'First name': 'Vorname',
      Gender: 'Geschlecht',
      'Last name': 'Nachname',
      'Mr.': 'Herr',
      'Mrs.': 'Frau',
      'New password': 'Neues Passwort',
      'Passord successfully changed': 'Passwort erfolgreich geändert',
      Password: 'Password',
      'Password successfully saved.': 'Passwort erfolgreich gespeichert.',
      Person: 'Person',
      'Personal Information': 'Persönliche Informationen',
      Phone: 'Handynummer',
      'Phone successfully saved. You have been sent a code to phone that you can use to activate it.':
        'Handynummer erfolgreich gespeichert. Ihnen wurde ein Code an {{phone}} gesendet, die Nummer freizuschalten.',
      Profile: 'Profil',
      'Profile successfully saved.': 'Profile erfolgreich gespeichert.',
      'Repeat password': 'Passwort wiederholen',
      'Save Information': 'Informationen speichern',
      'Save Mail': 'E-Mail speichern',
      'Save Password': 'Passwort speichern',
      'Save Phone': 'Handynummer speichern',
      'Save Profile': 'Profil speichern',
      'The email has been verified': 'Diese E-Mail wurde verifiziert',
      Title: 'Titel',
    },
    registration: {
      'About the person': 'Zur Person',
      'Already signed up?': 'Schon registriert?',
      'No e-mail received? Please check your spam folder or correct your e-mail address.':
        'Keine E-Mail erhalten? Bitte prüfen Sie Ihren Spam-Ordner oder korrigieren Sie Ihre E-Mail-Adresse.',
      'Continue doctor talk': 'Arztgespräch fortsetzen',
      'I agree to the terms of use and privacy policy.':
        'Den <lnk_terms>Allgemeinen Geschäftsbedingungen (AGB)</lnk_terms> stimme ich zu.',
      'I am aware of my right of withdrawal and I agree to it.':
        'Mein <lnk_privacy>Widerrufsrecht</lnk_privacy> ist mir bekannt und ich bin damit einverstanden.',
      'Please verify your E-Mail using the sent link':
        'Bitte verifizieren Sie Ihre E-Mail über den gesendeten Link',
      'Protect your data!': 'Schützen Sie Ihre Daten!',
      'Sign up': 'Registrieren',
      'Sign up for free': 'Kostenlos registrieren',
      'These statements form the basis for your consultation with your doctor.':
        'Diese Angaben bilden die Grundlage für Ihr Arztgespräch.',
      'Verify email': 'E-Mail verifizieren',
      'We have sent an activation link to':
        'Ihnen wurde ein Bestätigungslink gesendet an:',
      'Why provide the mobile phone number?': 'Warum die Handynummer angeben?',
      'If we fear misuse':
        'Wenn wir Missbrauch befürchten, senden Ihnen eine SMS an Ihre Handynummer, um Sie beim Login sicher zu identifizieren und so Ihre Daten zu schützen. Mit dieser Kombination von Passwort und SMS folgen wir der Empfehlung für 2-Faktor-Authentisierung des Bundesamts für Sicherheit in der Informationstechnik (BSI)',
    },
    reports: {
      'Assessment pending. As soon as your report has been issued, you will receive an e-mail.':
        'Befundung steht noch aus. Sobald Ihr Bericht erstellt wurde, erhalten Sie eine E-Mail.',
      'Payment outstanding. You will receive access to the report as soon as we have received your payment.':
        'Ausstehende Zahlung. Sie erhalten Zugriff auf den Bericht, sobald wir Ihre Zahlung erhalten haben.',
      'Anamnesis Report': 'Anamnese-Bericht',
      'Cancel order': 'Bestellung stornieren',
      'Cancellation date': 'Annullierung Datum',
      Cancelled: 'Storniert',
      'Commissioning date': 'Beauftragungsdatum',
      Completed: 'Abgeschlossen',
      'Could not load report.': 'Der Bericht konnte nicht geladen werden.',
      'Created at': 'Erstellt am',
      'Download invoice': 'Rechnung herunterladen',
      'E-Mail': 'E-Mail',
      'Go to conversation history': 'Zum Gesprächsverlauf',
      'Medical reports': 'Arztberichte',
      'No reports available': 'Keine Berichte verfügbar',
      Orders: 'Bestellungen',
      'Orthopedic X-ray report': 'Orthopädische Röntgenbeurteilung',
      'Paid at': 'Bezahldatum',
      Patient: 'Patient',
      'Pay now': 'Jetzt bezahlen',
      Phone: 'Handynummer',
      Print: 'Drucken',
      'Release date': 'Freigabedatum',
      Report: 'Bericht',
      'Report not released yet': 'Bericht noch nicht freigegeben',
      'Request date': 'Anforderungsdatum',
      Reviewer: 'Begutachter:in',
      'Second opinion illness': 'Zweitgutachten {{illness}}',
      'Share report for five minutes': 'Bericht für fünf Minuten teilen',
      'Show anamnesis': 'Anamnese anzeigen',
      'Show anamnesis of current conversation':
        'Anamnese des aktuellen Gesprächs anzeigen',
      'Show expert opinion': 'Gutachten anzeigen',
      'Stop sharing': 'Teilen beenden',
      'The report is currently being shared': 'Der Bericht wird gerade geteilt',
      'Time remaining': 'Verbleibende Zeit',
      Website: 'Website',
    },
  },
}
