import type { FC } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { BaseReportFragment } from '../GraphQL/graphql'
import { CountryCode, InvoiceState } from '../GraphQL/graphql'
import { getReportTitle } from '../Helper/ReportHelper'
import { dateToString } from '../Helper/TextHelper'
import { Button } from './Button'

import { Card } from './Card/Card'
import { DownloadInvoiceButton } from './DownloadInvoiceButton/DownloadInvoiceButton'

interface ReportCardProps {
  report: BaseReportFragment
}

export const ReportCard: FC<ReportCardProps> = ({ report }) => {
  const { t } = useTranslation()

  const isPaid = useMemo(() => {
    return report.orders.some(
      ({
        summary: {
          patient: { state },
        },
      }) => state === InvoiceState.Paid || state === InvoiceState.PartiallyPaid
    )
  }, [report.orders])

  const isVoided = useMemo(() => {
    return report.orders.some(
      ({
        summary: {
          patient: { state },
        },
      }) => state === InvoiceState.Void || state === InvoiceState.PartiallyVoid
    )
  }, [report.orders])

  return (
    <Card
      banner={
        !report.released
          ? t(
              'pages.reports.Assessment pending. As soon as your report has been issued, you will receive an e-mail.'
            )
          : report.released && !isPaid
          ? t(
              'pages.reports.Payment outstanding. You will receive access to the report as soon as we have received your payment.'
            )
          : undefined
      }
      title={getReportTitle(t, report)}
    >
      <div className="flex flex-col w-full space-y-6">
        <div>
          {(report.releaseTimestamp ||
            report.requestTimestamp ||
            report.createdTimestamp) && (
            <div className="font-medium w-full">
              <div className="flex">
                <p className="truncate flex-auto flex-grow">
                  {report.releaseTimestamp
                    ? t('pages.reports.Release date')
                    : report.requestTimestamp
                    ? t('pages.reports.Request date')
                    : report.createdTimestamp
                    ? t('pages.reports.Commissioning date')
                    : ''}
                  :
                </p>
                <p className="text-right whitespace-nowrap flex-none">
                  {dateToString(
                    report.releaseTimestamp ??
                      report.requestTimestamp ??
                      report.createdTimestamp,
                    CountryCode.De
                  )}
                </p>
              </div>
              {report.orders[0].patientInvoices[0].paidAt && (
                <div className="flex">
                  <p className="truncate flex-auto flex-grow">
                    {t('pages.reports.Paid at')}:
                  </p>
                  <p className="text-right whitespace-nowrap flex-none">
                    {dateToString(
                      report.orders[0].patientInvoices[0].paidAt,
                      CountryCode.De
                    )}
                  </p>
                </div>
              )}
              {report.orders[0].patientInvoices[0].voidAt && (
                <div className="flex">
                  <p className="truncate flex-auto flex-grow">
                    {t('pages.reports.Cancellation date')}:
                  </p>
                  <p className="text-right whitespace-nowrap flex-none">
                    {dateToString(
                      report.orders[0].patientInvoices[0].voidAt,
                      CountryCode.De
                    )}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        {report.released && isPaid && (
          <Button to={`/reports/${report.id}`} size="lg">
            {t('pages.reports.Show expert opinion')}{' '}
          </Button>
        )}
        {
          <>
            {report.conversationSession && !isVoided && (
              <Button
                variant="secondary"
                outline
                className="bg-white bg-opacity-100"
                to={`/anamnesis/${report.conversationSession.id}`}
              >
                {t('pages.reports.Show anamnesis')}{' '}
              </Button>
            )}
            <Button
              variant="secondary"
              outline
              to={`/chat/archive/${report.id}`}
              className="bg-white bg-opacity-100"
            >
              {t('pages.reports.Go to conversation history')}{' '}
              {/* <ChatIcon className="inline" /> */}
            </Button>

            {report.orders.length === 1 && (
              <>
                {[InvoiceState.Created, InvoiceState.PartiallyPaid].includes(
                  report.orders[0].summary.patient.state
                ) && (
                  <Button to={`/orders/${report.orders[0].id}/payment`}>
                    {t('pages.payment.Pay now')}
                  </Button>
                )}
                {report.released && (
                  <DownloadInvoiceButton orderId={report.orders[0].id}>
                    {t('pages.reports.Download invoice')}
                  </DownloadInvoiceButton>
                )}
              </>
            )}

            {report.orders.length > 1 && (
              <Button to={`/reports/${report.id}/orders`}>
                {t('pages.reports.Orders')}
              </Button>
            )}
          </>
        }
      </div>
    </Card>
  )
}
