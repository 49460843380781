import classNames from 'classnames'
import { isString } from 'lodash'
import type { FC } from 'react'
import * as ReactModal from 'react-modal'

const RModal = ReactModal.default

RModal.setAppElement(document.body)

type ModalProps = ReactModal.Props & {
  size?: 'sm' | 'md'
}

export const Modal: FC<ModalProps> = ({
  children,
  className,
  size = 'sm',
  ...rest
}) => {
  return (
    <RModal
      className={
        'flex bg-gray-500 bg-opacity-75 z-10 w-screen h-screen items-center justify-center '
      }
      preventScroll={true}
      htmlOpenClassName={'overflow-hidden'}
      {...rest}
    >
      <div
        className={classNames(
          'rounded-lg bg-white shadow-xl',
          size === 'sm' && 'sm:my-8 sm:w-full sm:max-w-lg',
          isString(className) && className
        )}
      >
        {children}
      </div>
    </RModal>
  )
}
