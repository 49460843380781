import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MdRenderer } from '../../Components/MdRenderer/MdRenderer'
import { ReportLayout } from '../../Components/ReportLayout/ReportLayout'
import { Shell } from '../../Components/Shell/Shell'
import { useAnamnesis } from '../../Hooks/useAnamnesis'
import { useProfile } from '../../Hooks/useProfile'
import { Alert } from '../../Modules/Alert/Alert'
import { Loading } from '../../Modules/Loading/Loading'

type AnamnesisParams = {
  sessionId?: string
}

export const AnamnesisPage: React.FC = () => {
  const { t } = useTranslation()
  const { sessionId } = useParams<AnamnesisParams>()
  const { anamnesis, anamnesisLoading, anamnesisError } =
    useAnamnesis(sessionId)
  const { profile, profileLoading } = useProfile()

  if (anamnesisLoading || profileLoading) {
    return (
      <Shell preGeneratedCircles={[]} autogeneratedCircles={0}>
        <Loading />
      </Shell>
    )
  }

  if (anamnesisError || !anamnesis) {
    return (
      <Shell preGeneratedCircles={[]} autogeneratedCircles={2}>
        <Alert message={t('error.Could not load data')} />
      </Shell>
    )
  }

  return (
    <Shell preGeneratedCircles={[]} autogeneratedCircles={2}>
      <ReportLayout title={t('pages.reports.Anamnesis Report')} user={profile}>
        <MdRenderer mdText={anamnesis} styling />
      </ReportLayout>
    </Shell>
  )
}
