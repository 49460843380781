import { useMutation } from '@apollo/client'
import type { FC } from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Link } from '../../Components/Link/Link'
import { Shell } from '../../Components/Shell/Shell'
import { Spinner } from '../../Components/Spinner/Spinner'
import { AuthContext } from '../../Contexts/AuthContext'
import type {
  VerifyUserEmailMutation,
  VerifyUserEmailMutationVariables,
} from '../../GraphQL/graphql'
import { VerifyUserEmailDocument } from '../../GraphQL/graphql'
import { authErrorToMessage } from '../../Helper/AuthHelper'
import { useGtm } from '../../Hooks/useGtm'
import { useProfile } from '../../Hooks/useProfile'
import { Alert } from '../../Modules/Alert/Alert'

type EmailVerificationParams = {
  userId: string
  token: string
}

export const EmailVerification: FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const back = searchParams.get('next') || null
  const { trackSuccessfulRegistration } = useGtm()

  const { updateAuth } = useContext(AuthContext)

  const { userId, token } = useParams<EmailVerificationParams>()
  const navigate = useNavigate()
  const { profile, profileLoading } = useProfile()

  const [errorMessage, setErrorMessage] = useState('')

  const [verifyUserEmail, { data, loading }] = useMutation<
    VerifyUserEmailMutation,
    VerifyUserEmailMutationVariables
  >(VerifyUserEmailDocument, {
    fetchPolicy: 'network-only',
    async onCompleted(data) {
      if (data.verifyUserEmail.error) {
        setErrorMessage(authErrorToMessage(data.verifyUserEmail.error, t))
      }
      if (data.verifyUserEmail.accessToken) {
        updateAuth(data.verifyUserEmail.accessToken)
      }
    },
  })

  const isEffectRunning = useRef<boolean>(false)
  useEffect(() => {
    if (isEffectRunning.current) return

    isEffectRunning.current = true
    if (token && userId) {
      verifyUserEmail({ variables: { userId, token } }).then(() => {
        isEffectRunning.current = false
      })
    }
  }, [token, userId, verifyUserEmail])

  useEffect(() => {
    if (!userId || profile?.id !== userId || !profile.isEmailVerified) return
    const redirectUser = async () => {
      if (!profile?.isComplete && profile?.isEmailVerified) {
        await trackSuccessfulRegistration()
        navigate(`/register${back ? `?next=${back}` : ''}`)
      } else if (back) {
        navigate(back)
      } else {
        navigate('/profile')
      }
    }

    redirectUser()
  }, [
    loading,
    profileLoading,
    back,
    navigate,
    profile,
    userId,
    trackSuccessfulRegistration,
  ])

  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]}>
      <div className="flex flex-col space-y-6 justify-center items-center">
        {loading || profileLoading ? (
          <Spinner />
        ) : errorMessage ? (
          <>{errorMessage}</>
        ) : !data ? (
          <Alert
            message={t(
              'error.An error has occurred. Please get in touch with our support team.'
            )}
          />
        ) : (
          <>
            {t('pages.profile.The email has been verified')}
            <p>
              {!profile?.isComplete && profile?.isEmailVerified ? (
                <Link to={`/register${back ? `?next=${back}` : ''}`}>
                  {t('components.button.Continue')}
                </Link>
              ) : (
                <Link to="/profile">{t('pages.profile.Back to Profile')}</Link>
              )}
            </p>
          </>
        )}
      </div>
    </Shell>
  )
}
