import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ReportContent } from '../../Components/ReportContent/ReportContent'
import { ReportLayout } from '../../Components/ReportLayout/ReportLayout'
import { Shell } from '../../Components/Shell/Shell'
import type { GetReportBySecretQuery } from '../../GraphQL/graphql'
import { GetReportBySecretDocument } from '../../GraphQL/graphql'
import { getReportTitle } from '../../Helper/ReportHelper'

export const ReportSharePage: React.FC = () => {
  const { t } = useTranslation()
  const params = useParams()
  const secret = params.secret
  const { data, loading } = useQuery<GetReportBySecretQuery>(
    GetReportBySecretDocument,
    { fetchPolicy: 'cache-and-network', variables: { secret: secret } }
  )
  // const contentRef = useRef(null)

  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]}>
      {!loading && data?.getReport.report && !data?.getReport.error && (
        <ReportLayout
          title={getReportTitle(t, data.getReport.report)}
          date={data?.getReport.report.releaseTimestamp}
        >
          <ReportContent report={data.getReport.report} />
        </ReportLayout>
      )}
    </Shell>
  )
}
