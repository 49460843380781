import Cookies from 'js-cookie'

// Use wordpress cookie, something like "de"
export const LANGUAGE_COOKIE_NAME = 'pll_language'
export const DEFAULT_LANGUAGE_CODE = 'de'

export const setLanguageCode = (code: string) => {
  if (code === '') {
    Cookies.remove(LANGUAGE_COOKIE_NAME)
  } else {
    Cookies.set(LANGUAGE_COOKIE_NAME, code, {
      expires: 360,
      sameSite: 'None',
    })
  }
}

export const getLanguageCode = () => {
  try {
    return Cookies.get(LANGUAGE_COOKIE_NAME) || DEFAULT_LANGUAGE_CODE
  } catch {
    // TODO: Why is this block empty?
  }
  return DEFAULT_LANGUAGE_CODE
}
