import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  CreateOrderMutation,
  CreateOrderMutationVariables,
  GetProductSetForSessionQuery,
  GetProductSetForSessionQueryVariables,
  GetProductSetForSessionUpdatesSubscription,
  GetProductSetForSessionUpdatesSubscriptionVariables,
  UnfreezeSessionMutation,
  UnfreezeSessionMutationVariables,
} from '../GraphQL/graphql'
import {
  CreateOrderDocument,
  GetProductSetForSessionDocument,
  GetProductSetForSessionUpdatesDocument,
  UnfreezeSessionDocument,
} from '../GraphQL/graphql'

export const useProductSetForSession = (sessionId?: string) => {
  const [loadProductSet, { data, loading, subscribeToMore }] = useLazyQuery<
    GetProductSetForSessionQuery,
    GetProductSetForSessionQueryVariables
  >(GetProductSetForSessionDocument)

  useEffect(() => {
    sessionId && loadProductSet({ variables: { sessionId } })
  }, [loadProductSet, sessionId])

  useEffect(() => {
    if (!sessionId) return
    const unsubscribe = subscribeToMore<
      GetProductSetForSessionUpdatesSubscription,
      GetProductSetForSessionUpdatesSubscriptionVariables
    >({
      document: GetProductSetForSessionUpdatesDocument,
      variables: { sessionId },
      updateQuery: (prev, { subscriptionData: { data } }) => ({
        ...prev,
        ...data,
        __typename: prev.__typename,
      }),
    })
    return () => unsubscribe()
  }, [sessionId, subscribeToMore])

  const unfreezeSession = useMutation<
    UnfreezeSessionMutation,
    UnfreezeSessionMutationVariables
  >(UnfreezeSessionDocument)

  const createOrder = useMutation<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >(CreateOrderDocument)

  const mutations = useMemo(
    () => ({
      unfreezeSession,
      createOrder,
    }),
    [unfreezeSession, createOrder]
  )

  return useMemo(
    () => ({
      session: sessionId
        ? data?.getProductSetForSession.session
        : undefined ?? undefined,
      productSet: sessionId
        ? data?.getProductSetForSession.productsSet
        : undefined ?? undefined,
      error: sessionId
        ? data?.getProductSetForSession.error
        : undefined ?? undefined,
      loading: !sessionId ? false : loading,
      mutations,
    }),
    [data, loading, sessionId, mutations]
  )
}
