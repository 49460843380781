export const useGtm = () => {
  const trackEvent =
    (event: string) =>
    (additionalData: object = {}) => {
      if (!window.dataLayer) {
        return
      }

      window.dataLayer.push({
        event,
        ...additionalData,
      })

      // to prevent the event from being declined because of the wordpress redirect
      return new Promise((resolve) => setTimeout(resolve, 500))
    }

  return {
    trackOrderEvent: trackEvent('order_placed'),
    trackSuccessfulRegistration: trackEvent('new_registration'),
    trackStartConversation: trackEvent('start_conversation'),
  }
}
