import type { FC } from 'react'
import type { NavLinkProps } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

export const HeaderLink: FC<NavLinkProps> = ({ children, ...props }) => {
  return (
    <NavLink
      className="max-[700px]:hidden float-left block text-center py-3.5 px-4 no-underline text-lg hover:bg-[#ffffff] hover:text-[#438f04] hover:h-full
      max-[600px]:hidden max-[600px]:first:block max-[600px]:float-none max-[600px]:block max-[600px]:text-left"
      {...props}
    >
      {children}
    </NavLink>
  )
}
