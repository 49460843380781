import { useQuery } from '@apollo/client'
import { useEffect, useMemo, useRef } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import type {
  GetOrderPaymentIntentQuery,
  GetOrderPaymentIntentQueryVariables,
} from '../GraphQL/graphql'
import { GetOrderPaymentIntentDocument } from '../GraphQL/graphql'

export const usePaymentResult = () => {
  const { orderId } = useParams()
  const [searchParams] = useSearchParams()
  const clientSecret = searchParams.get('payment_intent')

  const { data, loading, error, refetch } = useQuery<
    GetOrderPaymentIntentQuery,
    GetOrderPaymentIntentQueryVariables
  >(GetOrderPaymentIntentDocument, {
    variables: {
      orderId: orderId ?? '',
      clientSecret: clientSecret ?? '',
    },
  })

  const intervalRef = useRef<number>()
  useEffect(() => {
    if (
      data?.getOrderPaymentIntent.paymentIntent?.status !== 'succeeded' &&
      data?.getOrderPaymentIntent.paymentIntent?.status !== 'canceled' &&
      !error &&
      !data?.getOrderPaymentIntent.error &&
      !intervalRef.current
    ) {
      intervalRef.current = window.setInterval(() => {
        refetch()
      }, 5000)
    }

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [data, error])

  return useMemo(
    () => ({
      data,
      loading,
      error,
    }),
    [data, loading, error]
  )
}
