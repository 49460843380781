import type { FC } from 'react'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AlertMessage } from '../../Components/AlertMessage/AlertMessage'
import { Button } from '../../Components/Button'
import { Checkbox } from '../../Components/Checkbox/Checkbox'
import { FormInput } from '../../Components/FormInput/FormInput'
import { Heading } from '../../Components/Heading/Heading'
import { Link } from '../../Components/Link/Link'
import { Shell } from '../../Components/Shell/Shell'
import { AuthContext } from '../../Contexts/AuthContext'

import type { SendSecondAuthFactorMutationVariables } from '../../GraphQL/graphql'
import {
  AuthError,
  type LoginUserMutationVariables,
  type SendPasswordResetMutationVariables,
} from '../../GraphQL/graphql'
import { authErrorToMessage, factorRegex } from '../../Helper/AuthHelper'
import { useProfile } from '../../Hooks/useProfile'

enum LoginState {
  EnterEmailPassword,
  VerifyFactor,
  ResendFactor,
  ResetPassword,
}

export const Login: FC = () => {
  const { t } = useTranslation()
  const { updateAuth } = useContext(AuthContext)

  const [state, setState] = useState<LoginState>(LoginState.EnterEmailPassword)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const nextUrl = searchParams.get('next')
  const transfer = searchParams.has('transfer')

  const [passwordResetMail, setPasswordResetMail] = useState('')

  const { mutations } = useProfile()
  const [loginUser] = mutations.loginUser
  const [sendPasswordReset] = mutations.sendPasswordReset
  const [sendSecondAuthFactor] = mutations.sendSecondAuthFactor

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    setFocus,
    reset,
    formState: { errors, isDirty, isSubmitting },
    watch,
    setValue,
  } = useForm<
    LoginUserMutationVariables &
      SendSecondAuthFactorMutationVariables &
      SendPasswordResetMutationVariables
  >({
    defaultValues: {
      email: undefined,
      password: undefined,
      transferSession: transfer ?? false,
      factor: undefined,
    },
  })

  const transferSession = watch('transferSession')

  useEffect(() => {
    if (state === LoginState.VerifyFactor) {
      setFocus('factor')
    }
  }, [state, setFocus])

  const onSubmit = handleSubmit(
    async ({ email, password, transferSession, factor }) => {
      if (
        state === LoginState.EnterEmailPassword ||
        state === LoginState.ResendFactor
      ) {
        await sendSecondAuthFactor({
          variables: { email, password },
          onCompleted: ({ sendSecondAuthFactor }) => {
            if (sendSecondAuthFactor.error) {
              setError('root', {
                message: authErrorToMessage(sendSecondAuthFactor.error, t),
              })
              setState(LoginState.ResetPassword)
            } else if (sendSecondAuthFactor.success) {
              setState(LoginState.VerifyFactor)
            }
          },
          onError: () =>
            setError('root', {
              message: t(
                'error.An error has occurred. If this error occurs repeatedly, please get in touch with our support team.'
              ),
            }),
        })
      } else if (state === LoginState.ResetPassword) {
        await sendPasswordReset({
          variables: { email },
          onCompleted: ({ sendPasswordReset }) => {
            if (sendPasswordReset) {
              setPasswordResetMail(email)
              setState(LoginState.EnterEmailPassword)
            } else {
              setError('root', {
                message: t(
                  'error.An error has occurred. If this error occurs repeatedly, please get in touch with our support team.'
                ),
              })
            }
          },
          onError: () =>
            setError('root', {
              message: t(
                'error.An error has occurred. If this error occurs repeatedly, please get in touch with our support team.'
              ),
            }),
        })
      } else if (state === LoginState.VerifyFactor) {
        await loginUser({
          variables: { email, password, factor, transferSession },
          onCompleted: async ({ loginUser }) => {
            if (loginUser.error) {
              setError('root', {
                message: authErrorToMessage(loginUser.error, t),
              })
            } else if (loginUser.accessToken) {
              await updateAuth(loginUser.accessToken)
              navigate(nextUrl ?? '/chat')
            }
          },
          onError: () =>
            setError('root', {
              message: t(
                'error.An error has occurred. If this error occurs repeatedly, please get in touch with our support team.'
              ),
            }),
        })
      }
    }
  )

  return (
    <Shell autogeneratedCircles={2} preGeneratedCircles={[]}>
      <div className="max-w-md mx-auto">
        <Heading variant="h1" className="text-center">
          {t('pages.login.Login')}
        </Heading>
        <div className="flex justify-center space-x-4 mt-5 mb-6">
          <p>{t('pages.login.Not registered yet?')}</p>
          <Link to={`/register?${searchParams}`}>
            {t('pages.login.Sign up')}
          </Link>
        </div>
        {passwordResetMail && (
          <div className="mb-10">
            <AlertMessage
              type="success"
              message={t(
                'pages.login.Password reset instructions has been sent to email.',
                { email: passwordResetMail }
              )}
              onClose={() => setPasswordResetMail('')}
            />
          </div>
        )}
        {errors.root?.message && (
          <div className="mb-10">
            <AlertMessage
              type="warning"
              message={errors.root.message}
              onClose={() => clearErrors('root')}
            />
          </div>
        )}
        <form className="flex flex-col space-y-6" onSubmit={onSubmit}>
          <FormInput
            id="email"
            size="lg"
            label={t('pages.login.E-Mail')}
            disabled={isSubmitting || state !== LoginState.EnterEmailPassword}
            type="text"
            register={register}
            options={{
              required: t('error.This field cannot be empty.'),
            }}
            errors={errors}
          />

          <FormInput
            id="password"
            size="lg"
            type="password"
            label={t('pages.login.Password')}
            disabled={isSubmitting || state !== LoginState.EnterEmailPassword}
            register={register}
            options={{
              required: t('error.This field cannot be empty.'),
            }}
            errors={errors}
          />

          <Checkbox
            className="items-start"
            aria-checked={transferSession as boolean}
            onClick={() => {
              if (isSubmitting || state !== LoginState.EnterEmailPassword) {
                return
              }
              setValue('transferSession', !transferSession)
            }}
          >
            {t(
              'pages.login.Transfer chat history and overwrite the one saved in your profile.'
            )}
          </Checkbox>

          {state === LoginState.VerifyFactor && (
            <FormInput
              label={t('pages.login.E-Mail Token')}
              size="lg"
              id="factor"
              invisible
              disabled={isSubmitting || state !== LoginState.VerifyFactor}
              type="number"
              inputMode="numeric"
              register={register}
              options={{
                validate: (token) => {
                  if (state === LoginState.VerifyFactor) {
                    return !factorRegex.test(token)
                      ? authErrorToMessage(AuthError.InvalidTwoFaToken, t)
                      : undefined
                  }
                },
              }}
              errors={errors}
            />
          )}

          <div className="mt-5 flex space-x-3 justify-center">
            <Button type="submit" size="lg" disabled={isSubmitting || !isDirty}>
              {state === LoginState.EnterEmailPassword &&
                (isSubmitting
                  ? t('components.button.Sending')
                  : t('pages.login.Send E-Mail confirmation'))}
              {state === LoginState.VerifyFactor &&
                (isSubmitting
                  ? t('components.button.Loading')
                  : t('pages.login.Login'))}
              {state === LoginState.ResetPassword &&
                (isSubmitting
                  ? t('components.button.Loading')
                  : t('pages.login.Reset password'))}
              {state === LoginState.ResendFactor &&
                (isSubmitting
                  ? t('components.button.Loading')
                  : t('pages.login.Resend E-Mail confirmation'))}
            </Button>

            {state !== LoginState.EnterEmailPassword && (
              <Button
                variant="secondary"
                outline
                size="lg"
                type="reset"
                disabled={isSubmitting || !isDirty}
                onClick={() => {
                  setState(LoginState.EnterEmailPassword)
                  reset()
                }}
              >
                {t('components.button.Back')}
              </Button>
            )}
          </div>
        </form>
      </div>
    </Shell>
  )
}
