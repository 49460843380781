import { ConversationError } from '../GraphQL/graphql'

export const sortConversations = (a, b) =>
  new Date(a.answerTimestamp).getTime() - new Date(b.answerTimestamp).getTime()

export const getVideoUrl = (
  id = '404',
  language = '',
  videoPath = 'chat'
): string => {
  const identifier = id
    .toLowerCase()
    .replaceAll('ä', 'ae')
    .replaceAll('ö', 'oe')
    .replaceAll('ü', 'ue')
    .replaceAll('ß', 'ss')

  const videoUrl =
    process.env.REACT_APP_S3_VIDEOS_CHAT +
    `/${videoPath}/${language}/${
      language ? language + '-' : ''
    }${identifier}.mp4`.replaceAll(/\/+/g, '/')

  return videoUrl
}

export const getVideoUrlForConversation = (
  videoIdentifier?: string,
  language?: string,
  videoPath = 'chat'
  // TODO: delete `?? undefined` after `null` is eliminated
): string => getVideoUrl(videoIdentifier, language, videoPath)

export const conversationErrorToMessage = (
  error: ConversationError
): string => {
  switch (error) {
    case ConversationError.UnknownStart:
      return 'Start konnte nicht zugewiesen werden. Der Link war ungültig'
    default:
      return 'Es ist ein Fehler aufgetreten'
  }
}
