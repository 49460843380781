import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import type {
  UploadDicomFilesMutation,
  UploadDicomFilesMutationVariables,
} from '../../GraphQL/graphql'
import { UploadDicomFilesDocument } from '../../GraphQL/graphql'
import type { DicomSeriesFiles } from '../../Helper/DicomHelper'
import { parseDicomSeries } from '../../Helper/DicomHelper'
import { Button } from '../Button'

interface Props {
  onFinish(): void
}

export const DicomUpload: React.FC<Props> = ({ onFinish }) => {
  const { t } = useTranslation()
  const [uploadDicom] = useMutation<
    UploadDicomFilesMutation,
    UploadDicomFilesMutationVariables
  >(UploadDicomFilesDocument)

  const [parsedSeries, setParsedSeries] = useState<DicomSeriesFiles[]>([])
  const [showOnlyXRayWarning, setShowOnlyXRayWarning] = useState(false)

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return

    const series = await parseDicomSeries(acceptedFiles)
    const onlyCtSeries = series.filter((s) => s.studyModality === 'CR')

    if (onlyCtSeries.length !== series.length) {
      setShowOnlyXRayWarning(true)
    }

    setParsedSeries(onlyCtSeries)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  })

  const closeModal = () => {
    setParsedSeries([])
    onFinish()
    setShowOnlyXRayWarning(false)
  }

  const handleUpload = async () => {
    const files = parsedSeries
      .concat()
      .map((s) => s.files.map((f) => f.file))
      .flat()

    await uploadDicom({ variables: { files } })

    closeModal()
  }

  return (
    <div className="space-y-4">
      <div
        {...getRootProps()}
        className={classNames(
          'flex h-[192px] cursor-pointer border-4 border-dashed transition-colors text-center',
          isDragActive && 'border-dro-green text-dro-green',
          !isDragActive && 'border-dro-midgray'
        )}
      >
        <input {...getInputProps()} />

        {
          <div className="self-center">
            <p>
              {isDragActive
                ? t('pages.files.Drop files here')
                : t('pages.files.Drag’n’drop DICOM files or click to select')}
            </p>
          </div>
        }
      </div>
      {showOnlyXRayWarning && (
        <p>{t('pages.files.Warning! Only x-ray images will be uploaded!')}</p>
      )}

      <p className="text-s">
        {t(
          'pages.files.Currently, we are only evaluating knee X-rays. Please upload only X-ray images as shown in the sample images. Others will not be evaluated and will be deleted.'
        )}
      </p>

      <div className="flex space-x-3 justify-center">
        <Button variant="danger" onClick={closeModal}>
          {t('components.button.Abort')}
        </Button>
        <Button onClick={handleUpload}>
          {t('pages.files.Upload count image series', {
            count: parsedSeries.length,
          })}
        </Button>
      </div>
    </div>
  )
}
