import { type TFunction } from 'i18next'
import { ProfileError } from '../GraphQL/graphql'

export const profileErrorToMessage = (
  error: ProfileError,
  t: TFunction
): string => {
  switch (error) {
    case ProfileError.InvalidBirthdateFormat:
      return (
        t('error.profile.Wrong format.') +
        ' ' +
        t('error.profile.You must be at least 18 years old.')
      )
    case ProfileError.InvalidCityFormat:
      return t('error.profile.Wrong format.')
    case ProfileError.InvalidCountryFormat:
      return t('error.profile.Wrong format.')
    case ProfileError.InvalidEmailFormat:
      return t('error.profile.Wrong format.')
    case ProfileError.InvalidFirstnameFormat:
      return t('error.profile.Wrong format.')
    case ProfileError.InvalidInstructionFormat:
      return t('error.profile.Wrong format.')
    case ProfileError.InvalidLastnameFormat:
      return t('error.profile.Wrong format.')
    case ProfileError.InvalidPasswordFormat:
      return t('error.profile.Wrong format.')
    case ProfileError.InvalidPhoneFormat:
      return (
        t('error.profile.Wrong format.') +
        ' ' +
        t('error.profile.Including country code, e.g. +10123456789.')
      )
    case ProfileError.InvalidStreetFormat:
      return t('error.profile.Wrong format.')
    case ProfileError.InvalidTitleFormat:
      return t('error.profile.Wrong format.')
    case ProfileError.InvalidZipFormat:
      return t('error.profile.The postal code is not valid')
    case ProfileError.UnsupportedCountry:
      return t('error.profile.This country is not yet supported')
  }
}
