import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import type { FC } from 'react'
import { useContext, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'
import { AuthContext } from '../../Contexts/AuthContext'
import type {
  RegisterUserAndSendEmailVerificationMutation,
  RegisterUserAndSendEmailVerificationMutationVariables,
} from '../../GraphQL/graphql'
import {
  AuthError,
  ProfileError,
  RegisterUserAndSendEmailVerificationDocument,
} from '../../GraphQL/graphql'
import { authErrorToMessage } from '../../Helper/AuthHelper'
import { profileErrorToMessage } from '../../Helper/UserHelper'
import { AlertMessage } from '../AlertMessage/AlertMessage'
import { Anchor } from '../Anchor/Anchor'
import { Button } from '../Button'
import { Checkbox } from '../Checkbox/Checkbox'
import { FormInput } from '../FormInput/FormInput'

export const RegisterEnterEmailPassword: FC = () => {
  const { t } = useTranslation()
  const { updateAuth } = useContext(AuthContext)
  const [searchParams] = useSearchParams()
  const nextPath = searchParams.get('next')

  const [agreesTerms, setAgreesTerms] = useState(false)
  const [agreesPolicy, setAgreesPolicy] = useState(false)

  const registrationFormSchena = useMemo(
    () =>
      z
        .object({
          email: z
            .string()
            .trim()
            .min(1, t('error.profile.Please enter an email'))
            .email(profileErrorToMessage(ProfileError.InvalidEmailFormat, t)),
          password: z
            .string()
            .trim()
            .min(
              6,
              profileErrorToMessage(ProfileError.InvalidPasswordFormat, t)
            ),
          passwordRepeat: z
            .string()
            .trim()
            .min(
              6,
              profileErrorToMessage(ProfileError.InvalidPasswordFormat, t)
            ),
        })
        .refine((data) => data.password === data.passwordRepeat, {
          message: t('error.profile.Passwords do not match') as string,
          path: ['passwordRepeat'],
        }),
    [t]
  )

  type RegistrationFormValue = z.infer<typeof registrationFormSchena>

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<RegistrationFormValue>({
    mode: 'onBlur',
    defaultValues: {
      email: undefined,
      password: undefined,
      passwordRepeat: undefined,
    },
    resolver: zodResolver(registrationFormSchena),
  })

  const [registerUserAndSendEmailVerification, { loading }] = useMutation<
    RegisterUserAndSendEmailVerificationMutation,
    RegisterUserAndSendEmailVerificationMutationVariables
  >(RegisterUserAndSendEmailVerificationDocument)

  const onSubmit = handleSubmit(async ({ email, password, passwordRepeat }) => {
    if (password !== passwordRepeat) {
      setError('passwordRepeat', {
        message: t('error.profile.Passwords do not match'),
      })
      return
    }
    await registerUserAndSendEmailVerification({
      variables: {
        email,
        password,
        next: nextPath,
      },
      onCompleted: ({ registerUserAndSendEmailVerification }) => {
        if (registerUserAndSendEmailVerification.authError) {
          switch (registerUserAndSendEmailVerification.authError) {
            case AuthError.ExistingUser:
              setError('email', {
                message: authErrorToMessage(
                  registerUserAndSendEmailVerification.authError,
                  t
                ),
              })
              break
            default:
              setError('root', {
                message: authErrorToMessage(
                  registerUserAndSendEmailVerification.authError,
                  t
                ),
              })
          }
        } else if (registerUserAndSendEmailVerification.profileErrors) {
          const errors: string[] = []
          registerUserAndSendEmailVerification.profileErrors.forEach(
            (error) => {
              switch (error) {
                case ProfileError.InvalidEmailFormat:
                  setError('email', {
                    message: profileErrorToMessage(error, t),
                  })
                  break
                case ProfileError.InvalidPasswordFormat:
                  setError('password', {
                    message: profileErrorToMessage(error, t),
                  })
                  break
                default:
                  errors.push(profileErrorToMessage(error, t))
                  break
              }
              if (errors.length) {
                setError('root', {
                  message: errors.join(' | '),
                })
              }
            }
          )
        } else if (registerUserAndSendEmailVerification.accessToken) {
          updateAuth(registerUserAndSendEmailVerification.accessToken)
        }
      },
      onError: () =>
        setError('root', {
          message: t(
            'error.Saving not possible. If this error occurs repeatedly, please contact our support.'
          ),
        }),
    })
  })

  return (
    <>
      {errors.root?.message && (
        <div className="mb-10">
          <AlertMessage
            type="warning"
            message={errors.root.message}
            onClose={() => clearErrors('root')}
          />
        </div>
      )}

      <form className="flex flex-col space-y-6" onSubmit={onSubmit}>
        <FormInput
          id="email"
          size="lg"
          label={t('pages.login.E-Mail')}
          disabled={isSubmitting}
          type="text"
          register={register}
          options={{
            required: t('error.This field cannot be empty.'),
          }}
          errors={errors}
        />
        <FormInput
          id="password"
          size="lg"
          label={t('pages.profile.New password')}
          disabled={isSubmitting}
          type="password"
          register={register}
          options={{
            required: t('error.This field cannot be empty.'),
          }}
          errors={errors}
        />
        <FormInput
          id="passwordRepeat"
          size="lg"
          label={t('pages.profile.Repeat password')}
          disabled={isSubmitting}
          type="password"
          register={register}
          options={{
            required: t('error.This field cannot be empty.'),
          }}
          errors={errors}
        />

        <Checkbox
          className="items-start"
          aria-checked={agreesTerms}
          onClick={() => {
            setAgreesTerms(!agreesTerms)
          }}
        >
          <Trans
            i18nKey="pages.registration.I agree to the terms of use and privacy policy."
            components={{
              lnk_terms: (
                // eslint-disable-next-line react/jsx-no-undef
                <Anchor href={t('link.company-terms-of-use')} blank />
              ),
            }}
          />
        </Checkbox>

        <Checkbox
          className="items-start"
          aria-checked={agreesPolicy}
          onClick={() => {
            setAgreesPolicy(!agreesPolicy)
          }}
        >
          <Trans
            i18nKey="pages.registration.I am aware of my right of withdrawal and I agree to it."
            components={{
              lnk_privacy: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <Anchor href={t('link.company-privacy-policy')} blank />
              ),
            }}
          />
        </Checkbox>

        <div className="mt-5 flex justify-center">
          <Button
            type="submit"
            size="lg"
            disabled={
              isSubmitting ||
              !isDirty ||
              loading ||
              !agreesTerms ||
              !agreesPolicy
            }
          >
            {isSubmitting
              ? t('components.button.Sending')
              : t('pages.registration.Sign up for free')}
          </Button>
        </div>
      </form>
    </>
  )
}
