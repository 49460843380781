import classNames from 'classnames'
import { isString } from 'lodash'
import type { FC, InputHTMLAttributes } from 'react'
import type {
  FieldErrors,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form'

type RadioGroupProps = InputHTMLAttributes<HTMLInputElement> & {
  id: string
  label: string
  invisible?: boolean
  buttons: {
    value: string
    title: string
  }[]
  register: UseFormRegister<any>
  options: RegisterOptions<FieldValues>
  errors?: FieldErrors<FieldValues>
}

export const FormRadioGroup: FC<RadioGroupProps> = ({
  className,
  id,
  buttons,
  label,
  invisible = false,
  register,
  errors,
  options,
  ...rest
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        className={classNames(
          'color-3 text-lg cursor-pointer mb-2 inline-block',
          invisible && 'sr-only'
        )}
      >
        {label}
      </label>

      <fieldset>
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
          {buttons.map((option) => (
            <div key={option.value} className="flex items-center">
              <input
                id={option.value}
                type="radio"
                className={classNames(
                  'h-4 w-4 border-dro-midgray border text-dro-green focus:ring-dro-green cursor-pointer',
                  className
                )}
                value={option.value}
                {...register(id, options)}
                {...rest}
              />
              <label
                htmlFor={option.value}
                className="ml-3 block leading-6 cursor-pointer"
              >
                {option.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>

      {errors && isString(errors[id]?.message) && (
        <label
          htmlFor="factor"
          className="text-dro-red flex justify-end text-right"
        >
          {errors[id]?.message as string}
          {!['.', '!', '?'].includes(
            (errors[id]?.message as string).at(-1) ?? '.'
          ) && '.'}
        </label>
      )}
    </div>
  )
}
