import cn from 'classnames'

interface Props {
  label?: string
  withLayout?: boolean
}

const SPINNER_PARTICLE =
  'absolute w-10 h-10 m-1 border-4 border-[transparent] rounded-[50%] animate-spin'

export const Spinner: React.FC<Props> = ({
  label = null,
  withLayout = false,
}) => {
  return (
    <div
      className={cn({
        'flex items-center justify-center w-full h-4/5': withLayout,
      })}
    >
      <div className="text-center">
        <div className="relative w-12 h-12 inline-block">
          <div className={cn(SPINNER_PARTICLE, 'border-l-[rgb(66,_143,_3)]')} />
          <div className={cn(SPINNER_PARTICLE, 'border-b-[rgb(66,_143,_3)]')} />
          <div className={cn(SPINNER_PARTICLE, 'border-r-[rgb(66,_143,_3)]')} />
        </div>
        {label && <p>{label}</p>}
      </div>
    </div>
  )
}
