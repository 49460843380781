import _ from 'lodash'
import type { FC, PropsWithChildren } from 'react'
import { createContext, useState } from 'react'
import { SocketDownAlert } from '../Components/SocketDownAlert/SocketDownAlert'

type WebSocketContextType = {
  isSocketError: boolean
  onError: (error: Error) => void
}

export const WebSocketContext = createContext<WebSocketContextType>({
  isSocketError: false,
  onError: _.noop,
})

export const WebSocketProvider: FC<PropsWithChildren> = ({ children }) => {
  const [error, setError] = useState(false)

  function onError(error: Error) {
    setError(true)
    // eslint-disable-next-line no-console
    console.log('Socket Error', error)
  }

  return (
    <WebSocketContext.Provider
      value={{
        onError,
        isSocketError: error,
      }}
    >
      {error && <SocketDownAlert />}
      {children}
    </WebSocketContext.Provider>
  )
}
