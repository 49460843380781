import { DateTime, Duration } from 'luxon'
import type { FC, HTMLAttributes } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ProfileError,
  type ProfileInput,
  ProfileSex,
} from '../../GraphQL/graphql'
import {
  authErrorToMessage,
  birthdateRegex,
  firstnameRegex,
  lastnameRegex,
} from '../../Helper/AuthHelper'
import { profileErrorToMessage } from '../../Helper/UserHelper'
import { useProfile } from '../../Hooks/useProfile'
import { AlertMessage } from '../AlertMessage/AlertMessage'
import { Button } from '../Button'
import { FormInput } from '../FormInput/FormInput'
import { FormRadioGroup } from '../FormRadioGroup/FormRadioGroup'
import { Heading } from '../Heading/Heading'
// import { RadioGroup } from '../FormRadioGroup/FormRadioGroup'

export const ProfileUpdateProfile: FC<HTMLAttributes<HTMLDivElement>> = ({
  ...divArgs
}) => {
  const { t } = useTranslation()

  const [editProfile, setEditProfile] = useState(false)

  const {
    profile,
    mutations: {
      updateProfile: [updateProfile],
    },
  } = useProfile()

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitting },
  } = useForm<ProfileInput>({
    defaultValues: useMemo(() => {
      return {
        sex: profile?.sex ?? undefined,
        title: profile?.title ?? undefined,
        firstname: profile?.firstname ?? undefined,
        lastname: profile?.lastname ?? undefined,
        birthdate: profile?.birthdate ?? undefined,
      }
    }, [
      profile?.sex,
      profile?.title,
      profile?.firstname,
      profile?.lastname,
      profile?.birthdate,
    ]),
  })

  useEffect(() => {
    reset({
      sex: profile?.sex ?? undefined,
      title: profile?.title ?? undefined,
      firstname: profile?.firstname ?? undefined,
      lastname: profile?.lastname ?? undefined,
      birthdate: profile?.birthdate ?? undefined,
    })
  }, [profile, reset])

  const onSubmit = handleSubmit(async (profileinput) => {
    await updateProfile({
      variables: { profile: profileinput },
      onCompleted: ({ updateProfile }) => {
        if (updateProfile.authError) {
          setError('root', {
            message: authErrorToMessage(updateProfile.authError, t),
          })
        } else if (updateProfile.profileErrors) {
          const errors: string[] = []
          updateProfile.profileErrors.forEach((error) => {
            switch (error) {
              case ProfileError.InvalidBirthdateFormat:
                setError('birthdate', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidFirstnameFormat:
                setError('firstname', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidLastnameFormat:
                setError('lastname', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidTitleFormat:
                setError('title', {
                  message: profileErrorToMessage(error, t),
                })
                break
              default:
                errors.push(profileErrorToMessage(error, t))
                break
            }
            if (errors.length) {
              setError('root', {
                message: errors.join(' | '),
              })
            }
          })
        } else {
          setEditProfile(false)
          reset()
        }
      },
      onError: () => {
        setError('root', {
          message: t(
            'error.Saving not possible. If this error occurs repeatedly, please contact our support.'
          ),
        })
      },
    })
  })

  return (
    <div {...divArgs}>
      <Heading variant="h2" className="mb-6">
        {t('pages.profile.Person')}
      </Heading>

      {isSubmitSuccessful && !isDirty && (
        <AlertMessage
          type="success"
          message={t('pages.profile.Profile successfully saved.')}
          onClose={() => reset()}
        />
      )}

      {editProfile && errors.root?.message && (
        <AlertMessage
          type="warning"
          message={errors.root.message}
          onClose={() => clearErrors('root')}
        />
      )}

      <form className="space-y-6" onSubmit={onSubmit}>
        {/* <RadioGroup
            options={[
              { value: ProfileSex.Female, title: t('pages.profile.Mrs.') },
              { value: ProfileSex.Male, title: t('pages.profile.Mr.') },
            ]}
            className="mb-6"
            disabled={isSubmitting || !editProfile}
            {...register('sex', { required: true })}
          /> */}
        <div>
          <FormRadioGroup
            id={'sex'}
            label={t('pages.profile.Gender')}
            invisible
            buttons={[
              { value: ProfileSex.Female, title: t('pages.profile.Mrs.') },
              { value: ProfileSex.Male, title: t('pages.profile.Mr.') },
            ]}
            disabled={isSubmitting || !editProfile}
            register={register}
            options={{ required: true }}
          />
        </div>

        <FormInput
          id="title"
          size="lg"
          label={t('pages.profile.Title')}
          disabled={isSubmitting || !editProfile}
          type="text"
          register={register}
          errors={errors}
        />

        <FormInput
          id="firstname"
          size="lg"
          label={t('pages.profile.First name')}
          disabled={isSubmitting || !editProfile}
          type="text"
          register={register}
          options={{
            required: t('error.This field cannot be empty.'),
            pattern: {
              value: firstnameRegex,
              message: profileErrorToMessage(
                ProfileError.InvalidFirstnameFormat,
                t
              ),
            },
          }}
          errors={errors}
        />

        <FormInput
          id="lastname"
          size="lg"
          label={t('pages.profile.Last name')}
          disabled={isSubmitting || !editProfile}
          type="text"
          register={register}
          options={{
            required: t('error.This field cannot be empty.'),
            pattern: {
              value: lastnameRegex,
              message: profileErrorToMessage(
                ProfileError.InvalidFirstnameFormat,
                t
              ),
            },
          }}
          errors={errors}
        />

        <FormInput
          id="birthdate"
          size="lg"
          label={t('pages.profile.Day of birth')}
          disabled={isSubmitting || !editProfile}
          type="date"
          register={register}
          options={{
            required: t('error.This field cannot be empty.'),
            pattern: {
              value: birthdateRegex,
              message: profileErrorToMessage(
                ProfileError.InvalidBirthdateFormat,
                t
              ),
            },
            validate: (date) => {
              return DateTime.fromISO(date) <
                DateTime.now().minus(Duration.fromObject({ years: 18 }))
                ? undefined
                : profileErrorToMessage(ProfileError.InvalidBirthdateFormat, t)
            },
          }}
          errors={errors}
        />

        {editProfile && (
          <Button
            type="submit"
            size="sm"
            disabled={isSubmitting || !isDirty}
            className="mr-2"
          >
            {isSubmitting
              ? t('components.button.Saving')
              : t('pages.profile.Save Profile')}
          </Button>
        )}
        <Button
          type="button"
          variant={editProfile ? 'secondary' : 'primary'}
          size="sm"
          onClick={() => {
            reset()
            setEditProfile(!editProfile)
          }}
        >
          {editProfile
            ? t('components.button.Abort')
            : t('components.button.Edit')}
        </Button>
      </form>
    </div>
  )
}
