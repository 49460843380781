import type { TFunction } from 'i18next'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { DateTime } from 'luxon'
import type { ResearchGroup, UserRights, UserRole } from '../GraphQL/graphql'
import { AuthError } from '../GraphQL/graphql'

export const emailRegex =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
export const factorRegex = /^\d{6}$/
export const passwordRegex = /^.{6,}$/
export const phoneRegex =
  /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/

export const firstnameRegex = /^.{1,}$/
export const lastnameRegex = /^.{1,}$/
export const birthdateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/ // 2022-12-13

export const addressStreetRegex = /^.{1,}$/
export const addressZipRegex = /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/
export const addressCityRegex = /^.{1,}$/

const ACCESS_TOKEN_COOKIE = 'drat'

export type AccessTokenType = {
  exp: number
  iat: number
  userId: string
  userRole: UserRole
  userRights?: UserRights
  userIsComplete: boolean
  userIsResearch: boolean
  researchGroup?: ResearchGroup
}

export const getAccessTokenContent = (token?: string) => {
  if (!token) {
    token = Cookies.get(ACCESS_TOKEN_COOKIE) ?? ''
  }
  let content: Partial<AccessTokenType> | undefined
  try {
    content = jwtDecode<Partial<AccessTokenType>>(token)
  } catch {
    // error decoding
  }
  if (DateTime.fromSeconds(content?.exp ?? 0) > DateTime.now()) {
    return content
  } else {
    return undefined
  }
}

export const setAccessToken = (token: string | undefined | null) => {
  token = token ?? ''
  const { exp } = getAccessTokenContent(token) ?? {}
  const expires = exp ? DateTime.fromSeconds(exp).toJSDate() : 0
  Cookies.set(ACCESS_TOKEN_COOKIE, token, {
    sameSite: process.env.NODE_ENV === 'production' ? 'none' : undefined,
    // domain: process.env.NODE_ENV === 'production' ? 'docrobin.ai' : undefined,
    secure: process.env.NODE_ENV === 'production',
    expires,
  })
}

export const getAccessToken = () => {
  const token = Cookies.get(ACCESS_TOKEN_COOKIE) ?? ''
  const { exp } = getAccessTokenContent(token) ?? {}
  if (DateTime.fromSeconds(exp ?? 0) > DateTime.now()) {
    return token
  } else {
    return ''
  }
}

export const authorizationHeader = (token: string): string => {
  return token ? `bearer ${token}` : ''
}

export const fetchBuffer = async (url: string): Promise<ArrayBuffer> => {
  const resp = new Promise((accept) => {
    const req = new XMLHttpRequest()
    req.open('GET', url, true)
    req.withCredentials = true
    req.responseType = 'arraybuffer'
    req.setRequestHeader('Access-Control-Allow-Origin', '*')
    req.setRequestHeader('Authorization', authorizationHeader(getAccessToken()))

    req.onload = function () {
      const resp = req.response
      if (resp) {
        accept(resp)
      }
    }

    req.send(null)
  })

  return resp as Promise<ArrayBuffer>
}

export const fetchFile = async (url: string): Promise<File | null> => {
  const res = await fetchBuffer(url)
  if (res) {
    const imageBlob = new Blob([new Uint8Array(res)])
    const file = new File([imageBlob], 'name')
    return file
  } else {
    return null
  }
}

export const fetchUrl = async (url: string): Promise<string> => {
  return fetchBuffer(url)
    .then((res) => {
      if (res) {
        const imageBlob = new Blob([new Uint8Array(res)])
        const imageObjectURL = URL.createObjectURL(imageBlob)
        return imageObjectURL
      }
      return ''
    })
    .catch(() => {
      return ''
    })
}

export const authErrorToMessage = (error: AuthError, t: TFunction): string => {
  switch (error) {
    case AuthError.RegistrationFailed:
      return t('error.auth.The registration has failed.')
    case AuthError.ExistingUser:
      return t('error.auth.This user exists already.')
    case AuthError.InvalidUser:
      return t('error.auth.The data is incorrect.')
    case AuthError.InvalidPassword:
      return t('error.auth.Wrong password. Typo?')
    case AuthError.UnactivatedUser:
      return t(
        'error.auth.Please contact our support team to unlock your account.'
      )
    case AuthError.UnknownUser:
      return t('error.auth.Could not find user.')
    case AuthError.UnverifiedEmail:
      return t(
        'error.auth.The email must be confirmed with the link from the activation email.'
      )
    case AuthError.UnverifiedPhone:
      return t('error.auth.The phone number must be confirmed.')
    case AuthError.InvalidEmailToken:
      return t('error.auth.The activation link has expired or is invalid.')
    case AuthError.InvalidPasswordToken:
      return t('error.auth.Invalid Password.')
    case AuthError.InvalidTwoFaToken:
      return t('error.auth.The Code has expired or is invalid.')
    case AuthError.NoEmailChange:
      return t(
        'error.auth.The email has not been updated, because it matches the old one.'
      )
    case AuthError.VerifiedUser:
      return t('error.auth.The user has been verified already.')
    case AuthError.VerificationEmailSendingError:
      return t(
        'error.auth.The E-Mail with the activation code could not be sent.'
      )
    case AuthError.VerificationSmsSendingError:
      return t(
        'error.auth.The E-Mail with the activation code could not be sent.'
      )
  }
}
