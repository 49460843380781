import _ from 'lodash'
import type { ReactNode } from 'react'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import type { To } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import logo from '../../assets/logo-text-blue.png'
import type {
  GetCurrentUserQuery,
  ReportReviewerFragment,
  ReportUserFragment,
} from '../../GraphQL/graphql'
import { PRINT_PAGE_STYLE } from '../../Helper/PrintHelper'
import { Anchor } from '../Anchor/Anchor'
import { Button } from '../Button'
import { Table } from '../Table/Table'

interface Props {
  title: string
  user?: ReportUserFragment | GetCurrentUserQuery['getCurrentUser']
  reviewer?: ReportReviewerFragment
  children: ReactNode | string
  date?: Date
}

export const ReportLayout: React.FC<Props> = ({
  title,
  user,
  reviewer,
  children,
  date,
}) => {
  const [searchParams] = useSearchParams()
  const nextUrl: string | null = searchParams.get('next') || null
  const contentRef = useRef<HTMLDivElement | null>(null)
  const oldDocTitle = useRef<string | null>(null)

  const { t, i18n } = useTranslation()
  const dateLabel: string = (date || new Date()).toLocaleString(i18n.language)

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    copyStyles: true,
    pageStyle: PRINT_PAGE_STYLE,
    fonts: [
      {
        family: 'Noto_Serif variant0',
        source:
          "url(https://docrobin.net/fonts/NotoSerif-VariableFont_wdth%2Cwght.woff2) format('woff2')",
      },
    ],
  })

  useEffect(() => {
    oldDocTitle.current = document.title
    // Some browsers use the page title for printing
    if (user) {
      document.title = `${title}, ${user.firstname} ${user.lastname}, ${dateLabel}`
    }
    // Cleanup: restore old document title.
    return () => {
      if (oldDocTitle.current) {
        document.title = oldDocTitle.current
      }
    }
  }, [dateLabel, title, user])

  return (
    <div className="space-y-10">
      <div className="print:invisible">
        <div className="container mx-auto flex justify-center gap-24">
          <div>
            <Button variant="primary" to={nextUrl ?? (-1 as To)}>
              {t('components.button.Back')}
            </Button>
          </div>
          <div>
            <Button
              variant="primary"
              onClick={() => {
                handlePrint()
              }}
            >
              {t('pages.reports.Print')}
            </Button>
          </div>
        </div>
      </div>
      <div className="max-w-3xl mx-auto" ref={contentRef}>
        <div className="text-end mb-5">
          <img
            src={logo}
            alt="DocRobin logo"
            className="max-w-[240px] ml-auto"
          />
        </div>

        {/* <div style={{ gridArea: 'title' }}>
            <Heading variant="h1">{title}</Heading>
          </div> */}
        <div className="mb-5 text-dro-blue col-span-2 text-end font-bold">
          {t('pages.reports.Created at')}: {dateLabel}
        </div>
        {user && (
          <Table
            hasHeaderColumn={true}
            className="text-dro-blue col-span-2 md:col-span-1 print:col-span-1"
          >
            <tbody>
              <tr>
                <td className="font-bold align-top pr-5">
                  {t('pages.reports.Patient')}
                </td>
                <td>
                  <div>
                    {_.compact([
                      user.title,
                      user.firstname,
                      user.lastname,
                    ]).join(' ')}
                  </div>
                  <div>{user.email}</div>
                  <div>{user.phone}</div>
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        <div className="my-5">{children}</div>

        {reviewer && (
          <Table
            hasHeaderColumn={true}
            className="text-dro-blue col-span-2 md:col-span-1 print:col-span-1"
          >
            <tbody>
              <tr>
                <td className="font-bold align-top pr-5">
                  {t('pages.reports.Reviewer')}
                </td>
                <td>
                  {_.compact([
                    reviewer.title,
                    reviewer.firstname,
                    reviewer.lastname,
                  ]).join(' ')}
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        <div className="mt-10 break-inside-avoid invisible print:visible print:break-after-avoid">
          <hr className=" h-px my-8 bg-dro-gray" />
          <div className="flex flex-auto gap-4  text-dro-blue">
            <img
              className="max-h-10 flex-none"
              src={logo}
              alt="DocRobin logo"
            />

            <div className="mt-2 flex-1">
              {t('company.company-name')}
              <br />
              {t('company.company-street')} | {t('company.company-zip')}{' '}
              {t('company.company-city')}
              <br />
              {t('pages.reports.E-Mail')}:{' '}
              <Anchor href={`mailto:${t('company.company-email')}`}>
                {t('company.company-email')}
              </Anchor>
              <br />
              {t('pages.reports.Phone')}:{' '}
              <Anchor href={`tel:${t('company.company-phone')}`}>
                {t('company.company-phone')}
              </Anchor>
              <br />
              {t('pages.reports.Website')}:{' '}
              <Anchor href={t('link.company-website')} blank>
                {t('link.company-website')}
              </Anchor>
              <br />
            </div>
            <div className="mt-2 flex-1">
              {t('company.company-ceo')}
              <br />
              {t('company.company-legal-location')} |{' '}
              {t('company.company-legal-court')}:{' '}
              {t('company.company-legal-number')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
