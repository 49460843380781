import classNames from 'classnames'
import type { AnchorHTMLAttributes, FC } from 'react'

export type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  blank?: boolean
  variant?: 'primary' | 'secondary'
}

export const Anchor: FC<AnchorProps> = ({
  className,
  variant = 'primary',
  blank = false,
  children,
  ...props
}) => {
  return (
    <a
      target={blank ? '_blank' : ''}
      rel={blank ? 'noreferrer' : ''}
      className={classNames(
        'underline underline-offset-4 cursor-pointer',
        variant === 'primary' && 'text-dro-green hover:text-dro-darkgreen',
        variant === 'secondary' && 'text-dro-blue hover:text-dro-lightblue',
        className
      )}
      {...props}
    >
      {children}
    </a>
  )
}
