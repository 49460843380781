import classNames from 'classnames'
import type { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

type Props = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  className?: string
}

export const CardList: FC<Props> = ({ children, className, ...rest }) => {
  return (
    <div
      className={classNames(
        'grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:gap-4',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}
