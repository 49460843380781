import type { FC, HTMLAttributes } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  AuthError,
  ProfileError,
  type UpdatePhoneMutationVariables,
  type VerifyUserPhoneMutationVariables,
} from '../../GraphQL/graphql'
import {
  authErrorToMessage,
  factorRegex,
  phoneRegex,
} from '../../Helper/AuthHelper'
import { profileErrorToMessage } from '../../Helper/UserHelper'
import { useProfile } from '../../Hooks/useProfile'
import { AlertMessage } from '../AlertMessage/AlertMessage'
import { Button } from '../Button'
import { FormInput } from '../FormInput/FormInput'
import { Heading } from '../Heading/Heading'

enum ProfileUpdatePhoneState {
  View,
  EditPhone,
  VerifyPhone,
}

export const ProfileUpdatePhone: FC<HTMLAttributes<HTMLDivElement>> = ({
  ...divArgs
}) => {
  const { t } = useTranslation()

  const [state, setState] = useState(ProfileUpdatePhoneState.View)

  const {
    profile,
    mutations: {
      updatePhone: [updatePhone],
      // verifyUserPhone: [verifyUserPhone],
    },
  } = useProfile()

  const {
    register,
    handleSubmit,
    setError,
    reset,
    setFocus,
    clearErrors,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitting },
  } = useForm<UpdatePhoneMutationVariables & VerifyUserPhoneMutationVariables>({
    defaultValues: useMemo(() => {
      return { phone: profile?.phone ?? undefined }
    }, [profile?.phone]),
  })

  useEffect(() => {
    reset({ phone: profile?.phone ?? undefined })
  }, [profile?.phone, reset])

  useEffect(() => {
    state === ProfileUpdatePhoneState.VerifyPhone && setFocus('smsToken')
  }, [setFocus, state])

  const onSubmit = handleSubmit(async ({ phone, smsToken }) => {
    if (state === ProfileUpdatePhoneState.EditPhone || !smsToken) {
      await updatePhone({
        variables: { phone },
        onCompleted: ({ updatePhone }) => {
          if (updatePhone.authError) {
            setError('root', {
              message: authErrorToMessage(updatePhone.authError, t),
            })
          } else if (updatePhone.profileErrors) {
            const errors: string[] = []
            updatePhone.profileErrors.forEach((error) => {
              switch (error) {
                case ProfileError.InvalidPhoneFormat:
                  setError('phone', {
                    message: profileErrorToMessage(error, t),
                  })
                  break
                default:
                  errors.push(profileErrorToMessage(error, t))
                  break
              }
              if (errors.length) {
                setError('root', {
                  message: errors.join(' | '),
                })
              }
            })
          } else if (updatePhone.user) {
            setState(ProfileUpdatePhoneState.View)
          }
        },
        onError: () =>
          setError('root', {
            message: t(
              'error.Saving not possible. If this error occurs repeatedly, please contact our support.'
            ),
          }),
      })
    } else if (state === ProfileUpdatePhoneState.VerifyPhone && smsToken) {
      setState(ProfileUpdatePhoneState.View)
      // await verifyUserPhone({
      //   variables: { smsToken },
      //   onCompleted: ({ verifyUserPhone }) => {
      //     if (verifyUserPhone.error) {
      //       setError('root', {
      //         message: authErrorToMessage(verifyUserPhone.error, t),
      //       })
      //     } else if (verifyUserPhone.accessToken) {
      //       setAccessToken(verifyUserPhone.accessToken)
      //       setState(ProfileUpdatePhoneState.View)
      //       reset()
      //     }
      //   },
      //   onError: () =>
      //     setError('root', {
      //       message: t(
      //         'Saving not possible. If this error occurs repeatedly, please contact our support.'
      //       ),
      //     }),
      // })
    }
  })

  return (
    <div {...divArgs}>
      <Heading variant="h2" className="mb-6">
        {t('pages.profile.Phone')}
      </Heading>

      {isSubmitSuccessful &&
        profile?.pendingPhone &&
        state === ProfileUpdatePhoneState.VerifyPhone && (
          <AlertMessage
            type="success"
            message={t(
              'pages.profile.Phone successfully saved. You have been sent a code to phone that you can use to activate it.',
              { phone: profile.pendingPhone }
            )}
          />
        )}

      {ProfileUpdatePhoneState.View !== state && errors.root?.message && (
        <AlertMessage
          type="warning"
          message={errors.root.message}
          onClose={() => clearErrors('root')}
        />
      )}

      <form className="space-y-6" onSubmit={onSubmit}>
        <FormInput
          id="phone"
          invisible
          size="lg"
          label={t('pages.profile.E-Mail')}
          disabled={isSubmitting || state !== ProfileUpdatePhoneState.EditPhone}
          type="text"
          info={t('error.profile.Including country code, e.g. +10123456789.')}
          register={register}
          options={{
            required: t('error.This field cannot be empty.'),
            pattern: {
              value: phoneRegex,
              message: profileErrorToMessage(
                ProfileError.InvalidPhoneFormat,
                t
              ),
            },
          }}
          errors={errors}
        />

        {state === ProfileUpdatePhoneState.VerifyPhone && (
          <FormInput
            id="smsToken"
            invisible
            size="lg"
            label={t('pages.login.SMS Token')}
            disabled={
              isSubmitting || state !== ProfileUpdatePhoneState.VerifyPhone
            }
            type="text"
            register={register}
            options={{
              required: t('error.This field cannot be empty.'),
              pattern: {
                value: factorRegex,
                message: authErrorToMessage(AuthError.InvalidTwoFaToken, t),
              },
            }}
            errors={errors}
          />
        )}

        {state === ProfileUpdatePhoneState.EditPhone && (
          <Button
            type="submit"
            size="sm"
            disabled={isSubmitting || !isDirty}
            className="mr-2"
          >
            {isSubmitting
              ? t('components.button.Saving')
              : t('pages.profile.Save Phone')}
          </Button>
        )}

        {state === ProfileUpdatePhoneState.VerifyPhone && (
          <Button
            type="submit"
            size="sm"
            disabled={isSubmitting || !isDirty}
            className="mr-2"
          >
            {isSubmitting
              ? t('components.button.Saving')
              : t('pages.profile.Confirm Phone')}
          </Button>
        )}

        <Button
          type="button"
          size="sm"
          variant={
            state !== ProfileUpdatePhoneState.View ? 'secondary' : 'primary'
          }
          onClick={() => {
            reset()
            setState(
              state === ProfileUpdatePhoneState.View
                ? ProfileUpdatePhoneState.EditPhone
                : ProfileUpdatePhoneState.View
            )
          }}
        >
          {state !== ProfileUpdatePhoneState.View
            ? t('components.button.Abort')
            : t('components.button.Edit')}
        </Button>
      </form>
    </div>
  )
}
