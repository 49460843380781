import classNames from 'classnames'
import type { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

type Props = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  className?: string
  title?: string
  banner?: string
  variant?: 'primary'
}

export const Card: FC<Props> = ({
  children,
  className,
  variant = 'primary',
  banner,
  title,
  ...rest
}) => {
  return (
    <div
      tabIndex={-1}
      className={classNames(
        'border flex flex-col rounded-4xl p-5 lg:p-7  justify-between',
        variant === 'primary' && 'bg-dro-lightblue text-white',
        className
      )}
      {...rest}
    >
      {banner && (
        <div className="py-2 px-4 bg-white/25 rounded-2xl mb-6">{banner}</div>
      )}
      {title && (
        <div className="font-bold text-3xl 2xl:text-4xl mb-10 text-ellipsis line-clamp-2">
          {title}
        </div>
      )}
      {children && (
        <div className="flex flex-col font-medium w-full">{children}</div>
      )}
    </div>
  )
}
