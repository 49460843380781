import { ClockIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useMemo } from 'react'
import { Loading } from '../Loading/Loading'

type Props = {
  message: string
  icon?: 'exclamation-triangle' | 'clock' | 'loading'
}

export const Alert: React.FC<Props> = ({ message, icon }) => {
  const IconComponent = useMemo(() => {
    if (icon === 'clock') return ClockIcon
    if (icon === 'loading') return Loading

    return ExclamationTriangleIcon
  }, [icon])

  return (
    <div className="grid place-items-center">
      <IconComponent className="text-dro-gray w-20 stroke-1" />
      <div className="text-dro-gray text-center text-2xl">{message}</div>
    </div>
  )
}
