export const PRINT_PAGE_STYLE = `
@page {
  size: A4;
  margin: 20mm;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
}
`
